import { Box, Typography } from "@mui/material";

import Control from "components/reusables/MapComponents/LeafletCustomControl";
import MapButton from "components/reusables/MapComponents/MapButton";
import LayersIcon from "@mui/icons-material/Layers";

import ProductTreeSelect, {
  ProductTreeSelectContext
} from "components/pages/CommoditiesProductionPage/ProductTreeSelect";
import { LayersControlsSection } from "components/reusables/MapComponents/LayersControlsButton";
import React, { Fragment, useContext } from "react";
import { PeriodSlider, PeriodSliderContext } from "components/reusables/PeriodSlider";
import { MapPageContext } from "../../reusables/MapPage";
import { StyledLinkLeaflet } from "../../reusables/StyledLink";
import { useQuery } from "react-query";
import { getPeriods, getStatements, StatementI } from "../../../utils/apiRequests";
const CommoditiesProductionControlsPanel = () => {
  const { selectedLayers } = useContext(MapPageContext);
  const { period, setPeriod } = useContext(ProductTreeSelectContext);

  const { data: statements } = useQuery([], getStatements, { staleTime: Infinity });

  const { data: allPeriods } = useQuery(["/com/trades-average-periods", "prd"], getPeriods, { staleTime: Infinity });

  const opts: PeriodSliderContext = {
    options: allPeriods,
    defaultOption: period,
    hideFirstLastLabel: true,
    setPeriod
  };

  const objStatement: StatementI =
    statements?.find(function (el) {
      return el.source === "PRDFAO";
    }) || {};
  const description = (
    <Typography sx={{ fontSize: "0.75rem" }}>
      {objStatement.text_before}
      <StyledLinkLeaflet href={objStatement.link1_target} target="_blank">
        {objStatement.link1_text}
      </StyledLinkLeaflet>
      {objStatement.text_between}
      <StyledLinkLeaflet target="_blank" href={objStatement.link2_target}>
        {objStatement.link2_text}
      </StyledLinkLeaflet>
      {objStatement.text_after}
    </Typography>
  );
  return (
    <Control position="topRight">
      <MapButton
        tooltip="Layers"
        anchorposition={{ horizontal: "left" }}
        containerStyle={{ width: 300 }}
        popupWrapperStyle={{ maxHeight: "60vh" }}
        popupContent={
          <Box className="flex flex-column gap-10" sx={{ px: 0.75 }}>
            <LayersControlsSection description={description} />
            {selectedLayers && selectedLayers.length > 0 && (
              <Fragment>
                <Typography fontWeight={500} sx={{ pl: 1 }}>
                  Product
                </Typography>
                <ProductTreeSelect />
                <Typography fontWeight={500} sx={{ pl: 1 }}>
                  Period
                </Typography>
                <PeriodSlider {...opts} />
              </Fragment>
            )}
          </Box>
        }
      >
        <LayersIcon />
      </MapButton>
    </Control>
  );
};

export default CommoditiesProductionControlsPanel;
