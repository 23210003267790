import {
  apiFieldsMappingTMFStatistics,
  apiFieldsMappingDEGStatistics,
  apiFieldsMappingDEFStatistics,
  apiFieldsMappingACStatistics
} from "./apiFieldsMapping";

type TMFStatisticsApiKey = keyof typeof apiFieldsMappingTMFStatistics;
type DEGStatisticsApiKey = keyof typeof apiFieldsMappingDEGStatistics;
type DEFStatisticsApiKey = keyof typeof apiFieldsMappingDEFStatistics;
type ACStatisticsApiKey = keyof typeof apiFieldsMappingACStatistics;

export const DEGDataKeys: DEGStatisticsApiKey[] = ["total"];
export const DEFDataKeys: DEFStatisticsApiKey[] = ["total"];
export const ACSDataKeys: TMFStatisticsApiKey[] = [
  "undisturbed_TMF",
  "degraded_TMF",
  "deforested_Land",
  "forest_Regrowth"
];
export const MCDataKeys: TMFStatisticsApiKey[] = [
  "undisturbed_TMF",
  "degraded_TMF",
  "forest_Regrowth",
  "deforested_Land_Plantations",
  "deforested_Land_Water",
  "deforested_Land_OtherLC",
  "ongoing_Disturbance"
];

export const ACDataKeys: ACStatisticsApiKey[] = [
  "degradation_Not_Followed_By_Deforestation",
  "degradation_Followed_By_Deforestation",
  "deforestation_After_Degradation",
  "forest_Conversion_Water",
  "forest_Conversion_Plantation",
  "total_Direct_Deforestation",
  "total_Deforestation"
];

export const ACLineDataKeys: ACStatisticsApiKey[] = [];
