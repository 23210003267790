import colorScales from "constants/colorScales";

export const gaugeChartsColors: any = {
  porosity: colorScales.lightgrayscale,
  fadAvg: colorScales.fragmentation,
  referenceAreaCoverage: colorScales.altgreenscale,
  coherence: colorScales.altgreenscale,
  restorationPotential: colorScales.grayscale,
  changeFragmentation: colorScales.PositiveNegative
};
