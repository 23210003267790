import { useEffect, useRef, useState } from "react";

const useHeaderHeight = () => {
  const headerRef = useRef<HTMLElement | null>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const found = document.getElementById("HEADER_WRAPPER");
    if (!found) return;
    headerRef.current = found;
  }, []);

  useEffect(() => {
    if (!headerRef.current) return;

    const resizeObserver = new ResizeObserver((e) => {
      const newHeight = e[0].target.clientHeight;
      setHeight(newHeight);
    });

    resizeObserver.observe(headerRef.current);

    return () => resizeObserver.disconnect();
  }, [headerRef]);

  return height;
};

export default useHeaderHeight;
