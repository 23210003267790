import React from "react";
import { Box } from "@mui/material";
import { darkGreen } from "constants/colors";
import StyledLink from "../../../reusables/StyledLink";

const PADDING = 3;
const SidebarContent = () => {
  return (
    <>
      <Box padding={PADDING} color={"#006032"}>
        The Global Map of Forest Cover Changes and their Drivers is a combination of global layers on forest disturbance
        types from 2016 to 2023 and follows{" "}
        <StyledLink
          href="https://www.science.org/doi/full/10.1126/science.aau3445"
          target="_blank"
          color={darkGreen}
          aria-label="Link to Classifying drivers of global forest loss (external link will be opened in a new window)"
        >
          the stratification of the main drivers of forest cover loss
        </StyledLink>{" "}
        e.g. deforestation (commodity driven and shifting agriculture), forest harvest and wildfires. <br />
        <br />
        In areas of large-scale forestry activities, the Global Forest Change (GFC) data is used to identify harvested
        forest area. In the maps, harvested forest intensity is expressed as the relative amount of forest area, but the
        statistics show the total harvested forest area per year. Using the methodology developed in{" "}
        <StyledLink
          href="https://www.nature.com/articles/s41586-020-2438-y"
          target="_blank"
          color={darkGreen}
          aria-label="Link to Abrupt increase in harvested forest area over Europe after 2015 (external link will be opened in a new window)"
        >
          Ceccherini et al 2020
        </StyledLink>
        , forest area from FAOSTAT data for 2015 defines for each country the minimum tree cover that qualifies as
        forest using the GFC maps. The minimum mapping unit (MMU) is 0.5 ha to match the FAO definition of forest. To
        quantify harvest as the only large-scale forestry operations, the approach excludes losses from{" "}
        <StyledLink
          href="https://glad.umd.edu/dataset/Fire_GFL/"
          target="_blank"
          color={darkGreen}
          aria-label="Link to Global forest loss due to fire (external link will be opened in a new window)"
        >
          forest fires
        </StyledLink>{" "}
        and other natural disturbances due to extreme events. <br />
        <br />
        The approach has limitations in the detection of small-scale silvicultural practices. GFC data based on Landsat
        imagery at 30m spatial resolution maps stand-replacement disturbances linked with clear-cuts and does not
        capture partial removal of trees caused by forest thinning, selective logging, continuous cover forestry or
        operations with rotation length shorter than 10 years. In addition, most changes below the canopy cannot be
        detected by optical instruments, which may lead to an underestimation of the actual harvested area.
        <br />
        <br />
        For areas outside large-scale forestry activity operations, this dataset combines GFC data on tree cover loss
        (using a minimum of 10 % tree cover in 2000, a MMU of 0.5 ha) with the{" "}
        <StyledLink
          href="https://forobs.jrc.ec.europa.eu/TMF/"
          target="_blank"
          color={darkGreen}
          aria-label="Link to Tropical Forest Monitoring tracking (external link will be opened in a new window)"
        >
          JRC-TMF dataset
        </StyledLink>{" "}
        on deforestation of tropical moist forests to map global deforestation. The main driver behind deforestation is
        agricultural expansion, albeit it may also include e.g., urbanization, road construction and mining.
        <br />
        <br />
        Disturbances encompass fires in and outside of areas of large scale forestry operations, extreme events, and
        other types of disturbances of short duration are detected in the JRC-TMF dataset, e.g., by selective logging,
        understory fires or natural events impacting the tropical moist forest structure with no change in land use.
        Fires are mapped based on the Global forest loss due to fire dataset and are areas of burned forest vegetation
        with no agricultural conversion. Outside of areas of large-scale forestry operations, fires are mainly located
        where wildfire is the dominant driver of forest cover loss and in tropical humid forests when burned forest
        vegetation is not followed by agricultural expansion. Extreme events correspond to forest cover loss inside
        forest harvest areas occurring at a larger scale than harvested forests. Anomalies in time-series of the
        percentage of forest cover loss in 0.2 degrees grids allowed separating forest harvesting from major extreme
        events due to windstorms, pests or biotic disturbances. Note that short-rotation forestry (i.e. areas
        characterized by intense management) and intense fires that may be partially mapped by the GFC data can be
        erroneously classified as extreme events.
        <br />
      </Box>
    </>
  );
};

export default SidebarContent;
