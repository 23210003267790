import { useState, useEffect, useContext } from "react";
import { Map } from "leaflet";
import { Resizable } from "re-resizable";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { MapPageContext } from "components/reusables/MapPage";
import { Clear } from "@mui/icons-material";

const TRANSITION_MS = 300;

const dashboardStyle: React.CSSProperties = {
  display: "flex",
  padding: 0,
  transition: `width ${TRANSITION_MS}ms`,
  overflow: "hidden"
} as const;

interface ResizableSidebarPropsI {
  map: null | Map;
  isOpen: boolean;
  children: React.ReactNode;
}

const DEFAULT_DESKTOP_WIDTH = 750;
const DEFAULT_MOBILE_WIDTH = "100vw";

const ResizableSidebar = (props: ResizableSidebarPropsI) => {
  let DEFAULT_WIDTH: string | number = 750;

  const { setIsSidebarVisible } = useContext(MapPageContext);

  const { map, isOpen, children } = props;
  const [width, setWidth] = useState<string | number>(0);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isSmallScreen) DEFAULT_WIDTH = DEFAULT_MOBILE_WIDTH;
    else DEFAULT_WIDTH = DEFAULT_DESKTOP_WIDTH;
  }, [isSmallScreen]);

  useEffect(() => {
    // update the map as soon as the isOpen state changes
    // without waiting for size listener to kick in
    // for better UX
    if (isOpen === false) setWidth(0);
    if (isOpen === true) setWidth(DEFAULT_WIDTH);
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      map?.invalidateSize();
    }, TRANSITION_MS);
  }, [width]);

  const style = { ...dashboardStyle };

  return (
    <Resizable
      style={style}
      size={{ width, height: "100%" }}
      enable={{ left: true }}
      maxWidth={isSmallScreen ? "100vw" : "50%"}
      minWidth={0}
      onResizeStop={(e, direction, ref, d) => {
        setWidth(ref.offsetWidth + d.width);
        if (map) map.invalidateSize();
      }}
    >
      {children}
      {isSmallScreen && (
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            display: "flex",
            justifyContent: "center"
          }}
          className="w-full"
        >
          <Button
            onClick={() => setIsSidebarVisible(false)}
            variant="outlined"
            sx={{ backgroundColor: "white" }}
            startIcon={<Clear />}
          >
            CLOSE SIDEBAR
          </Button>
        </Box>
      )}
    </Resizable>
  );
};

export default ResizableSidebar;
