import styled from "@emotion/styled";
import { Slider } from "@mui/material";
import { Theme } from "@mui/material/styles";

const BAR_HEIGHT = 12;

type MuiTheme = {
  theme?: Theme;
  value: number | undefined; // don't allow array of numbers
};

const CustomSlider = styled(Slider)<MuiTheme>((props) => {
  const { theme, min = 0, max = 100, value } = props;
  const { primary, mode } = theme?.palette || {};

  let currentValue = 0;
  if (typeof value === "object") currentValue = value[0];
  if (typeof value === "number") currentValue = value;

  // calculate displayed track width percent based on min and max values;
  // the calculation works only with single value, not array
  const computedPercent = ((currentValue - min) * 100) / (max - min);

  return {
    color: ["#48B749"],
    height: 3,
    padding: "13px 0",
    "& .MuiSlider-thumb": {
      height: BAR_HEIGHT,
      width: BAR_HEIGHT,
      backgroundColor: "#fff",
      border: "1px solid currentColor",
      "&:hover": {
        boxShadow: `0 0 0 8px ${primary?.main}20`
      },
      "& .airbnb-bar": {
        height: 3,
        width: 1,
        backgroundColor: "currentColor",
        marginLeft: 1,
        marginRight: 1
      }
    },
    "& .MuiSlider-track": {
      height: BAR_HEIGHT,
      width: `calc(${computedPercent}% + ${BAR_HEIGHT}px) !important`,
      left: `${-BAR_HEIGHT / 2}px !important`
    },
    "& .MuiSlider-rail": {
      color: mode === "dark" ? "#bfbfbf" : "#eee",
      opacity: mode === "dark" ? undefined : 1,
      height: BAR_HEIGHT,
      width: `calc(100% + ${BAR_HEIGHT}px)`,
      left: -BAR_HEIGHT / 2,
      border: "0.5px solid grey"
    }
  };
});

export default CustomSlider;
