import { GeoServerClient } from "utils/ogcClients";
import { GetMapParametersI, LayerConfigI, LayerDefinitionI, LayerParamsI } from "types";
import { getLayersConfig, handleLayersName, makeCommonConfig, MakeConfigParamsI } from "utils/layerConfigUtils";
import { ComPrdCategoryType } from "constants/layersCategories";
import { useContext } from "react";
import { MapPageContext } from "components/reusables/MapPage";

const geoServerClient = new GeoServerClient();
export const defaultLayer = "prd";

export const layersDefinitions: LayerDefinitionI[] = [
  {
    type: "layer",
    name: "LULUCF",
    layerName: "carbon_average",
    title: "LULUCF",
    description: "(total carbon fluxes from the land use, land-use change and forestry sector)",
    defaultSelected: true
  },
  {
    type: "layer",
    name: "FOREST",
    layerName: "carbon_average",
    title: "Forest",
    description: "(including forest land remaining forest land and land converted to forest land)"
  },
  {
    type: "layer",
    name: "DEFORESTATION",
    layerName: "carbon_average",
    title: "Deforestation",
    description:
      "(forest land converted to other land use categories such as cropland, grassland, wetland, settlements, other"
  },
  {
    type: "layer",
    name: "OTHER",
    layerName: "carbon_average",
    title: "Other land uses",
    description: "(cropland, grassland, wetland, settlements, other land)"
  },
  {
    type: "layer",
    name: "ORG_SOILS",
    layerName: "carbon_average",
    title: "Organic Soils",
    description: "(from all land use categories)"
  },
  {
    type: "layer",
    name: "HWP",
    layerName: "carbon_average",
    title: "HWP",
    description: "(harvested wood products)"
  }
];

const getViewParams = (params: LayerParamsI) => {
  const { selectedLayers } = useContext(MapPageContext);

  if (selectedLayers && selectedLayers[0] && selectedLayers[0].id)
    params.viewParams = "category:" + selectedLayers[0].id + ";";
};

const makeConfig = (props: MakeConfigParamsI): LayerConfigI => {
  const { definition, year } = props;
  const { name } = definition;

  const params: GetMapParametersI = {
    layers: handleLayersName(definition, year),
    ...geoServerClient.WMSParams
  };

  return {
    ...makeCommonConfig(props, geoServerClient),
    params,
    functionToUse: getViewParams
  };
};

export const layersConfig = getLayersConfig(layersDefinitions, makeConfig);
