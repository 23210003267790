import { Typography } from "@mui/material";
import { TmfCategoryType } from "constants/layersCategories";

export const tmfCategoryToTitle: Record<TmfCategoryType, string> = {
  deforestation: "Deforestation year",
  degradation: "Degradation year",
  "annual-change": "Annual Change",
  "main-classes": "Transition Map - Main classes"
};

const layersDescriptions = {
  deforestation: (
    <Typography sx={{ textAlign: "justify" }}>
      The deforestation year maps the year when the forest cover has been deforested for the first time (followed or not
      by a regrowth). It concerns all the deforested classes of the transition map including the mangroves that have
      been deforested, the conversion into tree plantation, the conversion into water and the recent deforestation
      (2020-2023).
    </Typography>
  ),
  degradation: (
    <Typography sx={{ textAlign: "justify" }}>
      The degradation year maps the year when the forest cover has been degraded for the first time. It concerns all the
      degraded forest classes of the transition map including the mangroves and the recent degradation (2023).
    </Typography>
  ),
  "annual-change": (
    <Typography sx={{ textAlign: "justify" }}>
      The annual change depicts the tropical moist forest extent and the related disturbances (deforestation and
      degradation), and post-disturbances for each year between 2000 and 2023. The timeline allows seeing how the
      tropical moist forest is changing over the past 2 decades.
    </Typography>
  ),
  "main-classes": (
    <Typography sx={{ textAlign: "justify" }}>
      The transition map captures the sequential dynamics of changes by providing transition stages from the first year
      of the monitoring period to the end of the year 2023. It depicts five main transition classes: (i) remaining
      undisturbed moist forests where no disturbance has been observed over the Landsat historical record over the
      period 1982-2023 (including the mangroves and the bamboo-dominated forest), (ii) degraded forests corresponding
      mostly to either logged or burned forests (iii) young forest regrowth, (iv) deforested land and (v) non tropical
      moist forest cover (including afforestation).
    </Typography>
  )
};

export default layersDescriptions;
