import { Box } from "@mui/material";
import WithCountryData from "components/reusables/WithCountryData";

import LayerCharts from "./LayerCharts";
import EvolutionCharts from "./EvolutionCharts";

const PADDING = 3;

const GlobalForestAttributesCharts = () => {
  return (
    <WithCountryData>
      <>
        <Box sx={{ padding: PADDING }}>
          <LayerCharts />
          <EvolutionCharts />
        </Box>
      </>
    </WithCountryData>
  );
};

export default GlobalForestAttributesCharts;
