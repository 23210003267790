import TreemapChart from "components/charts/TreemapChart";
import { SeriesTreemapOptions } from "highcharts";
import { useContext } from "react";
import { useQuery } from "react-query";
import { getProductionAverageByProduct } from "utils/apiRequests";
import { ProductTreeSelectContext } from "components/pages/CommoditiesProductionPage/ProductTreeSelect";
import { MapPageContext } from "components/reusables/MapPage";

import { intervalColorCode } from "./index";
import { CountryFlagString } from "../../../reusables/CountryFlag";

const CommodityChart = () => {
  const { selectedLayers } = useContext(MapPageContext);
  const { product, period } = useContext(ProductTreeSelectContext);
  const { product_code, product_name } = product || {};

  if (!selectedLayers || selectedLayers.length === 0) return null;
  const category = selectedLayers[0].comPrdCategory ? selectedLayers[0].comPrdCategory : "prd";

  const queryOptions = {
    queryKey: ["productAverageProduction", category, product_code, period],
    queryFn: getProductionAverageByProduct,
    staleTime: Infinity,
    enabled: !!period && !!product_code
  };

  const { data, isLoading, error } = useQuery(queryOptions);

  if (isLoading) return <>loading...</>;
  if (!data || error) return null;
  if (data.length === 0)
    return (
      <>
        No production for {product_name} in {period}
      </>
    );

  const countryNames = [...new Set(data.map((i) => i.country_name))];

  const rootItems = countryNames.map((i) => ({
    id: i,
    name: i
  }));

  const mapped: SeriesTreemapOptions["data"] = data.map((i) => {
    return {
      name: i.country_name,
      value: i.avg_value,
      color: intervalColorCode[i.interval],
      unit: i.unit,
      image: CountryFlagString({ countryCode: i.country_code })
    };
  });

  return (
    <>
      <TreemapChart
        title={`Top ten countries for ${
          category === "prd"
            ? "average production quantity"
            : category === "prd_area"
            ? "average area harvested"
            : "yield"
        } of ${product_name} in ${period}`}
        seriesData={[...rootItems, ...mapped]}
        pointFormat="<b>{point.name}</b> is <b>{point.value} ({point.unit})"
      />
    </>
  );
};

export default CommodityChart;
