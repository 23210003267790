import { Box, Typography } from "@mui/material";
import { useContext } from "react";

import { MapPageContext } from "../../../reusables/MapPage";
import AnnualVariationCharts from "./AnnualVariationCharts";
import CommodityChart from "./CommodityChart";

import AverageChart from "./AverageChart";

const PADDING = 3;

export const intervalColorCode = [
  "#AAF7FFBF",
  "#72D9FFBF",
  "#3FA0FFBF",
  "#264DFFBF",
  "#282AD8BF",
  "#FFFDBFBF",
  "#FFE099BF",
  "#FFAD72BF",
  "#F76D5EBF",
  "#D82632BF",
  "#A51520BF"
];

const GlobalForestAttributesCharts = () => {
  const { selectedCountryCode } = useContext(MapPageContext);

  const Allways = () => {
    return (
      <>
        <Box sx={{ padding: PADDING }}>
          <CommodityChart />
        </Box>
      </>
    );
  };

  if (selectedCountryCode && selectedCountryCode !== "ZZ") {
    return (
      <>
        <Box sx={{ padding: PADDING }}>
          <AverageChart />
        </Box>
        <Box sx={{ padding: PADDING }}>
          <AnnualVariationCharts />
        </Box>
        <Allways />
      </>
    );
  } else {
    return <Allways />;
  }
};

export default GlobalForestAttributesCharts;
