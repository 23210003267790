import { useContext } from "react";
import { Box } from "@mui/material";
import { FormGroup } from "@mui/material";
import { MapPageContext } from "../../MapPage";
import LayerGroupYears from "./LayerGroupYears";
import SimpleLayer from "./SimpleLayer";

import {
  instanceOfLayerConfigI,
  instanceOfLayerGroupConfigI,
  instanceOfLayerGroupYearsConfigI
} from "../../../../types/typesUtils";
import LayersGroup from "./LayersGroup";

const LayersSelector = () => {
  const { layersConfig } = useContext(MapPageContext);

  return (
    <Box sx={{ width: "auto", maxWidth: 250, paddingTop: 1 }} mx={2}>
      <FormGroup sx={{ pr: 2 }}>
        {layersConfig.map((layerTreeItem, index) => {
          if (instanceOfLayerGroupConfigI(layerTreeItem)) return <LayersGroup key={index} />;
          if (instanceOfLayerGroupYearsConfigI(layerTreeItem))
            return <LayerGroupYears key={index} layerConfig={layerTreeItem} />;
          if (instanceOfLayerConfigI(layerTreeItem)) return <SimpleLayer key={index} layerConfig={layerTreeItem} />;
          return null;
        })}
      </FormGroup>
    </Box>
  );
};

export default LayersSelector;
