import { Box } from "@mui/material";
import { useContext } from "react";

import { TradePageContext } from "components/pages/CommoditiesTradePage";
import CustomSlider from "components/reusables/CustomSlider";

const ThresholdSlider = () => {
  const { threshold, setThreshold } = useContext(TradePageContext);
  return (
    <Box sx={{ px: 3 }} className="flex items-center">
      <Box width="100%">
        <CustomSlider
          value={threshold}
          valueLabelDisplay="auto"
          step={1}
          min={30}
          max={100}
          onChange={(_, value) => {
            if (typeof value === "number") setThreshold(value);
          }}
        />
      </Box>
      <Box width={50} textAlign="right">
        {threshold} %
      </Box>
    </Box>
  );
};

export default ThresholdSlider;
