import TimeSlider from "components/reusables/TimeSlider";
import { useContext } from "react";
import { LayerGroupYearsConfigI } from "../../types";
import { instanceOfLayerGroupYearsConfigI } from "../../types/typesUtils";
import { MapPageContext } from "./MapPage";

const LayersTimeSlider = () => {
  const { selectedLayers, setSelectedLayers, layersConfig, onlyOneLayerVisible } = useContext(MapPageContext);

  const parentName = selectedLayers[0]?.parentName;
  const foundParent = layersConfig.find((i) => {
    if (instanceOfLayerGroupYearsConfigI(i) && parentName) {
      return i.name === parentName;
    } else return false;
  }) as LayerGroupYearsConfigI | undefined;

  const years = foundParent?.years || [];

  const handleLayerSelection = (newYear: number) => {
    if (!foundParent) return;
    const foundChild = foundParent.children.find((i) => i.year === newYear);
    if (onlyOneLayerVisible) {
      if (foundChild) setSelectedLayers([foundChild]);
    } else {
      // TODO logic needs to be replicated for all parents
    }
  };

  return <TimeSlider onChange={handleLayerSelection} years={years} />;
};

export default LayersTimeSlider;
