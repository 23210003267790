import Box from "@mui/material/Box";
import { TradePageContext } from "components/pages/CommoditiesTradePage";
import CustomSlider from "components/reusables/CustomSlider";
import { useContext } from "react";
import { useQuery } from "react-query";
import { getPeriods } from "utils/apiRequests";

interface PeriodSliderPropsI {
  options?: string[];
  onChange?: (newValue: string) => void;
  hideFirstLastLabel?: boolean;
}

// This component accepts an array of strings and feeds the slider with the respective indexes
// because the slider value needs to be a number
function TradePeriodSlider(props: PeriodSliderPropsI) {
  const { onChange, hideFirstLastLabel = true } = props;
  const { period, setPeriod, periodOptionsApiPath, layer } = useContext(TradePageContext);

  const sliderValue = period;

  const { data: options, isLoading } = useQuery([periodOptionsApiPath, layer], getPeriods);

  if (isLoading) return <>Loading...</>;
  if (!options || options.length === 0) return <Box pl={2}>No options available</Box>;

  if (!period) return null;
  const foundValue = options.indexOf(period);
  if (foundValue === undefined) return null;

  const marks = options.map((i, index) => {
    const label = sliderValue === i ? i.toString() : undefined;
    const labelElement = <div>{label}</div>;
    return { value: index, label: labelElement };
  });

  const min = 0;
  const max = options.length - 1;

  const handleChange = (e: any, newValue: any) => {
    const foundPeriod = options[newValue];
    if (foundPeriod !== undefined) setPeriod(foundPeriod);
    if (onChange) onChange(foundPeriod);
  };

  function valueLabelFormat(value: number) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  return (
    <Box pl={3} pr={3}>
      <Box
        sx={{
          borderRadius: 0.5,
          height: 50
        }}
      >
        <CustomSlider
          min={min}
          max={max}
          value={foundValue}
          valueLabelFormat={valueLabelFormat}
          step={null}
          valueLabelDisplay="off"
          marks={marks}
          onChange={handleChange}
          style={{ marginBottom: 0 }}
        />
      </Box>
    </Box>
  );
}

export default TradePeriodSlider;

export interface PeriodSliderContext extends PeriodSliderPropsI {
  options?: string[];
  onChange?: (newValue: string) => void;
  hideFirstLastLabel?: boolean;

  defaultOption?: string;
  setPeriod?: (newValue: string) => void;
}

// This component accepts an array of strings and feeds the slider with the respective indexes
// because the slider value needs to be a number
export function PeriodSlider(props: PeriodSliderContext) {
  const { onChange, hideFirstLastLabel = true, options, defaultOption, setPeriod } = props;

  if (!options || options.length === 0) return <Box pl={2}>No options available</Box>;

  const sliderValue = defaultOption;
  const foundValue = defaultOption ? options.indexOf(defaultOption) : undefined;
  //  if (foundValue === undefined) return null;

  const marks = options.map((i, index) => {
    const label = sliderValue === i ? i.toString() : undefined;
    const labelElement = <div>{label}</div>;
    return { value: index, label: labelElement };
  });

  const min = 0;
  const max = options.length - 1;

  const handleChange = (e: any, newValue: any) => {
    const foundPeriod = options[newValue];
    if (foundPeriod !== undefined && setPeriod !== undefined) setPeriod(foundPeriod);
    if (onChange) onChange(foundPeriod);
  };

  function valueLabelFormat(value: number) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  return (
    <Box pl={3} pr={3}>
      <Box
        sx={{
          borderRadius: 0.5,
          height: 50
        }}
      >
        <CustomSlider
          min={min}
          max={max}
          value={foundValue}
          valueLabelFormat={valueLabelFormat}
          step={null}
          valueLabelDisplay="off"
          marks={marks}
          onChange={handleChange}
          style={{ marginBottom: 0 }}
        />
      </Box>
    </Box>
  );
}
