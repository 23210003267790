import { ReactNode, useContext } from "react";

import { MapPageContext } from "components/reusables/MapPage";
import { Box, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { getCountries } from "utils/apiRequests";
import CountryFlag from "components/reusables/CountryFlag";

interface PropsI {
  layerTitle?: ReactNode;
  replaceTitle?: boolean | true;
}
/*Country name not dependent on current option*/
export const getCountryName: (context: any) => string = (context: any) => {
  const { selectedCountryCode } = context;
  let countryName = "NO COUNTRY SELECTED";
  const { data: countries } = useQuery({
    queryKey: ["countries", "/gen/Countries"],
    queryFn: getCountries,
    staleTime: Infinity,
    enabled: !!selectedCountryCode
  });

  if (selectedCountryCode) {
    const foundCountry = countries?.find((i) => i.country_code === selectedCountryCode);

    if (foundCountry) countryName = foundCountry.country_name;
    else if (selectedCountryCode === "EU27") countryName = "European Union";
    else if (selectedCountryCode === "ROW") countryName = "Rest of the world";
  }
  return countryName;
};

export const SelectedCountryLayerTitle = (props: PropsI) => {
  const context = useContext(MapPageContext);
  const { selectedLayers, selectedCountryCode } = context;
  const countryName = getCountryName(context as any);
  let layerTitle: ReactNode = "";

  if (selectedLayers[0] && selectedLayers[0].title) layerTitle = `${selectedLayers[0].title}`;

  if (props.layerTitle) {
    if (props.replaceTitle) layerTitle = props.layerTitle;
    else layerTitle = (selectedLayers[0] ? `${selectedLayers[0].title}` + " " : "") + props.layerTitle;
  }

  return (
    <Box className="flex" sx={{ my: "auto" }}>
      <Box className="flex items-center gap-10">
        <CountryFlag countryCode={selectedCountryCode} />
        <Typography variant="h6" fontWeight={500} style={{ textTransform: "uppercase" }}>
          {countryName}
        </Typography>
      </Box>
      {layerTitle && <Box sx={{ px: 1, display: "flex", alignItems: "center" }}>|</Box>}
      <Typography variant="h6" fontWeight={400}>
        {layerTitle}
      </Typography>
    </Box>
  );
};
