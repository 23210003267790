import { ReactNode } from "react";
import { HelpOutline } from "@mui/icons-material";
import { Box, BoxProps, IconButton, Tooltip, TooltipProps } from "@mui/material";

interface PropsI extends BoxProps {
  children: ReactNode;
  tooltipProps: Omit<TooltipProps, "children">;
  onIconClick?: () => void;
}

const WithInfoTooltipIcon = ({ children, tooltipProps, onIconClick, ...rest }: PropsI) => {
  return (
    <Box {...rest} className="flex gap-10 items-center">
      {children}
      <Tooltip arrow {...tooltipProps} sx={{ width: 0, height: 0 }}>
        <IconButton onClick={onIconClick} disabled={!onIconClick}>
          <HelpOutline htmlColor="#aaa" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default WithInfoTooltipIcon;
