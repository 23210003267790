export const removeOpacity = {
  color: (color: string) => {
    const split_color = color.split(",");
    const newColor = color.replace(split_color[3], " 1)");
    return newColor;
  },
  mainColor: (mainColor: string) => {
    const split_mainColor = mainColor.split(",");
    const newMainColor = mainColor.replace(split_mainColor[3], " 1)");
    return newMainColor;
  }
};
