import { GfaCategoryType } from "../../../constants/layersCategories";
import { MapServerClient } from "utils/ogcClients";
import { GetMapParametersI, LayerConfigI, LayerDefinitionI } from "../../../types";

import {
  getLayersConfig,
  handleLayersName,
  makeCommonConfig,
  MakeConfigParamsI
} from "../../../utils/layerConfigUtils";

const mapFilePrefix = "EUFC";
const mapServerClient = new MapServerClient(mapFilePrefix);

export const layersDefinitions: LayerDefinitionI[] = [
  {
    type: "layer",
    name: "fragmentation",
    layerName: "EUFF",
    title: "Forest Fragmentation"
  },
  {
    type: "layer",
    name: "pattern",
    layerName: "EUFP",
    defaultSelected: true,
    title: "Forest Pattern"
  }
];

const makeConfig = (props: MakeConfigParamsI): LayerConfigI => {
  const { definition, year } = props;
  const { name, mapFile } = definition;

  const mapFileName = mapFile ? mapFile : year ? `${mapFilePrefix}${year}` : mapFilePrefix;

  const params: GetMapParametersI = {
    layers: handleLayersName(definition, year),
    ...mapServerClient.WMSParams
  };

  return {
    ...makeCommonConfig(props, mapServerClient),
    params,
    gfaCategory: name as GfaCategoryType
  };
};

export const layersConfig = getLayersConfig(layersDefinitions, makeConfig);
