import { buildTradeId } from "components/pages/CommoditiesTradePage/TradePaths";
import { MapPageContext } from "components/reusables/MapPage";
import { LatLngTuple, popup } from "leaflet";
import { useContext, useEffect, useRef } from "react";
import { CircleMarker, Polyline } from "react-leaflet";
import { TradePathInfoI } from "types";

import * as ReactDOMServer from "react-dom/server";
import { Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import CountryFlag from "components/reusables/CountryFlag";

type Props = {
  trade: TradePathInfoI;
  selectedTrade: TradePathInfoI | undefined;
  onSelect: (trade?: TradePathInfoI) => void;
};

export type TradeCategory = "Combined" | "A" | "B" | "C";
export const colorByCategory: Record<TradeCategory, string> = {
  A: "#991111",
  B: "#445599",
  C: "#459944",
  Combined: "#459944"
};

// each kgs breakpoint defines the width until the next breakpoint
export const strokeWidthByKgsBreakpoints = {
  "0": 3,
  "100000": 5,
  "500000": 7,
  "1000000": 9,
  "10000000": 11,
  "20000000": 13
};

type BreakpointKey = keyof typeof strokeWidthByKgsBreakpoints;

const getStrokeWidth = (quantity: number) => {
  const breakpoints = Object.keys(strokeWidthByKgsBreakpoints) as BreakpointKey[];

  let lastBreakpoint: BreakpointKey = "0";
  let strokeWidth = 1;

  for (const breakpoint of breakpoints) {
    lastBreakpoint = breakpoint;
    if (quantity <= Number(breakpoint)) continue;
    else strokeWidth = strokeWidthByKgsBreakpoints[lastBreakpoint];
  }

  return strokeWidth;
};

const TradePath = (props: Props) => {
  const { setIsSidebarVisible, map } = useContext(MapPageContext);
  const { selectedTrade, onSelect } = props;
  const {
    fromLatLng,
    toLatLng,
    category,
    quantity,
    id,
    fromCountryName,
    toCountryName,
    unit,
    fromCountryCode,
    toCountryCode
  } = props.trade;
  const polylineRef = useRef<any>(null);
  const circleRef = useRef<any>(null);

  const strokeWidth = getStrokeWidth(quantity) * 2;
  const isSelected = selectedTrade && buildTradeId(selectedTrade) === id;

  const positions = [fromLatLng, toLatLng] as LatLngTuple[];
  const color = colorByCategory[category];
  const selectedColor = "#ffbb00";
  const hoverColor = "#ffee00";

  const popupMarkup = ReactDOMServer.renderToStaticMarkup(
    <>
      <Typography className="flex items-center gap-10 nowrap">
        <CountryFlag countryCode={fromCountryCode} style={{ height: 15 }} />
        {fromCountryName} <ArrowForward />
        <CountryFlag countryCode={toCountryCode} style={{ height: 15 }} />
        {toCountryName}
      </Typography>
      <Typography>
        {quantity.toLocaleString()} {unit}
      </Typography>
    </>
  );

  const leafletPopup = popup({ autoPan: false, maxWidth: 500, minWidth: 250 }).setContent(popupMarkup);

  const handleClick = () => {
    if (isSelected) onSelect(undefined);
    else onSelect(props.trade);

    setIsSidebarVisible(true);
  };

  const handleColor = () => {
    const newColor = isSelected ? selectedColor : color;
    polylineRef?.current?.setStyle({ color: newColor });
    circleRef?.current.setStyle({ color: newColor, fillColor: newColor });
  };

  const listenerEnter = () => {
    polylineRef?.current?.setStyle({ color: hoverColor });
    circleRef?.current.setStyle({ color: hoverColor, fillColor: hoverColor });
  };

  const listenerLeave = () => {
    leafletPopup.close();
    handleColor();
  };

  const listenerOver = (e: any) => {
    if (!map) return;
    const latlng = map?.mouseEventToLatLng(e);
    if (latlng) leafletPopup.setLatLng(latlng);
    leafletPopup.openOn(map);
  };

  useEffect(() => {
    const polylineEl: Element = polylineRef?.current?._path;
    const circleEl: Element = circleRef?.current?._path;

    if (polylineEl) {
      polylineEl.addEventListener("mouseenter", listenerEnter);
      polylineEl.addEventListener("mouseover", listenerOver);
      polylineEl.addEventListener("mouseleave", listenerLeave);
    }
    if (circleEl) {
      circleEl.addEventListener("mouseenter", listenerEnter);
      circleEl.addEventListener("mouseover", listenerOver);
      circleEl.addEventListener("mouseleave", listenerLeave);
    }
    return () => {
      if (polylineEl) {
        polylineEl.removeEventListener("mouseenter", listenerEnter);
        polylineEl.removeEventListener("mouseover", listenerOver);
        polylineEl.removeEventListener("mouseleave", listenerLeave);
      }
      if (circleEl) {
        circleEl.removeEventListener("mouseenter", listenerEnter);
        circleEl.removeEventListener("mouseover", listenerOver);
        circleEl.removeEventListener("mouseleave", listenerLeave);
      }
    };
  }, [polylineRef, selectedTrade]);

  useEffect(() => {
    handleColor();
  }, [selectedTrade]);

  return (
    <>
      <Polyline
        ref={polylineRef}
        positions={positions}
        opacity={0.5}
        weight={strokeWidth / 2}
        eventHandlers={{ click: handleClick }}
      />
      <CircleMarker
        ref={circleRef}
        center={positions[0]}
        radius={strokeWidth}
        fillOpacity={0.6}
        pathOptions={{ weight: 1 }}
        eventHandlers={{ click: handleClick }}
      />
    </>
  );
};

export default TradePath;
