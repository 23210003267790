export interface ColorScaleItemI {
  from: number;
  to: number;
  color: string;
}

const colorScales: Record<string, ColorScaleItemI[]> = {
  altgreenscale: [
    {
      from: 0,
      to: 20,
      color: "#FFFFFF" // white
    },
    {
      from: 20,
      to: 40,
      color: "#D0F0C0" // tea green
    },
    {
      from: 40,
      to: 60,
      color: "#ACE1AF" // celadon
    },
    {
      from: 60,
      to: 80,
      color: "#77DD77" // pastel green
    },
    {
      from: 80,
      to: 100,
      color: "#7BB661" // bud green
    }
  ],
  lightgreenscale: [
    {
      from: 0,
      to: 20,
      color: "#00EE00"
    },
    {
      from: 20,
      to: 40,
      color: "#00DD00" // very light green
    },
    {
      from: 40,
      to: 60,
      color: "#00CC00" // light green
    },
    {
      from: 60,
      to: 80,
      color: "#00BB00" // green
    },
    {
      from: 80,
      to: 100,
      color: "#00AA00" // dark green
    }
  ],
  greenscale: [
    {
      from: 0,
      to: 20,
      color: "#FFFFFF" // white
    },
    {
      from: 20,
      to: 40,
      color: "#00DD00" // very light green
    },
    {
      from: 40,
      to: 60,
      color: "#00BB00" // light green
    },
    {
      from: 60,
      to: 80,
      color: "#009900" // green
    },
    {
      from: 80,
      to: 100,
      color: "#007700" // dark green
    }
  ],
  lightgrayscale: [
    {
      from: 0,
      to: 20,
      color: "#FFFFFF" // white
    },
    {
      from: 20,
      to: 40,
      color: "#EEEEEE" // very light gray
    },
    {
      from: 40,
      to: 60,
      color: "#DDDDDD" // light gray
    },
    {
      from: 60,
      to: 80,
      color: "#CCCCCC" // gray
    },
    {
      from: 80,
      to: 100,
      color: "#BBBBBB" // dark gray
    }
  ],
  grayscale: [
    {
      from: 0,
      to: 20,
      color: "#FFFFFF" // white
    },
    {
      from: 20,
      to: 40,
      color: "#DDDDDD" // very light gray
    },
    {
      from: 40,
      to: 60,
      color: "#BBBBBB" // light gray
    },
    {
      from: 60,
      to: 80,
      color: "#999999" // gray
    },
    {
      from: 80,
      to: 100,
      color: "#777777" // dark gray
    }
  ],
  fragmentation: [
    {
      from: 0,
      to: 10,
      color: "#d63127" // red
    },
    {
      from: 10,
      to: 40,
      color: "#f98b59" // orange
    },
    {
      from: 40,
      to: 60,
      color: "#fec700" // yellow
    },
    {
      from: 60,
      to: 90,
      color: "#8bc763" // green
    },
    {
      from: 90,
      to: 100,
      color: "#00ae00" // dark green
    }
  ],
  PositiveNegative: [
    {
      from: 0,
      to: 100,
      color: "#0000ff" // blue
    },
    {
      from: -100,
      to: 0,
      color: "#d63127" // tea green
    }
  ]
};
export default colorScales;
