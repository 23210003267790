import { LatLng, DomUtil } from "leaflet";
import { GeoServerClient } from "utils/ogcClients";
import { useMap } from "react-leaflet";
import { useContext, useEffect } from "react";
import { getCountryFeatureByCoords } from "utils/ogcRequests";
import { GlobalContext } from "App";

interface ClickCountryHandlerPropsI {
  onCountryClick: (feature?: GeoJSON.Feature) => void;
  ogcServerClient: GeoServerClient;
}

const ClickCountryHandler = ({ onCountryClick, ogcServerClient }: ClickCountryHandlerPropsI) => {
  const map = useMap();
  const { notifications, pushNotification } = useContext(GlobalContext);

  const getCountry = async (coords: LatLng) => {
    const data = await getCountryFeatureByCoords(coords, ogcServerClient);
    if (!data) {
      console.error("NO COUNTRY DATA!");
      onCountryClick(undefined);
      return;
    }

    if (data.features[0]) {
      onCountryClick(data.features[0]);
    } else {
      pushNotification({
        type: "country-not-available",
        severity: "warning",
        timeout: 4000,
        message: "Country not available for this dataset!",
        width: 400
      });

      onCountryClick(undefined);
    }
  };

  const clickHandler = (e: any) => {
    const coords = e.latlng;
    getCountry(coords);
  };

  const container = map.getContainer();

  const mouseDownHandler = () => DomUtil.removeClass(container, "pointer-cursor-enabled");
  const mouseUpHandler = () => DomUtil.addClass(container, "pointer-cursor-enabled");

  useEffect(() => {
    map.addEventListener("click", clickHandler);

    // User pointer cursor instead of default pan hand
    DomUtil.addClass(container, "pointer-cursor-enabled");
    map.addEventListener("mousedown", mouseDownHandler);
    map.addEventListener("mouseup", mouseUpHandler);

    return () => {
      map.removeEventListener("click", clickHandler);
      map.removeEventListener("mousedown", mouseDownHandler);
      map.removeEventListener("mouseup", mouseUpHandler);
    };
  }, [notifications]);

  return null;
};

export default ClickCountryHandler;
