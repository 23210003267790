import { Box, SxProps, Typography } from "@mui/material";
import { Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useState } from "react";

const borderStyle = "1px solid grey";

const Tab = ({ tab, index, activeTabIndex, activateTab }: any) => {
  const handleClick = () => activateTab();

  const isActive = index === activeTabIndex;

  const sx: SxProps = {
    p: 2,
    pl: 3,
    minWidth: 120,
    width: "fit-content",
    transition: "flex 0.3s ease",
    display: "flex"
  };

  if (isActive) {
    sx.borderTopLeftRadius = 8;
    sx.borderTopRightRadius = 8;
    sx.bgcolor = "white";
    sx.flexGrow = 1;
    sx.borderBottom = "0px solid transparent";

    sx.borderLeft = borderStyle;
    sx.borderTop = borderStyle;
    sx.borderRight = borderStyle;
  }

  if (!isActive) {
    sx.cursor = "pointer";
    sx.borderBottom = borderStyle;
    sx.color = "grey";
    sx.fontStyle = "italic";
  }

  const isBeforeActiveTab = index === activeTabIndex - 1;

  if (isBeforeActiveTab) {
    sx.borderRightWidth = 0;
  }

  let title: ReactNode = "";
  if (typeof tab.title === "string") {
    title = (
      <Typography fontWeight={400} sx={{ my: "auto" }}>
        {tab.title}
      </Typography>
    );
  }
  if (typeof tab.title === "object") title = tab.title;

  return (
    <Box sx={sx} onClick={handleClick}>
      {title}
    </Box>
  );
};

interface TabsBarPropsI {
  activeTabIndex: number;
  setActiveTabIndex: Dispatch<SetStateAction<number>>;
  tabs: TabConfigI[];
}

const TabsBar = ({ activeTabIndex, setActiveTabIndex, tabs }: TabsBarPropsI) => {
  const handleActivateTab = (newIndex: number) => {
    setActiveTabIndex(newIndex);
  };

  return (
    <Box
      sx={{
        height: 60,
        bgcolor: "#eee",
        borderTop: "1px solid #eee",
        paddingTop: 0.1,
        position: "sticky",
        top: 0,
        zIndex: 2
      }}
      className="flex"
    >
      {tabs.map((i, index) => (
        <Tab
          tab={i}
          key={index}
          index={index}
          activeTabIndex={activeTabIndex}
          activateTab={() => handleActivateTab(index)}
        />
      ))}
    </Box>
  );
};

export interface TabConfigI {
  title: string | ReactElement;
  content: ReactNode;
}

interface SidebarPropsI {
  tabs: TabConfigI[];
  activeTabIndex?: number;
  setActiveTabIndex?: Dispatch<SetStateAction<number | undefined>>;
}

const SidebarTabs = (props: SidebarPropsI) => {
  const { tabs, setActiveTabIndex: setActiveTabIndexProps } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // The active tab index can be controlled or uncontrolled
  // In case it is controlled, these effects keep the local state in sync with the external one
  // This allows to use the component independently from the MapPage context
  useEffect(() => {
    if (props.activeTabIndex) setActiveTabIndex(props.activeTabIndex);
  }, [props.activeTabIndex]);

  useEffect(() => {
    if (setActiveTabIndexProps) setActiveTabIndexProps(activeTabIndex);
  }, [activeTabIndex]);

  return (
    <Box className="w-full h-full overflow-auto" sx={{ borderLeft: "0.25px solid grey" }}>
      <TabsBar {...{ activeTabIndex, setActiveTabIndex, tabs }} />
      <Box sx={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }} className="h-full">
        {tabs[activeTabIndex].content}
      </Box>
    </Box>
  );
};

export default SidebarTabs;
