import TreemapChart from "components/charts/TreemapChart";
import { TradePageContext } from "components/pages/CommoditiesTradePage";
import { SeriesPieOptions, SeriesTreemapOptions } from "highcharts";
import { useContext } from "react";
import { useQuery } from "react-query";
import { getTrades, getTradesImporters } from "utils/apiRequests";
import { CountryFlagString } from "../../reusables/CountryFlag";
import { formatedNumber } from "../../../utils/chartsUtils";

//const color = "#de3b80";
type Props = {
  toCountryCode: string;
  toCountryName: string;
  importer?: boolean | false;
};

const TradedCommodities = (props: Props) => {
  const { period, product, layer } = useContext(TradePageContext);

  const { toCountryCode, toCountryName, importer } = props || {};

  const queryOptions = {
    queryKey: [importer ? "tradesAverageImporters" : "tradesAverage", toCountryCode, period, product, layer],
    queryFn: importer ? getTradesImporters : getTrades,
    staleTime: Infinity,
    enabled: !!period && !!toCountryCode
  };

  const { data, isLoading, error } = useQuery(queryOptions);

  if (isLoading) return <>loading...</>;
  if (!data || error) return null;
  if (data.length === 0)
    return (
      <>
        No trade found for {product?.product_name} to {toCountryName} in {period}
      </>
    );

  const topTenExporters = [...new Set(data.filter((item, idx) => idx < 10))];

  const mapped: SeriesTreemapOptions["data"] = topTenExporters.map((i, idx) => {
    return {
      value: i.avg_value,
      valueFormated: formatedNumber(i.avg_value),
      unit: i.unit,
      parent: i.partner_code,
      name: i.partner_name,
      image: CountryFlagString({ countryCode: importer ? i.reporter_code : i.partner_code }),
      percentage: importer ? "" : "(" + formatedNumber(i.partner_code_percentage, 2) + "%)"
    };
  });
  const title = importer
    ? `Top ten importers of ${product?.product_name} from ${toCountryName} in ${period}`
    : `Top ten exporters of ${product?.product_name} to ${toCountryName} in ${period}`;

  return (
    <>
      <TreemapChart title={title} seriesData={[...mapped]} />
    </>
  );
};

export default TradedCommodities;
