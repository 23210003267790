import { LayerConfigI, LayerGroupConfigI, LayerGroupYearsConfigI } from ".";

export function instanceOfGenericGroupConfigI(object: any): object is LayerGroupConfigI | LayerGroupYearsConfigI {
  return "children" in object;
}

export function instanceOfLayerGroupYearsConfigI(object: any): object is LayerGroupYearsConfigI {
  return object.type === "years-group";
}

export function instanceOfLayerGroupConfigI(object: any): object is LayerGroupConfigI {
  return object.type === "group";
}

export function instanceOfLayerConfigI(object: any): object is LayerConfigI {
  return object.type === "layer";
}

export function isGeoJsonPolygon(object: any): object is GeoJSON.Polygon {
  return object.type === "Polygon";
}

export function isGeoJsonMultiPolygon(object: any): object is GeoJSON.MultiPolygon {
  return object.type === "MultiPolygon";
}
