import { Tooltip } from "@mui/material";
import React from "react";

interface PropsI {
  text: string;
  charactersCount: number;
}

const TruncateText = (props: PropsI) => {
  const { text, charactersCount } = props;
  const truncated = text.substring(0, charactersCount);

  const isTruncatedShorter = truncated.length < text.length;

  return (
    <Tooltip title={text} disableHoverListener={!isTruncatedShorter}>
      <span>
        {truncated}
        {isTruncatedShorter && "..."}
      </span>
    </Tooltip>
  );
};

export default TruncateText;
