import WebtoolsComponent, { defaultHighchartsData } from "components/charts/WebtoolsComponent";
import { Options, SeriesPieOptions } from "highcharts";
import { set } from "lodash";
import { pointFormatter } from "utils/chartsUtils";
import cloneDeep from "lodash/cloneDeep";

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;

const defaultData: Options = {
  ...defaultHighchartsData,
  chart: {
    type: "pie",
    height: 300
  },
  series: [
    {
      type: "pie",
      size: "80%",
      innerSize: "60%",
      data: []
    }
  ],
  tooltip: {
    pointFormatter: pointFormatter
  },
  plotOptions: {
    series: { dataLabels: { enabled: false } },
    pie: { borderWidth: 1, borderColor: "#ccc" }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: MAX_WIDTH,
          maxHeight: MAX_HEIGHT
        }
      }
    ]
  }
};

type Props = {
  data: SeriesPieOptions["data"];
  title?: string;
};

const PieChart = (props: Props) => {
  const { title, data } = props;

  let merged = cloneDeep(defaultData);

  merged = set(merged, "title.text", title);
  merged = set(merged, "series[0].data", data);

  return <WebtoolsComponent id={`pie_${title}`} data={merged} />;
};

export default PieChart;
