// This utility is used to generate an array of HEX colors
// between 2 given HEX colors

function hex(col: number) {
  const s = "0123456789abcdef";
  if (col == 0 || isNaN(col)) return "00";
  col = Math.round(Math.min(Math.max(0, col), 255));
  return s.charAt((col - (col % 16)) / 16) + s.charAt(col % 16);
}

/* Convert an RGB triplet to a hex string */
export function convertToHex(rgb: number[]) {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
}

/* Remove '#' in color hex string */
function trim(s: string) {
  return s.charAt(0) == "#" ? s.substring(1, 7) : s;
}

/* Convert a hex string to an RGB triplet */
function convertToRGB(hex: string) {
  const color = [];
  color[0] = parseInt(trim(hex).substring(0, 2), 16);
  color[1] = parseInt(trim(hex).substring(2, 4), 16);
  color[2] = parseInt(trim(hex).substring(4, 6), 16);
  return color;
}

function generateColors(colorStart: string, colorEnd: string, colorCount: number): string[] {
  // The beginning of your gradient
  const start = convertToRGB(colorStart);
  // The end of your gradient
  const end = convertToRGB(colorEnd);

  //Alpha blending amount
  let alpha = 0.0;

  const list = [];

  for (let index = 0; index < colorCount; index++) {
    const c: any[] = [];

    alpha += 1.0 / colorCount;

    c[0] = start[0] * alpha + (1 - alpha) * end[0];
    c[1] = start[1] * alpha + (1 - alpha) * end[1];
    c[2] = start[2] * alpha + (1 - alpha) * end[2];

    list.push(`#${convertToHex(c)}`);
  }

  return list.reverse();
}

export default generateColors;
