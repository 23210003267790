import { useContext } from "react";

import { apiFieldsMappingACStatistics } from "./utils/apiFieldsMapping";
import { MapPageContext } from "components/reusables/MapPage";
import { getACStatistics } from "utils/apiRequests";
import { makeData, makeHighchartsSeries } from "../../../../utils/chartsUtils";
import { ACDataKeys } from "./utils/keysGroups";
import { useQuery } from "react-query";
import StackedColumnChart from "components/charts/StackedColumnChart";

import { Typography } from "@mui/material";

const GlobalCharts = () => {
  const { selectedCountryCode: countryCode } = useContext(MapPageContext);

  const queryOptions = {
    queryKey: ["tmfAnnualChange", countryCode],
    queryFn: getACStatistics,
    staleTime: Infinity,
    enabled: true
  };

  const { data: dataAC, isLoading, error } = useQuery(queryOptions);

  if (!dataAC || dataAC.length === 0) return null;

  const allYears: string[] = [...new Set(dataAC.map((item) => item.year))];

  const dataAll = makeData(dataAC, ACDataKeys, apiFieldsMappingACStatistics, "Mha");
  const highAll = makeHighchartsSeries(dataAC, dataAll, allYears);

  const dataAC1 = highAll.map((item: any) => {
    let addValues: any = { type: "column", showInLegend: true, options: { colors: item.colors } };
    if (item.name === apiFieldsMappingACStatistics["total_Deforestation"].title) {
      addValues = {
        type: "line",
        color: "#ff0000",
        marker: {
          lineWidth: 2,
          lineColor: "#ff0000",
          fillColor: "#ff0000"
        },
        legend: false
      };
    }

    return { ...item, ...addValues };
  });

  const hasValues = dataAC1
    .map((item) => item.data.some((elem: number) => elem != 0))
    .find((elem: number) => elem != 0);

  if (hasValues === true) {
    return <StackedColumnChart data={dataAC1} title={"&nbsp;"} categories={allYears} yAxisTitle={"(Mha)"} />;
  } else
    return (
      <Typography variant={"h6"} style={{ lineHeight: "50px" }}>
        No values
      </Typography>
    );
};

export default GlobalCharts;
