import {
  apiFieldsMappingForestStatistics,
  apiFieldsMappingForestChange
} from "components/pages/GlobalForestAttributesPage/Sidebar/utils/apiFieldsMapping";

type ForestStatisticsApiKey = keyof typeof apiFieldsMappingForestStatistics;
type ForestChangeApiKey = keyof typeof apiFieldsMappingForestChange;

export const copernicusDataKeys: ForestStatisticsApiKey[] = ["copernicus_non_forest", "copernicus_forest"];

export const patternDataKeys: ForestStatisticsApiKey[] = [
  "pattern_branch",
  "pattern_bridge",
  "pattern_core",
  "pattern_edge",
  "pattern_islet",
  "pattern_loop",
  "pattern_perforation"
];

export const fragmentationDataKeys: ForestStatisticsApiKey[] = [
  "fragmentation_rare_31",
  "fragmentation_patchy_31",
  "fragmentation_transitional_31",
  "fragmentation_dominant_31",
  "fragmentation_interior_31"
];

export const accountingDataKeys: ForestStatisticsApiKey[] = [
  "accounting_acc1",
  "accounting_acc2",
  "accounting_acc3",
  "accounting_acc4",
  "accounting_acc5",
  "accounting_acc6"
];

export const changeForestDataKeys: ForestChangeApiKey[] = [
  // "period",
  "forest_gain",
  "forest_loss"
  // "net_change"
  //  "fc_increase_index"
];

export const changeFragmentationDataKeys: ForestChangeApiKey[] = [
  "decrease_high",
  "decrease_medium",
  "decrease_low",
  "insignificant_no_change",
  "increase_high",
  "increase_medium",
  "increase_low"
];
