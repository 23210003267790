import { useContext } from "react";
import { MapPageContext } from "../../../reusables/MapPage";
import { getStatements, StatementI } from "../../../../utils/apiRequests";
import { useQuery } from "react-query";

const documents = () => {
  const { data: statements } = useQuery([], getStatements, { staleTime: Infinity });
  const objStatement: StatementI =
    statements?.find(function (el) {
      return el.source === "PRDFAO";
    }) || {};

  return [
    {
      title: "Legal Notice",
      description: (
        <>
          {objStatement.text_before}
          <a target="_blank" href={objStatement.link1_target}>
            {objStatement.link1_text}
          </a>
          {objStatement.text_between}
          <a target="_blank" href={objStatement.link2_target}>
            {objStatement.link2_text}
          </a>
          {objStatement.text_after}
        </>
      ),
      links: []
    },

    {
      title: "Layers documents",
      description: "Check the following document for more info about the methodology and classes used.",
      links: [
        {
          title: "Production",
          actionUrl: "/commodities/production.docx",
          actionText: "DOWNLOAD DOC"
        }
      ]
    }
  ];
};

export default documents;
