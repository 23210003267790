import { Box, Typography } from "@mui/material";
import SummaryPage, { SummaryItem } from "components/reusables/SummaryPage";
import colorByPageName from "constants/colorByPageName";
import StyledLink from "../reusables/StyledLink";
import { removeOpacity } from "components/reusables/removeColorOpacity";
import React from "react";
const title = "Global land use carbon fluxes";
// const color = removeOpacity.color(colorByPageName[title]);
const color = colorByPageName[title];

const CarbonDescription = () => {
  return (
    <Typography>
      Here we present CO&#8322; fluxes from Land Use, Land-Use Change and Forestry (LULUCF), sourced from National
      Greenhouse Gas Inventories (NGHGIs) communicated via a range of country reports to the United Nations Framework
      Convention on Climate Change (UNFCCC), using the methodology by the Intergovernmental Panel on Climate Change
      (IPCC). CO&#8322; fluxes are allocated to the classes Forest (excl. organic soils), Deforestation (conversion of
      forest to other land uses), Other non-forest land uses, Organic soils, and Harvested wood products, with gaps
      filled without altering the levels and trends of the reported data ({""}
      <StyledLink
        href="https://essd.copernicus.org/articles/14/4643/2022/essd-14-4643-2022.html"
        target="_blank"
        color={color}
        aria-label="Link to article (external link will be opened in a new window)"
      >
        Grassi et al. 2022
      </StyledLink>
      ). We further compare NGHGI estimates with independent global land use emission datasets, adjusted to NGHGI
      definitions to minimize conceptual differences: (i){" "}
      <StyledLink
        href="https://globalcarbonbudget.org/"
        target="_blank"
        color={color}
        aria-label="Link to Global Carbon Budget (external link will be opened in a new window)"
      >
        Global Carbon Budget (GCB)
      </StyledLink>{" "}
      data from Friedlingstein et al. (2024) for Forest, Deforestation, Other and Organic soils. Forest data (including
      shifting agriculture) are shown in the ‘original’ version and also ‘translated’ to the NGHGI definition of
      human-induced CO&#8322; sink based on the methodology from{" "}
      <StyledLink
        href="https://essd.copernicus.org/articles/15/1093/2023/"
        target="_blank"
        color={color}
        aria-label="Link to article (external link will be opened in a new window)"
      >
        Grassi et al. (2023)
      </StyledLink>
      ; (ii){" "}
      <StyledLink
        href="https://www.globalforestwatch.org/"
        target="_blank"
        color={color}
        aria-label="Link to Global Forest Watch (external link will be opened in a new window)"
      >
        Global Forest Watch (GFW)
      </StyledLink>{" "}
      provisional data from Gibbs et al. ({""}
      <StyledLink
        href="https://essd.copernicus.org/preprints/essd-2024-397/"
        target="_blank"
        color={color}
        aria-label="Link to paper in review (external link will be opened in a new window)"
      >
        in review
      </StyledLink>
      , update of{" "}
      <StyledLink
        href="https://www.nature.com/articles/s41558-020-00976-6"
        target="_blank"
        color={color}
        aria-label="Link to article (external link will be opened in a new window)"
      >
        Harris et al. 2021
      </StyledLink>
      ) for Forest and Deforestation. CO&#8322; fluxes linked to shifting agriculture are allocated either to the Forest
      or Deforestation classes for comparability with NGHGIs.
    </Typography>
  );
};

const MainContent = () => {
  return (
    <Box>
      <SummaryItem
        title="Carbon fluxes from land use 2000-2022"
        color={colorByPageName["Carbon fluxes from land use 2000-2022"]}
        mainColor={color}
        linkTo="fluxes"
        content={<CarbonDescription />}
        ariaLabel="Production map"
        minWidth={350}
      />
    </Box>
  );
};

const CommoditiesSummaryPage = () => {
  return (
    <SummaryPage backgroundImgPath="background_carbon" title={title} color={color} mainContent={<MainContent />} />
  );
};

export default CommoditiesSummaryPage;
