import { Box } from "@mui/material";
import { useContext } from "react";

import layersDescriptions, {
  gfaCategoryToTitle
} from "components/pages/GlobalForestAttributesPage/Sidebar/layersDescriptions";
import { MapPageContext } from "components/reusables/MapPage";
import WithLegend from "components/reusables/WithLegend";

import {
  accountingDataKeys,
  copernicusDataKeys,
  fragmentationDataKeys,
  patternDataKeys
} from "components/pages/GlobalForestAttributesPage/Sidebar/utils/keysGroups";
import { apiFieldsMappingForestStatistics } from "components/pages/GlobalForestAttributesPage/Sidebar/utils/apiFieldsMapping";
import chartsLegends from "components/pages/GlobalForestAttributesPage/Sidebar/chartsLegends";
import { makeData, makeTitle } from "utils/chartsUtils";
import { greyTextColor } from "constants/colors";
import WithInfoTooltipIcon from "components/reusables/WithInfoTooltipIcon";
import PieChart from "components/charts/PieChart";
import ColumnChart from "../../../charts/ColumnChart";

const dataKeysByCategory = {
  forest: copernicusDataKeys,
  fragmentation: fragmentationDataKeys,
  pattern: patternDataKeys,
  accounting: accountingDataKeys
};

const NoLayerSelectedMessage = () => {
  return <Box sx={{ padding: 1 }}>SELECT A LAYER TO SEE THE DATA</Box>;
};

const LayerCharts = () => {
  const { selectedLayers, selectedCountryData, setActiveTabIndex } = useContext(MapPageContext);

  if (!selectedCountryData) return null;

  const selectedCategory = selectedLayers[0]?.gfaCategory;

  if (selectedLayers.length === 0 || !selectedCategory) return <NoLayerSelectedMessage />;

  const data = makeData(
    selectedCountryData,
    dataKeysByCategory[selectedCategory],
    apiFieldsMappingForestStatistics,
    "ha"
  );
  const categoriesForest = ["Non-forest", "Forest"];
  const legend = chartsLegends[selectedCategory];

  const chartType =
    selectedCategory == "forest" ? (
      <ColumnChart data={data} categories={categoriesForest} yAxisTitle={"ha"} title={"&nbsp;"} />
    ) : (
      <PieChart data={data} />
    );
  return (
    <>
      <WithInfoTooltipIcon
        color={greyTextColor}
        tooltipProps={{ title: "More info" }}
        onIconClick={() => setActiveTabIndex(1)}
      >
        {makeTitle(gfaCategoryToTitle[selectedCategory])}
      </WithInfoTooltipIcon>
      <Box color={greyTextColor}>{layersDescriptions[selectedCategory]}</Box>
      <WithLegend legend={legend}>{chartType}</WithLegend>
    </>
  );
};

export default LayerCharts;
