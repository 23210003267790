import WebtoolsComponent, { defaultHighchartsData } from "components/charts/WebtoolsComponent";
import { Options, SeriesTreemapOptions } from "highcharts";
import { set } from "lodash";
import cloneDeep from "lodash/cloneDeep";

const defaultData: Options = {
  ...defaultHighchartsData,
  chart: {
    type: "treemap"
  },
  series: [
    {
      type: "treemap",
      borderRadius: 5,
      borderWidth: 1,
      borderColor: "white",
      layoutAlgorithm: "squarified",
      alternateStartingDirection: true,
      levels: [
        {
          level: 1,
          layoutAlgorithm: "sliceAndDice",
          dataLabels: {
            enabled: true,
            allowOverlap: false,
            padding: 0,
            useHTML: true,
            formatter: function () {
              const point: any = this.point;

              return `${point.image ? point.image : ""}<br/>${point.name}`;
            }
          }
        }
      ],
      data: []
    }
  ] as SeriesTreemapOptions[],
  title: {
    align: "left"
  },
  subtitle: {},

  tooltip: {
    outside: true,

    pointFormat: "<b>{point.name}</b> value is <b>{point.valueFormated} {point.unit} {point.percentage}"
  }
};

type Props = {
  title: string;
  seriesData: SeriesTreemapOptions["data"];
  pointFormat?: string;
};

const TreemapChart = (props: Props) => {
  const { title, seriesData, pointFormat } = props;
  let highchartsData = cloneDeep(defaultData);

  highchartsData = set(highchartsData, "title.text", title);
  highchartsData = set(highchartsData, "series[0].data", seriesData);
  if (pointFormat) highchartsData = set(highchartsData, "tooltip.pointFormat", pointFormat);

  return <WebtoolsComponent id={`treemap_${title}`} data={highchartsData} plugins={["treemap"]} />;
};

export default TreemapChart;
