import { Autocomplete, Box, FormControlLabel, FormGroup, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { getUnits } from "utils/apiRequests";
import CustomCheckbox from "../../reusables/CustomCheckbox";
import { Info } from "@mui/icons-material";

type Props = {
  value?: string;
  onChange: (e: any) => void;
  onlyProductFamilies?: boolean;
  dataSource?: string;
};

const UnitSelectDropdown = (props: Props) => {
  const { value, onChange, dataSource } = props;

  const data = [
    { label: "Quantity", valueD: "quantity" },
    { label: "Monetary value", valueD: "value" }
  ];
  /*  const { data, isLoading } = useQuery(["getUnits", dataSource], getUnits);

  useEffect(() => {
    // Set initial selection on data load
    if (data && data[0]) onChange(data[0]);
  }, [data]);

  if (isLoading) return <>Loading...</>;
  if (!data) return null;

  // Don't show selection if there is just one option
  if (data.length === 1) return null;
*/
  return (
    <Box display="flex">
      <FormGroup sx={{ pl: 2 }} row>
        {data.map(({ label, valueD }, index) => {
          const isChecked = valueD === value;
          return (
            <FormControlLabel
              key={index}
              control={
                <CustomCheckbox
                  name={valueD}
                  checked={isChecked}
                  onChange={(event) => onChange(event.target.name)}
                  size="small"
                />
              }
              label={isChecked ? <Typography style={{ fontWeight: "500" }}>{label}</Typography> : label}
              sx={{ padding: 0, margin: 0 }}
            />
          );
        })}
      </FormGroup>
    </Box>
  );
};
export default UnitSelectDropdown;
