import { useContext, useEffect, useState } from "react";
import { CRS, LeafletEventHandlerFnMap } from "leaflet";
import { WMSTileLayer } from "react-leaflet";
import { LayerConfigI } from "../../../types";
import { GlobalContext } from "../../../App";

import { MapPageContext } from "../MapPage";
import { Box, CircularProgress } from "@mui/material";
import { random } from "lodash";

interface WmsLayersPropsI {
  layers: LayerConfigI[];
}

const TileLoadingProgress = () => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 999
      }}
    >
      <Box sx={{ bgcolor: "white", opacity: 0.3, zIndex: -1, position: "absolute" }} className="w-full h-full" />
      <CircularProgress />
    </Box>
  );
};

const WmsLayers = ({ layers }: WmsLayersPropsI) => {
  const { pushNotification } = useContext(GlobalContext);
  const { selectedLayers, map } = useContext(MapPageContext);

  const initialLoadingState = Object.fromEntries(layers.map((i) => [i.id, false]));
  const [loadingLayers, setLoadingLayers] = useState(initialLoadingState);

  // prevent having loading state out of sync with leaflet
  // requires to have layerId param passed to WmsParams
  const handleLayerRemove = (e: any) => {
    const layerId = e.layer.options.layerId;
    if (!layerId) return;
    setLoadingLayers((prevState) => ({ ...prevState, [layerId]: false }));
  };

  useEffect(() => {
    if (!map) return;
    map.on("layerremove", handleLayerRemove);
    return () => {
      map.off("layerremove", handleLayerRemove);
    };
  }, [selectedLayers, map]);

  const renderWmsTileLayer = (config: LayerConfigI) => {
    const { url, params, id, bounds, crs, functionToUse } = config;

    if (functionToUse && functionToUse !== undefined) {
      functionToUse(params);
    }

    /* if (functionToUse) {
      useEffect(() => {
        if (!map) return;
        map.on("layerremove", handleLayerRemove);
        return () => {
          map.off("layerremove", handleLayerRemove);
        };
      }, [functionToUse(), map]);
    }
*/
    const urlString = url.toString();

    const handleIsLoading = () => {
      setLoadingLayers((prevState) => ({ ...prevState, [id]: true }));
    };
    const handleIsLoadingComplete = () => {
      setLoadingLayers((prevState) => ({ ...prevState, [id]: false }));
    };

    const handlers: LeafletEventHandlerFnMap = {
      loading: handleIsLoading,
      load: handleIsLoadingComplete,
      tileerror: () => {
        pushNotification({ severity: "error", type: "tileerror", message: "Error loading tiles", timeout: 2000 });
      }
    };

    params.layerId = id + params?.viewParams?.replaceAll(":", "").replaceAll(";", "").replaceAll("-", "");

    return (
      <WMSTileLayer
        key={params.layerId}
        url={urlString}
        params={params}
        crs={crs || CRS.EPSG4326}
        eventHandlers={handlers}
        noWrap={false}
        bounds={bounds}
      />
    );
  };

  // const errorOrNoData = error || !data || data?.status !== 200;
  const isLoading = Object.values(loadingLayers).find((i) => i === true);

  return (
    <>
      {layers.map((i) => renderWmsTileLayer(i))}
      {isLoading && <TileLoadingProgress />}
    </>
  );
};

export default WmsLayers;
