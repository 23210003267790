import { ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

interface TrackPageViewProps {
  children: ReactNode; // ReactNode covers anything that is renderable
}

const TrackPageView = ({ children }: TrackPageViewProps) => {
  const location = useLocation();
  const isFirstLoad = useRef(true); // Tracks if it's the first load

  useEffect(() => {
    // Define the tracking function with a delay
    const trackPage = () => {
      //console.log("Page viewed and Tracked:", location.pathname);
      setTimeout(() => {
        if (window.$wt && window.$wt.analytics && window.$wt.analytics.isTrackable()) {
          window.$wt.trackPageView();
        }
      }, 100); // 100ms delay before tracking
    };

    if (isFirstLoad.current) {
      // MutationObserver to ensure tracking code is ready
      const observer = new MutationObserver((mutations, obs) => {
        if (document.querySelector("div.analytics.wt.wt-analytics")) {
          trackPage(); // Track when the div is present
          obs.disconnect(); // Stop observing after tracking
          isFirstLoad.current = false; // Set to false after first tracking
        }
      });

      // Start observing for changes in the document
      observer.observe(document, { childList: true, subtree: true });

      // Cleanup observer on component unmount
      return () => observer.disconnect();
    } else {
      // Directly call trackPage for subsequent loads
      trackPage();
    }
  }, [location]); // This effect runs initially and every time the location changes

  // return null;  // This component does not render anything
  return <>{children}</>; // Render children so that RouterProvider and other components can be wrapped
};

export default TrackPageView;
