import { lighten } from "@mui/material";
import WebtoolsComponent, { defaultHighchartsData } from "components/charts/WebtoolsComponent";
import { Options, SeriesGaugeOptions, SeriesSolidgaugeOptions } from "highcharts";
import { set } from "lodash";
import generateColors, { convertToHex } from "utils/generateColorsBetween";
import cloneDeep from "lodash/cloneDeep";
import { ColorScaleItemI } from "constants/colorScales";

const MAX_VALUE = 100;
const SLICES_COUNT = 20;
const defaultData: Options = {
  ...defaultHighchartsData,
  chart: {
    type: "gauge",
    height: 300,
    animation: false
  },
  tooltip: {
    enabled: false
  },
  pane: {
    startAngle: 180,
    endAngle: 540,
    background: [
      {
        outerRadius: "100%",
        innerRadius: "80%",
        backgroundColor: "#eee",
        borderWidth: 0
      }
    ]
  },
  yAxis: {
    min: 0,
    max: MAX_VALUE,
    lineWidth: 0,
    zIndex: 20,
    tickColor: "#40404061",
    tickPosition: "outside",
    title: {}
  },

  plotOptions: {
    series: {
      enableMouseTracking: false,
      animation: false
    },
    solidgauge: {
      borderWidth: 34,
      linecap: "square",
      dataLabels: {
        enabled: false
      }
    }
  }
};

interface GaugeChartWebtoolsPropsI {
  title: string;
  percentage: number;
  color: string | ColorScaleItemI[];
}

type SeriesListType = Array<SeriesSolidgaugeOptions | SeriesGaugeOptions>;

const generateSeriesGradient = (colors: string[], slicesCount: number, maxValue: number): SeriesListType => {
  const list: SeriesListType = [];

  for (let i = maxValue; i >= 0; i = i - 1) {
    // find color index proportionally based on current index i
    const colorIndex = (slicesCount * i) / maxValue;
    list.push({
      borderColor: colors[colorIndex],
      type: "solidgauge",
      data: [
        {
          y: i,
          radius: "100%",
          innerRadius: "100%"
        }
      ],
      stickyTracking: false,
      enableMouseTracking: false
    });
  }

  return list;
};

const generateColorsFromOne = (color: string): SeriesListType => {
  const lightenedColor = lighten(color, 0.8);
  let rgbString = lightenedColor.replace("rgb(", "");
  rgbString = rgbString.replace(")", "");
  const rgbArray = rgbString.split(",").map((i) => parseInt(i));

  const lightenedHex = convertToHex(rgbArray);

  const colors = generateColors(lightenedHex, color, SLICES_COUNT + 1);
  const seriesGradient = generateSeriesGradient(colors, SLICES_COUNT, MAX_VALUE);

  const seriesList: SeriesListType = seriesGradient;
  return seriesList;
};

const generateColorsFromList = (colorScale: ColorScaleItemI[]): SeriesListType => {
  const colors = colorScale
    .map((item) => {
      const res = [];
      if (item.color && item.color != "") {
        for (let i = item.from; i <= item.to; i++) {
          res.push({
            borderColor: item.color,
            type: "solidgauge",
            data: [
              {
                y: i,
                radius: "100%",
                innerRadius: "100%"
              }
            ],
            stickyTracking: false,
            enableMouseTracking: false
          });
        }
      }
      return res;
    })
    .flat()
    .reverse() as SeriesListType;

  return colors;
};

const GaugeChart = (props: GaugeChartWebtoolsPropsI) => {
  const { title, percentage, color } = props;

  const seriesList: SeriesListType =
    typeof color === "string" ? generateColorsFromOne(color) : generateColorsFromList(color);
  const arrowSeries: SeriesGaugeOptions = {
    name: title,
    type: "gauge",
    tooltip: { enabled: false } as any,
    dataLabels: {
      y: -25,
      style: { fontSize: "25px" },
      zIndex: 10,
      backgroundColor: "#ffffffcc",
      formatter: () => `${percentage.toFixed(1)} %`
    },
    data: [{ y: percentage }]
  };

  seriesList.push(arrowSeries);

  const chartTitle = {
    text: `<b>${title}</b>`,
    y: 100
  };

  let merged = cloneDeep(defaultData);
  merged = set(merged, "series", seriesList);
  merged = set(merged, "yAxis.title", chartTitle);

  return <WebtoolsComponent id={`gauge_${title}`} data={merged} plugins={["gauge", "solid-gauge"]} />;
};

export default GaugeChart;
