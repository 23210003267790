import { LayerConfigI, LayerConfigTreeType, LayerDefinitionI, LayerGroupYearsConfigI } from "../types";
import { OgcServerClient } from "utils/ogcClients";

export interface MakeConfigParamsI {
  definition: LayerDefinitionI;
  isChild?: boolean;
  year?: number;
}

export type MakeConfigType = (params: MakeConfigParamsI) => LayerConfigI;

export const getLayersConfig = (
  layersDefinitions: LayerDefinitionI[] | undefined,
  makeConfig: MakeConfigType
): LayerConfigTreeType => {
  if (layersDefinitions === undefined) return {} as LayerConfigTreeType;
  const configs = layersDefinitions?.map((definition) => {
    const { type, name, title, defaultSelected, description } = definition;

    const commonConfig = { name, title, description, defaultSelected };
    // if type is group, children can be defined individually or programmatically by year
    if (type === "group") {
      const configChildren = definition.children.map((child) => makeConfig({ definition: child, isChild: true }));
      return { ...commonConfig, type, children: configChildren };
    }

    if (type === "years-group" && definition.years) {
      const { years, yearSelectionType } = definition;
      const configChildren = years.map((year) => makeConfig({ definition, year, isChild: true }));
      const config: LayerGroupYearsConfigI = {
        ...commonConfig,
        type,
        yearSelectionType,
        years,
        children: configChildren
      };
      return config;
    } else {
      return makeConfig({ definition });
    }
  });

  return configs;
};

type CommonConfigT = Pick<
  LayerConfigI,
  "id" | "url" | "defaultSelected" | "title" | "description" | "type" | "year" | "parentName"
>;

export const makeCommonConfig = (params: MakeConfigParamsI, ogcServerClient: OgcServerClient): CommonConfigT => {
  const { definition, isChild, year } = params;
  const { name, title, description, defaultSelected } = definition;

  const url = new URL(ogcServerClient.getWMSPath());

  let id = name;
  if (year) id = id.concat(`-${year}`);

  const isDefaultSelected = !!defaultSelected;

  return {
    id,
    url,
    defaultSelected: isDefaultSelected,
    title,
    description,
    type: "layer",
    year,
    parentName: isChild ? name : undefined
  };
};

export const handleLayersName = (definition: LayerDefinitionI, year?: number): string => {
  const { type } = definition;
  let layers = "";

  if (type === "layer") layers = definition.layerName;
  if (type === "years-group") layers = `${definition.layerNamePrefix}${year}`;

  return layers;
};
