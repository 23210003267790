import { useContext } from "react";

import { MapPageContext } from "components/reusables/MapPage";
import { makeData } from "utils/chartsUtils";
import { getProductionAnnualVariationStatistics } from "../../../../utils/apiRequests";
import { useQuery } from "react-query";
import { annualVariationDataKeys, areaAnnualVariationDataKeys, yieldAnnualVariationDataKeys } from "./utils/keysGroups";
import {
  apiFieldsProductionAnnualVariationStatistics,
  apiFieldsProductionAreaAnnualVariationStatistics,
  apiFieldsProductionYieldAnnualVariationStatistics
} from "./utils/apiFieldsMapping";
import { ProductTreeSelectContext } from "components/pages/CommoditiesProductionPage/ProductTreeSelect";
import SplineChart from "components/charts/SplineChart";

const valuesToUse = {
  prd: [
    annualVariationDataKeys,
    apiFieldsProductionAnnualVariationStatistics,
    "Production quantity ",
    "Annual production quantity"
  ],
  prd_area: [
    areaAnnualVariationDataKeys,
    apiFieldsProductionAreaAnnualVariationStatistics,
    "Area harvested",
    "Annual area harvested"
  ],
  yld: [yieldAnnualVariationDataKeys, apiFieldsProductionYieldAnnualVariationStatistics, "Yield", "Annual Yield"]
};

const makeHighchartsSeries = (data: any[], dataItems: any[], allYears: string[]): any[] => {
  const series = Object.entries(dataItems).map(([name, itemDataValue]) => {
    return {
      name: itemDataValue.name,
      color: "#3FA0FF",
      unit: itemDataValue.unit,
      data: allYears.map((i) => {
        const foundByYear = data.find((dataPoint) => dataPoint.period === i);
        return foundByYear[itemDataValue.property];
      }),
      showInLegend: false
    };
  });

  return series;
};

const AnnualVariationCharts = () => {
  const { selectedCountryCode: countryCode, selectedLayers } = useContext(MapPageContext);
  const { product } = useContext(ProductTreeSelectContext);

  if (!selectedLayers || selectedLayers.length == 0 || !countryCode || countryCode === "" || !product) return null;

  const category = selectedLayers[0].comPrdCategory !== undefined ? selectedLayers[0].comPrdCategory : "prd";
  const queryOptions = {
    queryKey: ["productionAnnualVariation", category, countryCode, product?.product_code],
    queryFn: getProductionAnnualVariationStatistics,
    staleTime: Infinity,
    enabled: true
  };

  const { data, isLoading, error } = useQuery(queryOptions);

  if (!data || data.length === 0) return null;

  const allYears: string[] = [...new Set(data.map((item) => item.period))];

  const val: any = valuesToUse[category];

  const unit = data?.at(0)?.unit || "";
  const dataAll = makeData(data, val[0], val[1], unit);
  const highAll = makeHighchartsSeries(data, dataAll, allYears);

  const titleY = val[2] + " (" + unit + ")";

  const chartType = <SplineChart data={highAll} title={val[3]} categories={allYears} yAxisTitle={titleY} />;

  return chartType;
};

export default AnnualVariationCharts;
