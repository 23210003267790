import { useQuery } from "react-query";
import { getStatements, StatementI } from "../../../../utils/apiRequests";

const documents = () => {
  const { data: statements } = useQuery([], getStatements, { staleTime: Infinity });
  const objStatementFao: StatementI =
    statements?.find(function (el) {
      return el.source === "TRDFAO";
    }) || {};
  const objStatementCom: StatementI =
    statements?.find(function (el) {
      return el.source === "TRDCOM";
    }) || {};

  return [
    {
      title: "Legal Notice",
      description: (
        <>
          {objStatementFao.text_before}
          <a target="_blank" href={objStatementFao.link1_target}>
            {objStatementFao.link1_text}
          </a>
          {objStatementFao.text_between}
          <a target="_blank" href={objStatementFao.link2_target}>
            {objStatementFao.link2_text}
          </a>
          {objStatementFao.text_after}
          <br />
          <br />
          {objStatementCom.text_before}
          <a target="_blank" href={objStatementCom.link1_target}>
            {objStatementCom.link1_text}
          </a>
          {objStatementCom.text_between}
          <a target="_blank" href={objStatementCom.link2_target}>
            {objStatementCom.link2_text}
          </a>
          {objStatementCom.text_after}
        </>
      ),

      links: []
    },

    {
      title: "Layers documents",
      description: "Check the following document for more info about the methodology and classes used.",
      links: [
        {
          title: "Trade",
          actionUrl: "/commodities/trade.docx",
          actionText: "DOWNLOAD DOC"
        }
      ]
    }
  ];
};

export default documents;
