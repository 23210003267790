import {
  apiFieldsMappingForestStatistics,
  apiFieldsMappingForestChange
} from "components/pages/GlobalForestAttributesPage/Sidebar/utils/apiFieldsMapping";
import {
  accountingDataKeys,
  fragmentationDataKeys,
  patternDataKeys,
  changeForestDataKeys,
  changeFragmentationDataKeys,
  copernicusDataKeys
} from "components/pages/GlobalForestAttributesPage/Sidebar/utils/keysGroups";
import { makeLegend } from "utils/chartsUtils";

const chartsLegends = {
  forest: makeLegend(copernicusDataKeys, apiFieldsMappingForestStatistics, { withDescription: true }),
  fragmentation: makeLegend(fragmentationDataKeys, apiFieldsMappingForestStatistics, { withDescription: true }),
  pattern: makeLegend(patternDataKeys, apiFieldsMappingForestStatistics),
  accounting: makeLegend(accountingDataKeys, apiFieldsMappingForestStatistics),
  changeCover: makeLegend(changeForestDataKeys, apiFieldsMappingForestChange),
  changeFragmentation: makeLegend(changeFragmentationDataKeys, apiFieldsMappingForestChange)
};

export default chartsLegends;
