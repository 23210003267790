import { useContext } from "react";
import { useQuery } from "react-query";
import { getTrendsProductionsCharts } from "utils/apiRequests";
import { ProductTreeSelectContext } from "components/pages/CommoditiesProductionPage/ProductTreeSelect";
import { MapPageContext } from "components/reusables/MapPage";
import BarChart from "components/charts/BarChart";

import { Point } from "highcharts";
import { formatedNumber } from "../../../../utils/chartsUtils";
import { getFlagFilePathByCountryCode } from "../../../reusables/CountryFlag";

export function dataLabelTrends(this: any): any {
  const { y, series } = this;
  if (
    series === undefined ||
    series.name === undefined ||
    series.data === undefined ||
    series.data.length < this.point.index
  )
    return "";

  const mk_significance_flag = "Trend" === series.name ? series.data[this.point.index].mk_significance_flag : 1;

  return (
    "<span style='font-size:10px; font-weight: normal'>" +
    (mk_significance_flag === 1 ? formatedNumber(y) : "not significant") +
    "</span>"
  );
}

export function pointFormatterTrend(this: any): any {
  const { mk_significance_flag } = this;
  let { unit, period_regression: regression, relative_change: relative, country_name } = this;
  if (!unit || unit === undefined) unit = "";
  if (!regression || regression === undefined) regression = "";
  if (!relative || relative === undefined) relative = "";
  const context = this as Point;
  if (!context) return " NO CONTEXT";
  const { series, y } = context;
  if (!y) return;
  const name = series.name.startsWith("Series ")
    ? ""
    : series.name.startsWith("Absolute change ")
    ? "<b>Absolute change: </b>"
    : "<b>" + series.name + "</b>: ";
  const options = series?.userOptions as any;
  if (unit === "" && options && options.unit) unit = options.unit;

  if (!country_name || country_name === undefined) country_name = "";
  else country_name = country_name + "<br/>";

  if (mk_significance_flag == 0) return "<b>Trend: </b>not significant";
  let extra = "";
  if (regression !== "") extra = "<b>Period regression</b>: " + regression;
  if (relative !== "") extra = "<b>Relative change</b>: " + formatedNumber(relative, 2) + " %";

  return country_name + name + formatedNumber(y) + " " + unit + (extra ? "<br/>" + extra : "");
}

export function dataLabelYaxis(this: any): any {
  const { value: countryCode, chart } = this;
  const { series: seriesArray } = chart;
  const series = seriesArray[0];
  let countryName = countryCode;

  if (
    series !== undefined &&
    series.userOptions !== undefined &&
    series.userOptions.data !== undefined &&
    series.userOptions.data.length >= this.pos
  ) {
    countryName = series.userOptions.data[this.pos].country_name;
  }
  const filePath = this.value && getFlagFilePathByCountryCode(countryCode);

  return (
    "<img src=" +
    filePath +
    " title='" +
    countryName +
    "' width='25px' height='12.5px'  style='object-fit: cover; border: 0.5px solid #aaa; z-index: -1  '/>"
  );
}

const TrendChart = () => {
  const { selectedCountryCode, selectedLayers, setActiveTabIndex } = useContext(MapPageContext);
  const { product } = useContext(ProductTreeSelectContext);
  const { product_code, product_name } = product || {};

  const categoryLayer =
    selectedLayers && selectedLayers[0] && selectedLayers[0].comPrdCategory ? selectedLayers[0].comPrdCategory : "prd";

  const queryOptions = {
    queryKey: [categoryLayer, selectedCountryCode, product_code],
    queryFn: getTrendsProductionsCharts,
    staleTime: Infinity,
    enabled: !!product_code
  };

  const { data: objResult, isLoading, error } = useQuery(queryOptions);
  const data: any[] = objResult?.highchartseries;
  const categories: string[] = objResult?.categories?.map((a: any) => a.country_code);

  const unit = data && data.length > 0 && data[0].data.length > 0 ? data[0].data[0].unit : "";

  const title =
    "Indicators of change for " +
    (categoryLayer === "prd" ? "production quantity" : categoryLayer === "prd_area" ? "area harvested" : "yield");

  let plotBand = {};
  if (selectedCountryCode !== "" && categories?.length > 0) {
    const index = categories.findIndex((obj) => obj === selectedCountryCode);
    if (index !== -1) {
      plotBand = {
        borderWidth: 1,
        borderColor: "#39FF14",
        color: "#39FF14AA",
        from: index - 0.5,
        to: index + 0.5,
        zIndex: -20
      };
    }
  }

  return (
    <>
      {isLoading && <p>Loading ...</p>}
      {error && null}
      {(!categories || categories.length === 0) && (
        <>
          No indicators of change for {product_name} {selectedCountryCode !== "" ? " in " + selectedCountryCode : ""}
        </>
      )}
      {categories && categories?.length !== 0 && (
        <BarChart
          data={data}
          yAxisTitle={unit !== "" ? "(" + unit + ")" : ""}
          categories={categories}
          title={title}
          plotBand={plotBand}
          functionToCall={setActiveTabIndex}
          callParameter={1}
        />
      )}
    </>
  );
};

export default TrendChart;
