import { MapPageContextI } from "components/reusables/MapPage";
import { GeoServerClient } from "utils/ogcClients";
import { getCountryFeatureById } from "utils/ogcRequests";

export const getPolygonByCountryCode = async (context: MapPageContextI, client: GeoServerClient) => {
  const data = await getCountryFeatureById(context.selectedCountryCode, client);

  if (!data) return;
  if (data.features[0]) context.setSelectedCountryPolygon(data.features[0]);
  else context.setSelectedCountryPolygon(null);
};
