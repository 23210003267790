import { useContext } from "react";
import { useQuery } from "react-query";
import { getProductionAverage } from "utils/apiRequests";
import { ProductTreeSelectContext } from "components/pages/CommoditiesProductionPage/ProductTreeSelect";
import { MapPageContext } from "components/reusables/MapPage";
import { intervalColorCode } from "./index";
import { apiFieldsAverageStatistics } from "./utils/apiFieldsMapping";
import { Chart } from "utils/chartsUtils";
import { averageDataKeys } from "./utils/keysGroups";

const AverageChart = () => {
  const { selectedLayers, selectedCountryCode } = useContext(MapPageContext);
  const { product } = useContext(ProductTreeSelectContext);
  const { product_code, product_name } = product || {};

  if (!selectedLayers || selectedLayers.length === 0 || !selectedCountryCode || selectedCountryCode === "") return null;
  const category = selectedLayers[0].comPrdCategory ? selectedLayers[0].comPrdCategory : "prd";

  const queryOptions = {
    queryKey: ["productAverageProductionAllYears", category, selectedCountryCode, product_code],
    queryFn: getProductionAverage,
    staleTime: Infinity,
    enabled: !!product_code && !!selectedCountryCode
  };

  const { data, isLoading, error } = useQuery(queryOptions);

  if (isLoading) return <>loading...</>;
  if (!data || error) return null;
  if (data.length === 0)
    return (
      <>
        No production for {product_name} in {selectedCountryCode}
      </>
    );

  const colors: string[] = data.map((item) => "#3FA0FF");
  const unit: string = data[0].unit;
  return (
    <>
      <Chart
        dataP={data}
        categories={averageDataKeys}
        title={
          category === "prd"
            ? "Average production quantity"
            : category === "prd_area"
            ? "Average area harvested"
            : "Average Yield"
        }
        attrDimension={"period"}
        apiMapping={apiFieldsAverageStatistics}
        colors={colors}
        hideLegend={true}
        yAxisTitle={
          (category === "prd" ? "Production quantity " : category === "prd_area" ? "Area harvested" : "Yield") +
          " (" +
          unit +
          ")"
        }
      />
    </>
  );
};

export default AverageChart;
