import { Checkbox, CheckboxProps, styled } from "@mui/material";

const CheckboxIcon = styled("span")(({ theme }) => ({
  borderRadius: 0,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1.5px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1.5px #6d6d6d, inset 0 -1.5px 0 #6d6d6d",
  backgroundColor: theme.palette.mode === "dark" ? "#0759007f" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "4px auto #",
    outlineOffset: 2
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#0759007f" : "#eeeeeebc"
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: theme.palette.mode === "dark" ? "#0759007f" : "rgba(206,217,224,.5)"
  }
}));

const CheckedIcon = styled(CheckboxIcon)({
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: `url("/cross.svg")`,
    content: '""'
  },
  "input:hover ~ &": {
    backgroundColor: "#e2e2e2"
  }
});

const CustomCheckbox = (props: CheckboxProps) => {
  return <Checkbox {...props} checkedIcon={<CheckedIcon />} icon={<CheckboxIcon />} />;
};

export default CustomCheckbox;
