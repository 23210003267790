import { Box } from "@mui/material";
import { Fragment, useContext } from "react";

import { apiFieldsMappingForestStatistics } from "./utils/apiFieldsMapping";
import { MapPageContext } from "components/reusables/MapPage";
import GaugeChart from "components/charts/GaugeChart";

const indicatorsDataKeys = ["pattern_porosity", "network_rac", "network_coh", "fragmentation_fadav_31"];

const GlobalCharts = () => {
  const { selectedCountryData } = useContext(MapPageContext);
  const data = selectedCountryData;
  if (!data) return null;
  return (
    <Box className="flex flex-wrap gap-30" sx={{ padding: 2 }}>
      {indicatorsDataKeys.map((key, index) => {
        const title = apiFieldsMappingForestStatistics[key].title;
        const value = data[key];

        if (!key || !value) return null;
        if (typeof value !== "number") return null;

        return (
          <Fragment key={index}>
            <div style={{ maxWidth: 300, height: 300, width: 300 }}>
              <GaugeChart percentage={value} title={title} color={apiFieldsMappingForestStatistics[key].color || ""} />
            </div>
          </Fragment>
        );
      })}
    </Box>
  );
};

export default GlobalCharts;
