import { useContext, useEffect, useState } from "react";
import { Autocomplete, styled, TextField } from "@mui/material";
import { useQuery } from "react-query";
import { MapPageContext } from "./MapPage";
import { CountryOptionI, getCountries } from "utils/apiRequests";
import { Style } from "@mui/icons-material";

interface PropsI {
  onSelect: (countryCode: string) => void;
  disableClearable?: boolean;
  label?: string;
}

const getOptionLabel = (option: CountryOptionI) => {
  if (!option) return "";
  return option.country_name;
};

const CountrySelect = (props: PropsI) => {
  const { onSelect, disableClearable } = props;
  const context = useContext(MapPageContext);
  const { selectedCountryCode, countriesApiPath } = context;
  const { data, isLoading, error } = useQuery(["countries", countriesApiPath], getCountries);

  const findOption = () => (data || []).find((i) => i.country_code === selectedCountryCode);

  const [foundCountryOption, setFoundCountryOption] = useState(findOption());

  const handleChange = (e: React.SyntheticEvent, newValue: CountryOptionI | null) => {
    onSelect(newValue?.country_code || "");
  };

  useEffect(() => {
    if (!selectedCountryCode) setFoundCountryOption(undefined);
    else {
      const found = findOption();
      if (found) setFoundCountryOption(found);
    }
  }, [data, selectedCountryCode]);

  if (error) {
    console.log(error);
    return <>There was an error</>;
  }

  return (
    <Autocomplete
      sx={{
        "& label + .MuiInput-formControl": {
          margin: "0px",
          padding: "0px"
        }
      }}
      loading={isLoading}
      value={foundCountryOption || null}
      options={data || []}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      selectOnFocus={false}
      disableClearable={disableClearable}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps }}
          variant="standard"
          size="small"
          margin="none"
          sx={{
            "& .MuiInput-input": {
              padding: "0px!important"
            }
          }}
          placeholder={props.label || "Country"}
        />
      )}
    />
  );
};

export default CountrySelect;
