import {
  apiFieldsProductionAnnualVariationStatistics,
  apiFieldsProductionAreaAnnualVariationStatistics,
  apiFieldsProductionYieldAnnualVariationStatistics,
  apiFieldsAverageStatistics
} from "./apiFieldsMapping";

type AnnualVariationApiKey = keyof typeof apiFieldsProductionAnnualVariationStatistics;
type AreaAnnualVariationApiKey = keyof typeof apiFieldsProductionAreaAnnualVariationStatistics;
type YieldAnnualVariationApiKey = keyof typeof apiFieldsProductionYieldAnnualVariationStatistics;
type AverageApiKey = keyof typeof apiFieldsAverageStatistics;

export const annualVariationDataKeys: AnnualVariationApiKey[] = ["value"];
export const areaAnnualVariationDataKeys: AreaAnnualVariationApiKey[] = ["value"];
export const yieldAnnualVariationDataKeys: YieldAnnualVariationApiKey[] = ["value"];
export const averageDataKeys: AverageApiKey[] = ["avg_value"];
