import { Box, Typography } from "@mui/material";
import StyledLink from "components/reusables/StyledLink";
import SummaryPage, { SummaryItem } from "components/reusables/SummaryPage";
import colorByPageName from "constants/colorByPageName";
import { removeOpacity } from "components/reusables/removeColorOpacity";
const title = "EU tools for forest monitoring";
// const color = removeOpacity.color(colorByPageName[title]);
const color = colorByPageName[title];
const minWidth = 660;

const InitialDescription =
  "The EU observatory on deforestation and forest degradation develops Earth-Observation-based tools to support forest monitoring.";

const Tool1Description = () => {
  return (
    <Typography>
      Forest disturbance regimes are changing globally. The constant stream of data that satellites acquire over forests
      provides the opportunity for near-real-time monitoring of these changes. <i>nrt</i> is a Python software package
      that provides a standardized interface for near-real-time monitoring of disturbances using satellite image
      time-series. The package is optimized for fast computation and suitable for operational deployment at scale. A
      typical operational use case of <i>nrt</i> is a system that immediately receives new satellite images and
      generates alerts when an anomaly is detected. <i>nrt</i> implements five monitoring frameworks from scientific
      literature on change detection and exposes them via a common API.
    </Typography>
  );
};

const Tool2Description = () => {
  return (
    <Typography>
      The JRC develops and maintains a series of software packages to help describe and analyze landscape patterns. They
      include{" "}
      <StyledLink
        href="https://forest.jrc.ec.europa.eu/en/activities/lpa/gtb/"
        target="_blank"
        color={color}
        aria-label="Link to GuidosToolBox Graphical User Interface for the Description of image Objects and their Shapes - GTB (external link will be opened in a new window)"
      >
        GuidosToolbox
      </StyledLink>
      , a desktop application with spatial pattern analysis software. A “workbench” provides GuidosToolbox’s most
      popular tools as command-line scripts to support automated mass-processing on Linux servers. They also include{" "}
      <StyledLink
        href="https://forest.jrc.ec.europa.eu/en/activities/lpa/mspa/"
        target="_blank"
        color={color}
        aria-label="Link to MSPA Morphological Spatial Pattern Analysis (external link will be opened in a new window)"
      >
        MSPA
      </StyledLink>
      : a spatial analysis software to detect and describe the morphometric features of digital image objects.
    </Typography>
  );
};

const Tool3Description = () => {
  return (
    <Typography>
      The distribution of tree species and the composition of forests are highly dependent on climate conditions. Over
      the course of the 21st century, climate change will alter which trees can grow where in Europe, creating new
      habitats for species to grow in some places, while making existing habitats elsewhere unsuitable. The{" "}
      <StyledLink
        href="https://forest.jrc.ec.europa.eu/en/activities/forests-and-climate-change"
        target="_blank"
        color={color}
        aria-label="Link to Forests and Climate Change (external link will be opened in a new window)"
      >
        EU-Trees4F
      </StyledLink>
      &nbsp;data set models the current and future potential distribution of 67 forest tree species in Europe, and
      incorporates also a scenario in which trees disperse only naturally (
      <StyledLink
        href="https://doi.org/10.1038/s41597-022-01128-5"
        target="_blank"
        color={color}
        aria-label="Link to EU-Trees4F, a dataset on the future distribution of European tree species (external link will be opened in a new window)"
      >
        Mauri et al. 2022
      </StyledLink>
      ).
    </Typography>
  );
};

const Tool4Description = () => {
  return (
    <Typography>
      IMPACT Toolbox offers a combination of remote sensing, photo interpretation and processing technologies in a
      portable and stand-alone GIS environment, allowing non specialist users to easily accomplish all necessary
      pre-processing steps while giving a fast and user-friendly environment for visual editing and map validation. It
      includes several tools in support of forest monitoring such as image segmentation and classification as well as
      forest emission-reporting modules.
    </Typography>
  );
};

const Tool5Description = () => {
  return (
    <Typography>
      High-quality reference data to develop or evaluate satellite-based methods to monitor forest disturbances are
      scarce. Therefore, the JRC coordinates collaborative efforts of researchers to share their geospatial data on
      European forest disturbances caused by wind storms, diseases, or insect outbreaks. Along with the data, we provide
      dedicated software packages to facilitate their analysis.
    </Typography>
  );
};

const MainContent = () => {
  return (
    <Box>
      <SummaryItem
        title="Near-real-time analysis of time series of satellite data"
        content={<Tool1Description />}
        color={color}
        mainColor={color}
        linkTo="https://github.com/ec-jrc/nrt"
        minWidth={minWidth}
        external={true}
        ariaLabel="GitHub repository"
      />
      <SummaryItem
        title="Landscape pattern analysis"
        content={<Tool2Description />}
        color={color}
        mainColor={color}
        linkTo="https://forest.jrc.ec.europa.eu/en/activities/lpa/"
        minWidth={minWidth}
        external={true}
        ariaLabel="Landscape pattern analisys"
      />
      <SummaryItem
        title="Forest tree species distributions in Europe"
        content={<Tool3Description />}
        color={color}
        mainColor={color}
        linkTo="https://forest.jrc.ec.europa.eu/en/activities/forests-and-climate-change/"
        minWidth={minWidth}
        external={true}
        ariaLabel="forest tree species distribution in europe"
      />
      <SummaryItem
        title="Spatial reference data for forest disturbances in Europe"
        content={<Tool5Description />}
        color={color}
        mainColor={color}
        linkTo="https://forest.jrc.ec.europa.eu/en/activities/forest-disturbances/#div1"
        minWidth={minWidth}
        external={true}
        ariaLabel="Spatial reference data for forest disturbances in europe"
      />
      <SummaryItem
        title="IMPACT - Stand-alone toolbox for Image processing and environmental monitoring"
        content={<Tool4Description />}
        color={color}
        mainColor={color}
        linkTo="https://forobs.jrc.ec.europa.eu/products/software/impact.php"
        minWidth={minWidth}
        external={true}
        ariaLabel="Stand-alone toolbox for Image processing and environmental monitoring"
      />
    </Box>
  );
};

const ToolsForEUForestMonitoringPage = () => {
  return (
    <SummaryPage
      title={title}
      backgroundImgPath="background_tools"
      description={InitialDescription}
      color={color}
      mainContent={<MainContent />}
    />
  );
};

export default ToolsForEUForestMonitoringPage;
