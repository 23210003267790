import { ColorScaleItemI } from "constants/colorScales";
import { gaugeChartsColors } from "./chartsConfig";

export interface ApiMappingItem {
  title: string;
  description?: string;
  color?: string;
  positiveNegativeColors?: [string, string];
  colorScale?: ColorScaleItemI[];
}

export type ApiMapping = Record<string, ApiMappingItem>;

export const apiFieldsMappingForestStatistics: ApiMapping = {
  country_name: { title: "Country Name" },
  country_code: { title: "Country Code" },
  year: { title: "Year" },
  copernicus_land: { title: "Land" },
  copernicus_forest: {
    title: "Forest",
    color: "#319554",
    description: "Copernicus forest area inside the reporting unit."
  },
  copernicus_non_forest: {
    title: "Non-forest",
    color: "#fefed1",
    description: "Copernicus non-forest land area including inland waters but excluding ocean."
  },
  fragmentation_rare_31: { title: "Very high", color: "#d63127", description: "Coverage rare 0-10 %" },
  fragmentation_patchy_31: { title: "High", color: "#f98b59", description: "Coverage patchy 10-40 %" },
  fragmentation_transitional_31: {
    title: "Intermediate",
    color: "#fec700",
    description: "Coverage transitional 40-60 %"
  },
  fragmentation_dominant_31: { title: "Low", color: "#8bc763", description: "Coverage dominant 60-90 %" },
  fragmentation_interior_31: { title: "Very low", color: "#00ae00", description: "Coverage interior 90-100 %" },
  fragmentation_rare_99: { title: "Very high 99", color: "#d63127" },
  fragmentation_patchy_99: { title: "High 99", color: "#f98b59" },
  fragmentation_transitional_99: { title: "Intermediate 99", color: "#8bc763" },
  fragmentation_dominant_99: { title: "Low 99", color: "#fec700" },
  fragmentation_interior_99: { title: "Very low 99", color: "#00ae00" },
  fragmentation_fadav_31: { title: "Forest Connectivity", colorScale: gaugeChartsColors.fadAvg, color: "#006032" },
  fragmentation_fadav_99: { title: "Forest area density average 99", color: "" },
  accounting_acc1: { title: "1-100 ha", color: "#000000" },
  accounting_acc2: { title: "101-1,000 ha", color: "#fe0000" },
  accounting_acc3: { title: "1,001-10,000 ha", color: "#fefe00" },
  accounting_acc4: { title: "10,001-100,000 ha", color: "#fe8b00" },
  accounting_acc5: { title: "100,001-1,000,000 ha", color: "#9f3b00" },
  accounting_acc6: { title: "> 1,000,000 ha", color: "#00c700" },
  pattern_branch: { title: "Branch", color: "#ff8c00" },
  pattern_bridge: { title: "Bridge", color: "#ff0000" },
  pattern_core: { title: "Core", color: "#00c800" },
  pattern_edge: { title: "Edge", color: "#000000" },
  pattern_islet: { title: "Islet", color: "#a03c00" },
  pattern_loop: { title: "Loop", color: "#ffff00" },
  pattern_perforation: { title: "Perforation", color: "#0000ff" },
  pattern_porosity: { title: "Forest Porosity", colorScale: gaugeChartsColors.porosity, color: "#db9c38" },
  network_aps: { title: "Network average patch size" },
  network_coh: { title: "Forest network coherence", colorScale: gaugeChartsColors.coherence, color: "#006032" },
  network_large_obj: { title: "Networrk largest patch" },
  network_nr_obj: { title: "Number of patches" },
  network_rac: { title: "Forest Coverage", colorScale: gaugeChartsColors.referenceAreaCoverage, color: "#006032" },
  network_rest_pot: { title: "Restoration Potential", colorScale: gaugeChartsColors.restorationPotential }

  //"fc_increase_index"
};

export const apiFieldsMappingForestChange: ApiMapping = {
  forest_gain: { title: "Forest gain", color: "#79f847" },
  forest_loss: { title: "Forest loss", color: "#0d0d0d" },
  net_change: { title: "Net change of Forest ", positiveNegativeColors: ["blue", "red"] },
  decrease_high: { title: "High decrease", color: "#4f7f5d" },
  decrease_medium: { title: "Medium decrease", color: "#6fa7a3" },
  decrease_low: { title: "Low decrease", color: "#95cfac" },
  insignificant_no_change: { title: "Insignificant/No change", color: "#f0efcb" },
  increase_high: { title: "High increase", color: "#be551d" },
  increase_medium: { title: "Medium increase", color: "#f19b40" },
  increase_low: { title: "Low increase", color: "#f9d873" }
};
