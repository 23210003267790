import TimeseriesChart from "./TimeseriesChart";

import { useState } from "react";

import SelectDropdown from "./SelectDropdown";
import { Box, Grid } from "@mui/material";

const ExtraChart = () => {
  const [extraChart, setExtraChart] = useState("EU27");

  const selectType = <SelectDropdown value={extraChart} onChange={(newValue) => setExtraChart(newValue)} />;

  return (
    <Box style={{ marginLeft: "5px", marginRight: "5px" }}>
      <br />
      {
        <Grid container direction="row">
          <Grid item>{selectType}</Grid>
          <Grid item>
            &nbsp;<b>Annual CO2 fluxes</b>
          </Grid>
        </Grid>
      }
      <TimeseriesChart countryCode={extraChart} countryName={extraChart} />
    </Box>
  );
};
export default ExtraChart;
