import { Box } from "@mui/material";
import WithCountryData from "components/reusables/WithCountryData";
import { greyTextColor } from "constants/colors";
import { useContext } from "react";
import { makeTitle } from "utils/chartsUtils";

import { MapPageContext } from "../../../reusables/MapPage";
import GlobalCharts from "./GlobalCharts";
import LayerCharts from "./LayerCharts";

import WithInfoTooltipIcon from "components/reusables/WithInfoTooltipIcon";

const PADDING = 3;

const GlobalForestAttributesCharts = () => {
  const { selectedYear, setActiveTabIndex } = useContext(MapPageContext);

  return (
    <WithCountryData>
      <>
        <Box sx={{ padding: PADDING }}>
          <LayerCharts />
        </Box>
        {selectedYear && (
          <>
            <hr />
            <Box sx={{ padding: PADDING }}>
              <WithInfoTooltipIcon
                color={greyTextColor}
                tooltipProps={{ title: "More info" }}
                onIconClick={() => setActiveTabIndex(1)}
              >
                {makeTitle("Summary")}
              </WithInfoTooltipIcon>
              <br />
              <GlobalCharts />
            </Box>
          </>
        )}
      </>
    </WithCountryData>
  );
};

export default GlobalForestAttributesCharts;
