import { Typography } from "@mui/material";
import { MapPageContext } from "components/reusables/MapPage";
import { ReactElement, useContext } from "react";

const WithCountryData = ({ children }: { children: ReactElement }) => {
  const { selectedCountryCode, selectedCountryData } = useContext(MapPageContext);

  if (!selectedCountryCode) {
    return (
      <Typography variant="h5" sx={{ p: 2 }}>
        Select a country to see the charts
      </Typography>
    );
  }

  if (!selectedCountryData) {
    return (
      <Typography variant="h5" sx={{ p: 2 }}>
        No information available for the current selection
      </Typography>
    );
  }

  return children;
};

export default WithCountryData;
