import { darken, lighten, styled } from "@mui/material";

interface StyledLinkPropsI {
  color: string;
}

export const StyledLinkLeaflet = styled("a")`
  width: unset !important;
  text-align: unset !important;
  width: unset !important;
  display: unset !important;
  line-height: unset !important;
  color: #444;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled("a")<StyledLinkPropsI>`
  color: ${(props) => darken(props.color, 0.2)};
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    color: ${(props) => lighten(props.color, 0.4)};
  }
`;

export default StyledLink;
