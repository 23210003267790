interface PropsI {
  fill?: string;
}

const PTC = (props: PropsI) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.17 163.17">
      <g>
        <g>
          <path
            style={{ fill: props.fill }}
            d="M81.58,163.17C36.6,163.17,0,126.57,0,81.58S36.6,0,81.58,0s81.58,36.6,81.58,81.58-36.6,81.58-81.58,81.58Zm0-147.91C45.01,15.25,15.25,45.01,15.25,81.58s29.76,66.33,66.33,66.33,66.33-29.76,66.33-66.33S118.16,15.25,81.58,15.25Z"
          />
          <g>
            <g>
              <path
                style={{ fill: props.fill }}
                d="M95.97,71.57c-2,0-3.97,.69-5.53,1.93-.32,.25-.37,.71-.12,1.03,.25,.32,.71,.37,1.03,.12,1.32-1.06,2.92-1.61,4.62-1.61,4.09,0,7.41,3.32,7.41,7.41,0,5.5-5.74,10.79-7.41,12.2-1.67-1.42-7.41-6.69-7.41-12.2,0-1.33,.36-2.64,1.03-3.78,.21-.35,.09-.8-.26-1-.35-.21-.8-.09-1,.26-.81,1.37-1.24,2.93-1.24,4.53,0,7.13,8.08,13.46,8.43,13.73,.13,.1,.29,.15,.45,.15s.32-.05,.45-.15c.34-.27,8.43-6.6,8.43-13.73,0-4.89-3.98-8.88-8.88-8.88"
              />
              <path
                style={{ fill: props.fill }}
                d="M93.45,80.44c0-1.39,1.13-2.52,2.52-2.52s2.52,1.13,2.52,2.52-1.13,2.52-2.52,2.52-2.52-1.13-2.52-2.52m6.5,0c0-2.19-1.79-3.98-3.98-3.98s-3.98,1.79-3.98,3.98,1.79,3.98,3.98,3.98c2.2,0,3.98-1.79,3.98-3.98"
              />
            </g>
            <g>
              <path
                style={{ fill: props.fill }}
                d="M74.01,85.08c-2,0-3.97,.69-5.53,1.93-.32,.25-.37,.71-.12,1.03,.25,.32,.71,.37,1.03,.12,1.32-1.06,2.92-1.61,4.62-1.61,4.09,0,7.41,3.32,7.41,7.41,0,5.5-5.74,10.79-7.41,12.2-1.67-1.42-7.41-6.69-7.41-12.2,0-1.33,.36-2.64,1.03-3.78,.21-.35,.09-.8-.26-1-.35-.21-.8-.09-1,.26-.81,1.37-1.24,2.93-1.24,4.53,0,7.13,8.08,13.46,8.43,13.73,.13,.1,.29,.15,.45,.15s.32-.05,.45-.15c.34-.27,8.43-6.6,8.43-13.73,0-4.89-3.98-8.88-8.88-8.88"
              />
              <path
                style={{ fill: props.fill }}
                d="M71.5,93.96c0-1.39,1.13-2.52,2.52-2.52s2.52,1.13,2.52,2.52-1.13,2.52-2.52,2.52-2.52-1.13-2.52-2.52m6.5,0c0-2.19-1.79-3.98-3.98-3.98s-3.98,1.79-3.98,3.98,1.79,3.98,3.98,3.98c2.2,0,3.98-1.79,3.98-3.98"
              />
            </g>
            <g>
              <path
                style={{ fill: props.fill }}
                d="M69.79,50.46c-2,0-3.97,.69-5.53,1.93-.32,.25-.37,.71-.12,1.03,.25,.32,.71,.37,1.03,.12,1.32-1.06,2.92-1.61,4.62-1.61,4.09,0,7.41,3.32,7.41,7.41,0,5.5-5.74,10.79-7.41,12.2-1.67-1.42-7.41-6.69-7.41-12.2,0-1.33,.36-2.64,1.03-3.78,.21-.35,.09-.8-.26-1-.35-.21-.8-.09-1,.26-.81,1.37-1.24,2.93-1.24,4.53,0,7.13,8.08,13.46,8.43,13.73,.13,.1,.29,.15,.45,.15s.32-.05,.45-.15c.34-.27,8.43-6.6,8.43-13.73,0-4.89-3.98-8.88-8.88-8.88"
              />
              <path
                style={{ fill: props.fill }}
                d="M67.27,59.33c0-1.39,1.13-2.52,2.52-2.52s2.52,1.13,2.52,2.52-1.13,2.52-2.52,2.52-2.52-1.13-2.52-2.52m6.5,0c0-2.19-1.79-3.98-3.98-3.98s-3.98,1.79-3.98,3.98,1.79,3.98,3.98,3.98c2.2,0,3.98-1.79,3.98-3.98"
              />
            </g>
            <path
              style={{ fill: props.fill }}
              d="M81.31,120.64c-21.75,0-39.37-17.63-39.37-39.37s17.63-39.37,39.37-39.37,39.37,17.63,39.37,39.37-17.63,39.37-39.37,39.37Zm0-75c-19.67,0-35.62,15.95-35.62,35.62s15.95,35.62,35.62,35.62,35.62-15.95,35.62-35.62-15.95-35.62-35.62-35.62Z"
            />
            <path
              style={{ fill: props.fill }}
              d="M99.78,90.24l3.38-6.76c6.33-3.54,10.6-6.86,16.4-11.22,9.14-7.1,8.87-9.45,8.87-9.45h0s-1.81-1.66-12.92,1.36l-.99-3.63c13.4-3.64,16.29-1.39,17.34,.59,1.05,1.99,1.15,5.64-9.94,14.19-5.96,4.44-15.63,11.33-22.14,14.92Z"
            />
            <path
              style={{ fill: props.fill }}
              d="M42.46,99.06c-9.51,0-11.36-2.32-11.87-4.2s0-5.76,12.56-11.92l1.65,3.37c-10.37,5.14-10.57,7.5-10.57,7.59h0s1.44,1.99,12.94,1.24c6.08-.46,12.85-1.18,18.83-2.37v3.64c-6.14,1.22-12.34,2.07-18.57,2.55-1.86,.04-3.51,.09-4.97,.09Z"
            />
            <path
              style={{ fill: props.fill }}
              d="M109.27,120.34c-3.5-.38-9.23-2-19.07-6.41-5.57-2.52-10.92-5.16-16.02-7.92l3.7-1.64c4.98,2.68,10.2,5.26,15.63,7.72,13.45,6.02,16.74,5.64,16.76,5.64h0s2.27,.52-6.27-7.23l3.38-.84c5.91,5.07,8.44,9.29,6.43,10.4-1.25,.42-2.9,.52-4.54,.29Z"
            />
            <path
              style={{ fill: props.fill }}
              d="M61.78,62.37c-3.32-2.51-7.56-5.26-10.35-8.05-5.08-5.2-4.27-6.75-3.24-7.46s3.31-1.26,10.92,2.21l-1.36,1.4c-6.35-2.89-7.81-2.41-7.82-2.41h0s-.68,1,3.55,5.34c2.73,2.71,5.05,4.86,8.3,7.29v1.69Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PTC;
