import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import SummaryPage, { SummaryTitleText } from "components/reusables/SummaryPage";
import { blue } from "constants/colors";
import colorByPageName from "constants/colorByPageName";
import StyledLink from "components/reusables/StyledLink";

const title = "Accessibility statement";
const color = colorByPageName[title];

interface ListComponentPropsI {
  listItems: string[];
  type?: string;
}
interface TypographyDateComponentPropsI {
  date: string;
}

const abilityContent = ["zoom up to 200% without problems", "navigate most of the website using just a keyboard"];
const emailContent = ["E-mail: jrc-forest-observatory@ec.europa.eu"];
const browserAcceptedContent = ["the latest version of Google Chrome and Apple Safari browsers;"];
const technologyBrowser = ["HTML", "WAI-ARIA", "CSS", "JavaScript"];
const noAccessibleContent = [
  "Maps and charts are not controllable by keyboard",
  "Most images do not have alt text descriptions",
  "Most buttons and/or links don’t have readable text",
  "Most documents are in PDF format and are not accessible",
  "In addition we must note that the majority of text has a color that does not provide sufficient color contrast (rgb(110, 158, 117) color over white background )."
];
const ListComponent = ({ listItems, type }: ListComponentPropsI) => {
  const typeOfList = type ? type : "circle";
  return (
    <List disablePadding={true} sx={{ listStyleType: typeOfList, pl: 4 }}>
      {listItems &&
        listItems.map((item: string, index) => {
          return (
            <ListItem key={index} sx={{ display: "list-item" }}>
              <ListItemText
                primary={item}
                primaryTypographyProps={{
                  color: blue,
                  variant: "body1"
                }}
              />
            </ListItem>
          );
        })}
    </List>
  );
};

const TypographyDateComponent = ({ date }: TypographyDateComponentPropsI) => {
  return (
    <Typography variant="subtitle2" mt={1} mb={1} gutterBottom color={blue}>
      {date}
    </Typography>
  );
};

const Acc1Title = () => {
  return <></>;
};
const Acc1 = () => {
  return (
    <>
      <Typography color={blue}>
        This statement applies to content published on the domain: 'https://forest-observatory.ec.europa.eu'. It does
        not apply to other content or websites published on any of its subdomains. These websites and their content will
        have their own specific accessibility statement. This website is managed by Joint Research Centre. It is
        designed to be used by as many people as possible, including people with disabilities. You should be able to:
      </Typography>
      <ListComponent listItems={abilityContent} />
      <Typography color={blue}>
        This website is designed to comply with the{" "}
        <StyledLink
          href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf"
          target="_blank"
          color={blue}
          aria-label="Link to technical standard for website and mobile(external link will be opened in your email client)"
        >
          technical standard for websites and mobile apps, EN 301 549, v.3.2.1
        </StyledLink>{" "}
        This closely follows level 'AA' of the{" "}
        <StyledLink
          href="https://www.w3.org/TR/WCAG21/"
          target="_blank"
          color={blue}
          aria-label="Link to W3C accessibility guidlines (external link will be opened in your email client)"
        >
          Web Content Accessibility Guidelines (WCAG) version 2.1
        </StyledLink>
      </Typography>
    </>
  );
};
const Acc2Title = () => {
  return <>Compliance status</>;
};
const Acc2 = () => {
  return (
    <>
      <Typography color={blue}>
        This website is not compliant with{" "}
        <StyledLink
          href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf"
          target="_blank"
          color={blue}
          aria-label="Link to technical standard EN 301 549 (external link will be opened in your email client)"
        >
          technical standard EN 301 549 v.3.2.1
        </StyledLink>{" "}
        and the{" "}
        <StyledLink
          href="https://www.w3.org/WAI/standards-guidelines/wcag/"
          target="_blank"
          color={blue}
          aria-label="Link to W3C Accessibility guidelines WCAG 2.1 (external link will be opened in your email client)"
        >
          Web Content Accessibility Guidelines (WCAG) 2.1 Level AA
        </StyledLink>
        . See{" "}
        <StyledLink href="/accessibility#noAccessibleContent" color={blue} aria-label="Link to non accessible content">
          'Non-accessible content'
        </StyledLink>{" "}
        for more details.
      </Typography>
      <TypographyDateComponent date="The website was last tested on 29/11/2023" />
    </>
  );
};
const Acc3Title = () => {
  return <>Preparation of this statement</>;
};
const Acc3 = () => {
  return <TypographyDateComponent date="This statement was reviewed on 29/11/2023" />;
};
const Acc4Title = () => {
  return <>Feedback</>;
};
const Acc4 = () => {
  return (
    <>
      <Typography color={blue}>
        We welcome your feedback on the accessibility of the 'https://forest-observatory.ec.europa.eu' website. Please
        let us know if you encounter accessibility barriers:
      </Typography>
      <ListComponent listItems={emailContent} />
      <Typography color={blue}>
        We try to respond to feedback within 15 business days from the date of receipt of the enquiry by the responsible
        Commission department.
      </Typography>
    </>
  );
};
const Acc5Title = () => {
  return <>Compatibility with browsers and assistive technology</>;
};
const Acc5 = () => {
  return (
    <>
      <Typography color={blue}>
        The 'https://forest-observatory.ec.europa.eu' website is designed to be compatible with the following most used
        assistive technologies:
      </Typography>
      <ListComponent listItems={browserAcceptedContent} />
    </>
  );
};
const Acc6Title = () => {
  return <>Technical specifications</>;
};
const Acc6 = () => {
  return (
    <>
      <Typography color={blue}>
        The accessibility of the 'https://forest-observatory.ec.europa.eu' website relies on the following technologies
        to work with the particular combination of web browser and any assistive technologies or plugins installed on
        your computer:
      </Typography>
      <ListComponent listItems={technologyBrowser} />
    </>
  );
};
const Acc7Title = () => {
  return (
    <>
      Non-accessible content
      <Typography color={blue} id="noAccessibleContent">
        {" "}
      </Typography>
    </>
  );
};
const Acc7 = () => {
  return (
    <>
      <Typography color={blue}>
        Despite our best efforts to ensure accessibility of the 'https://forest-observatory.ec.europa.eu' website, we
        are aware of some limitations, which we are working to fix. Below is a description of known limitations and
        potential solutions. Please contact us if you encounter an issue not listed below.
      </Typography>
      <Typography color={blue}>Known limitations for the 'https://forest-observatory.ec.europa.eu' website:</Typography>
      <ListComponent listItems={noAccessibleContent} type="decimal" />
    </>
  );
};
const AccessibilityDescription = () => {
  return (
    <Box>
      <SummaryTitleText color={blue} title={<Acc1Title />} content={<Acc1 />} />
      <SummaryTitleText color={blue} title={<Acc2Title />} content={<Acc2 />} />
      <SummaryTitleText color={blue} title={<Acc3Title />} content={<Acc3 />} />
      <SummaryTitleText color={blue} title={<Acc4Title />} content={<Acc4 />} />
      <SummaryTitleText color={blue} title={<Acc5Title />} content={<Acc5 />} />
      <SummaryTitleText color={blue} title={<Acc6Title />} content={<Acc6 />} />
      <SummaryTitleText color={blue} title={<Acc7Title />} content={<Acc7 />} />
    </Box>
  );
};

const AccessibilityPage = () => {
  return (
    <SummaryPage
      backgroundImgPath="background_accessibility"
      title={title}
      color={color}
      mainContent={<AccessibilityDescription />}
    />
  );
};

export default AccessibilityPage;
