import React, { FunctionComponent, useContext } from "react";
import { MapContainer, ScaleControl } from "react-leaflet";
import { CRS } from "leaflet";

import MapLegendButton from "./MapLegendButton";
import MapResetBoundsButton from "./MapResetBoundsButton";
import MapAttributionButton from "./MapAttributionButton";
import { GeoServerClient } from "utils/ogcClients";

import BoxZoomButton from "./BoxZoomButton";
import ToggleSidebarButton from "./ToggleSidebarButton";
import ClickCountryHandler from "./ClickCountryHandler";
import SelectedCountryPolygon from "./SelectedCountryPolygon";
import LayersControlsButton from "./LayersControlsButton";
import WmsLayers from "./WmsLayers";
import { initialBounds, maxBounds, MapPageContext } from "../MapPage";
import BaseLayer from "./BaseLayer";
import CursorCoordinatesControl from "./CursorCoordinatesControl";
import ZoomInOutButtons from "components/reusables/MapComponents/ZoomInOutButtons";
import MapCountrySelectButton from "components/reusables/MapComponents/CountrySelectButton";

interface StandardLeafletMapOptionsI {
  hideLegendButton?: boolean;
  disableCountryClearable?: boolean;
  countrySelectLabel?: string;
}
export interface StandardLeafletMapPropsI {
  setMap?: any;
  children?: React.ReactNode;
  ref?: any;
  CustomControlPanel?: FunctionComponent;
  options?: StandardLeafletMapOptionsI;
  crs?: CRS;
  showScaleToRestrictLegend?: boolean;
  hideSidebarButton?: boolean;
}

const defaultOptions: StandardLeafletMapOptionsI = {
  hideLegendButton: false
};

const StandardLeafletMap = (props: StandardLeafletMapPropsI) => {
  const {
    setMap,
    children,
    CustomControlPanel,
    crs = CRS.EPSG4326,
    showScaleToRestrictLegend,
    hideSidebarButton = false
  } = props;
  const { hideLegendButton, disableCountryClearable } = { ...defaultOptions, ...props.options };

  const {
    selectedLayers,
    selectedCountryPolygon,
    setSelectedCountryCode,
    setSelectedCountryPolygon,
    isSidebarVisible,
    setIsSidebarVisible,
    canClick,
    setCanClick,
    geometryClient = new GeoServerClient(),
    canSelectCountry
  } = useContext(MapPageContext);

  const handleCountryClick = (feature?: GeoJSON.Feature) => {
    if (!feature) {
      setSelectedCountryCode("ZZ");
      setSelectedCountryPolygon(null);
    } else {
      const { properties } = feature;
      const countryCode = properties?.country_code;
      setSelectedCountryCode(countryCode);
      setSelectedCountryPolygon(feature);
    }
  };
  const handleSidebarToggle = (e: any) => {
    e.stopPropagation();
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleZoomStart = () => setCanClick(false);
  // hack!! Otherwise the ClickCountryHandler handles the click event
  // even if it is not mounted yet, for unknown reason.
  const handleZoomEnd = () => setTimeout(() => setCanClick(true), 200);

  const mapLegendParams: any = showScaleToRestrictLegend ? { showScaleToRestrictLegend: true } : {};
  return (
    <MapContainer
      scrollWheelZoom={true}
      style={{ height: "100%", width: "100%" }}
      bounds={initialBounds}
      attributionControl={false}
      minZoom={2}
      ref={setMap}
      crs={crs}
      zoomControl={false}
      maxBounds={maxBounds}
      maxBoundsViscosity={1.0}
      gestureHandling
    >
      <BaseLayer />
      <WmsLayers layers={selectedLayers} />
      {canSelectCountry && <MapCountrySelectButton disableClearable={disableCountryClearable} />}
      {CustomControlPanel ? <CustomControlPanel /> : <LayersControlsButton />}
      <SelectedCountryPolygon polygon={selectedCountryPolygon} />
      {canClick && canSelectCountry && (
        <ClickCountryHandler onCountryClick={handleCountryClick} ogcServerClient={geometryClient} />
      )}

      <ZoomInOutButtons position="topLeft" />
      <BoxZoomButton position="topLeft" onZoomStart={handleZoomStart} onZoomEnd={handleZoomEnd} />
      <MapResetBoundsButton position="topLeft" />

      {!hideSidebarButton && (
        <ToggleSidebarButton position="topRight" onToggle={handleSidebarToggle} isOpen={isSidebarVisible} />
      )}
      {!hideLegendButton && <MapLegendButton position="topLeft" {...mapLegendParams} />}
      <MapAttributionButton position="bottomRight" />
      <CursorCoordinatesControl position="bottomCenter" />

      <ScaleControl position="bottomleft" imperial={false} maxWidth={100} />

      {children}
    </MapContainer>
  );
};

export default StandardLeafletMap;
