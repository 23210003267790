import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import { latLngBounds, Map } from "leaflet";
import ResizableSidebar from "./ResizableSidebar";
import { AnyObject, LayerConfigI, LayerConfigTreeType } from "../../types";
import { instanceOfLayerGroupConfigI, instanceOfLayerGroupYearsConfigI } from "../../types/typesUtils";
import SidebarTabs, { TabConfigI } from "components/reusables/SidebarTabs";
import { getPolygonByCountryCode } from "utils/mapPageUtils";
import { GeoServerClient } from "utils/ogcClients";
import { Box } from "@mui/material";
import useHeaderHeight from "hooks/useHeaderHeight";
import useContextSync from "hooks/useContextSync";

export const initialBounds = latLngBounds([
  [-30, -90],
  [70, 90]
]);

export const maxBounds = latLngBounds([
  [90, -190],
  [-90, 190]
]);

interface ForwardPropsToContextI {
  map: Map | null;
  onlyOneLayerVisible?: boolean;
  canClick?: boolean;
  canSelectCountry?: boolean;
  geometryClient?: GeoServerClient;
  layersConfig: LayerConfigTreeType;
  disableFitBoundsOnCountryChange?: boolean;
}

export interface MapPageContextI extends ForwardPropsToContextI {
  isSidebarVisible: boolean;
  setIsSidebarVisible: Dispatch<SetStateAction<boolean>>;
  selectedCountryCode: string;
  setSelectedCountryCode: Dispatch<SetStateAction<string>>;
  selectedCountryData: AnyObject | null;
  setSelectedCountryData: Dispatch<SetStateAction<any | null>>;
  selectedCountryPolygon: GeoJSON.Feature | null;
  setSelectedCountryPolygon: Dispatch<SetStateAction<GeoJSON.Feature | null>>;
  selectedYear: number | undefined;
  setSelectedYear: Dispatch<SetStateAction<number | undefined>>;
  selectedLayers: LayerConfigI[];
  setSelectedLayers: Dispatch<SetStateAction<LayerConfigI[]>>;
  setCanClick: Dispatch<SetStateAction<boolean>>;
  countriesApiPath: string;
  activeTabIndex: number | undefined;
  setActiveTabIndex: Dispatch<SetStateAction<number | undefined>>;
}

export interface CountryDataGetterI {
  countryCode: string;
  year?: number;
  selectedLayer?: LayerConfigI;
}

const initialContext: MapPageContextI = {
  map: null,
  isSidebarVisible: false,
  setIsSidebarVisible: () => null,
  selectedCountryCode: "",
  setSelectedCountryCode: () => null,
  selectedCountryData: null,
  setSelectedCountryData: () => null,
  selectedCountryPolygon: null,
  setSelectedCountryPolygon: () => null,
  selectedYear: 2019,
  setSelectedYear: () => null,
  layersConfig: [],
  selectedLayers: [],
  setSelectedLayers: () => null,
  canClick: true,
  setCanClick: () => null,
  countriesApiPath: "gen/Countries",
  activeTabIndex: undefined,
  setActiveTabIndex: () => null
};

export const MapPageContext = React.createContext<MapPageContextI>(initialContext);

interface MapPagePropsI extends ForwardPropsToContextI {
  children: React.ReactNode;
  onYearChange?: (context: MapPageContextI) => void;
  onCountryCodeChange?: (context: MapPageContextI) => void;
  onSelectedLayersChange?: (context: MapPageContextI) => void;
  onInit?: (context: MapPageContextI) => void;
  initialYear: number | undefined;
  sidebarContent?: React.ReactNode;
  countriesApiPath?: string | ((context: MapPageContextI) => string);
  initialCountryCode?: string;
  tabs?: TabConfigI[];
  countryDataGetter?: (info: CountryDataGetterI) => unknown;
  getCountryDataOnInit?: boolean;
  openSidebarOnCountryChange?: boolean;
}

// Only single layer selection is supported at the moment
const getInitialSelectedLayers = (layersConfig: LayerConfigTreeType, initialYear?: number): LayerConfigI[] => {
  let found;
  for (const item of layersConfig) {
    if (instanceOfLayerGroupConfigI(item)) {
      const foundChild = item.children?.find((child) => child.defaultSelected === true);
      if (foundChild) return [foundChild];
    } else if (instanceOfLayerGroupYearsConfigI(item)) {
      if (item.defaultSelected && initialYear) {
        const foundChild = item.children.find((child) => child.year === initialYear);
        if (foundChild) return [foundChild];
      }
    } else if (item.defaultSelected === true) found = item;
  }

  if (found) return [found];
  else return [];
};

const MapPage = (props: MapPagePropsI) => {
  const {
    layersConfig,
    children,
    onYearChange,
    onCountryCodeChange,
    onSelectedLayersChange,
    onInit,
    initialYear,
    map,
    sidebarContent,
    initialCountryCode,
    countriesApiPath,
    countryDataGetter,
    geometryClient,
    onlyOneLayerVisible = true,
    getCountryDataOnInit = true,
    openSidebarOnCountryChange = true,
    disableFitBoundsOnCountryChange
  } = props;
  const headerHeight = useHeaderHeight();

  const initialSelectedLayers = getInitialSelectedLayers(layersConfig, initialYear);

  const isFirstRender = useRef(true);
  const [isSidebarVisible, setIsSidebarVisible] = useState(initialContext.isSidebarVisible);

  const initialCode = initialCountryCode || initialContext.selectedCountryCode;
  const [selectedCountryCode, setSelectedCountryCode] = useState(initialCode);
  const [selectedCountryData, setSelectedCountryData] = useState(initialContext.selectedCountryData);
  const [selectedCountryPolygon, setSelectedCountryPolygon] = useState(initialContext.selectedCountryPolygon);
  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [selectedLayers, setSelectedLayers] = useState(initialSelectedLayers);
  const [canClick, setCanClick] = useState(initialContext.canClick || false);
  const [activeTabIndex, setActiveTabIndex] = useState(initialContext.activeTabIndex);

  const context: MapPageContextI = {
    map,
    isSidebarVisible,
    setIsSidebarVisible,
    selectedCountryCode,
    setSelectedCountryCode,
    selectedCountryPolygon,
    setSelectedCountryPolygon,
    selectedCountryData,
    setSelectedCountryData,
    selectedYear,
    setSelectedYear,
    layersConfig,
    selectedLayers,
    setSelectedLayers,
    canClick: props.canClick === undefined ? canClick : props.canClick,
    setCanClick,
    onlyOneLayerVisible,
    countriesApiPath: initialContext.countriesApiPath,
    geometryClient,
    disableFitBoundsOnCountryChange,
    activeTabIndex,
    setActiveTabIndex,
    canSelectCountry: props.canSelectCountry ?? true
  };

  useContextSync(context, "mapPage");

  if (typeof countriesApiPath === "function") context.countriesApiPath = countriesApiPath(context);
  else if (countriesApiPath) context.countriesApiPath = countriesApiPath;

  const getCountryData = async (c: MapPageContextI) => {
    if (!countryDataGetter) return;
    const { setSelectedCountryData, selectedCountryCode, selectedYear, selectedLayers } = c;
    if (!selectedYear) return;

    const info: CountryDataGetterI = {
      countryCode: selectedCountryCode,
      year: selectedYear
    };

    if (selectedLayers && selectedLayers.length != 0) info.selectedLayer = selectedLayers[0];

    const data = await countryDataGetter(info);

    if (data) setSelectedCountryData(data);
    else setSelectedCountryData(null);
  };

  const handleCountryCodeChange = async () => {
    if (countryDataGetter) getCountryData(context);
    if (geometryClient) getPolygonByCountryCode(context, geometryClient);
    if (openSidebarOnCountryChange) setIsSidebarVisible(true);
    if (!selectedCountryCode) setIsSidebarVisible(false);
  };

  useEffect(() => {
    if (initialCountryCode) handleCountryCodeChange();
  }, [initialCountryCode]);

  useEffect(() => {
    if (isFirstRender.current) return;
    handleCountryCodeChange();
    if (onCountryCodeChange) onCountryCodeChange(context);
  }, [selectedCountryCode]);

  useEffect(() => {
    if (isFirstRender.current) return;
    getCountryData(context);
    if (onYearChange) onYearChange(context);
  }, [selectedYear]);

  useEffect(() => {
    if (isFirstRender.current) return;
    getCountryData(context);
    if (onSelectedLayersChange) onSelectedLayersChange(context);
  }, [selectedLayers]);

  useEffect(() => {
    isFirstRender.current = false;
    if (getCountryDataOnInit) getCountryData(context);
    if (onInit) onInit(context);
    return () => {
      isFirstRender.current = true;
    };
  }, []);

  const tabs = props.tabs || [];

  return (
    <MapPageContext.Provider value={context}>
      <Box className="flex w-full h-full" sx={{ height: `calc(100vh - ${headerHeight}px)` }}>
        {children}
        <ResizableSidebar map={map} isOpen={isSidebarVisible}>
          {tabs.length > 0 && (
            <SidebarTabs tabs={tabs} activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} />
          )}
          {tabs.length === 0 && sidebarContent && sidebarContent}
        </ResizableSidebar>
      </Box>
    </MapPageContext.Provider>
  );
};

export default MapPage;
