export const tmfCategories = ["deforestation", "annual-change", "degradation", "main-classes"] as const;
export type TmfCategoryType = typeof tmfCategories[number];

export const gfaCategories = ["forest", "fragmentation", "accounting", "pattern"] as const;
export type GfaCategoryType = typeof gfaCategories[number];

export const ghmCategories = ["harmonized"] as const;
export type GhmCategoryType = typeof ghmCategories[number];

export const rmapCategories = ["forest"] as const;
export type RmapCategoryType = typeof rmapCategories[number];

export const comPrdcategories = ["prd", "prd_area", "yld"] as const;
export type ComPrdCategoryType = typeof comPrdcategories[number];
