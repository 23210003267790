import { CSSProperties } from "react";

export const getFlagFilePathByCountryCode = (countryCode: string) => {
  let fileName = `000_NOT_FOUND.svg`;

  if (countryCode && countryCode !== undefined && countryCode !== "") {
    if (countryCode === "EU27") fileName = "000_EUROPE.svg";
    else fileName = `${countryCode}.svg`;
  }

  return `/flags/${fileName}`;
};

export const CountryFlagString = (props: { countryCode: string; style?: CSSProperties }) => {
  const filePath = getFlagFilePathByCountryCode(props.countryCode);
  return "<img src=" + filePath + " style='max-width=40px;max-height:20px; border: 0.5px solid #aaa; z-index: -1;'/>";
};

const CountryFlag = (props: { countryCode: string; style?: CSSProperties }) => {
  const filePath = getFlagFilePathByCountryCode(props.countryCode);
  return <img src={filePath} style={{ maxWidth: 40, maxHeight: 20, border: "0.5px solid #aaa", ...props.style }} />;
};

export default CountryFlag;
