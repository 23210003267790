import { ColorScaleItemI } from "constants/colorScales";

export interface ApiMappingItem {
  title: string;
  description?: string;
  color?: string;
  positiveNegativeColors?: [string, string];
  colorScale?: ColorScaleItemI[];
}

export type ApiMapping = Record<string, ApiMappingItem>;

const apiFieldsAnnualVariationStatistics: ApiMapping = {
  country_name: { title: "Country Name" },
  country_code: { title: "Country Code" },
  period: { title: "Year" },
  unit: { title: "Unit" }
};

export const apiFieldsProductionAnnualVariationStatistics: ApiMapping = {
  ...apiFieldsAnnualVariationStatistics,
  value: { title: "Value" }
};

export const apiFieldsProductionAreaAnnualVariationStatistics: ApiMapping = {
  ...apiFieldsAnnualVariationStatistics,
  value: { title: "Area" }
};

export const apiFieldsProductionYieldAnnualVariationStatistics: ApiMapping = {
  ...apiFieldsAnnualVariationStatistics,
  value: { title: "Yield" }
};

export const apiFieldsAverageStatistics: ApiMapping = {
  country_name: { title: "Country Name" },
  country_code: { title: "Country Code" },
  period: { title: "Year" },
  unit: { title: "Unit" },
  avg_value: { title: "Production" },
  interval: { title: "" }
};
