import React, { useContext, useState } from "react";
import { FormControlLabel, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { LayerConfigI, LayerGroupYearsConfigI } from "../../../../types";
import { MapPageContext } from "../../MapPage";
import { Info } from "@mui/icons-material";
import CustomCheckbox from "components/reusables/CustomCheckbox";

interface YearsGroupLayerPropsI {
  layerConfig: LayerGroupYearsConfigI;
}

const LayerGroupYears = ({ layerConfig }: YearsGroupLayerPropsI) => {
  const { selectedYear, setSelectedYear, onlyOneLayerVisible, selectedLayers, setSelectedLayers } =
    useContext(MapPageContext);
  const { title, children, name, years, description } = layerConfig;
  const [showDescription, setShowDescription] = useState(false);

  if (!years || years.length === 0) return null;

  const latestYear = years[years.length - 1];

  const foundSelected = selectedLayers.find((selected) => children.find((child) => selected.id === child.id));

  const getNewSelected = () => {
    if (selectedYear) return children.find((i) => i.year === selectedYear);
    else return children.find((i) => i.year === latestYear);
  };

  const handleToggle = () => {
    const selectedIndex = selectedLayers.findIndex((i) => i.id === foundSelected?.id);
    if (selectedIndex === -1) {
      const newSelected = getNewSelected();
      if (onlyOneLayerVisible) {
        if (newSelected) setSelectedLayers([newSelected]);
      } else if (newSelected) setSelectedLayers([...selectedLayers, newSelected]);

      if (newSelected) handleYearChange(newSelected);
    } else {
      const newLayers = [...selectedLayers];
      newLayers.splice(selectedIndex, 1);
      setSelectedLayers(newLayers);
    }
  };

  const handleYearChange = (newSelected: LayerConfigI) => {
    setSelectedYear(newSelected.year);
  };

  const handleInfoClick = () => setShowDescription(!showDescription);

  const isChecked = !!foundSelected;

  return (
    <Box className="w-full">
      <Box className="flex justify-between w-full">
        <FormControlLabel
          control={<CustomCheckbox name={name} checked={isChecked} onChange={handleToggle} size="small" />}
          label={isChecked ? <Typography style={{ fontWeight: "500" }}>{title}</Typography> : title}
          style={{ alignItems: "flex-start" }}
        />
        {description && (
          <IconButton
            size="small"
            sx={{ color: "primary.main", height: 30, width: 30, my: "auto" }}
            onClick={handleInfoClick}
          >
            <Info fontSize="small" />
          </IconButton>
        )}
      </Box>
      {showDescription && <Box>{description}</Box>}
    </Box>
  );
};

export default LayerGroupYears;
