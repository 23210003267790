import { useState } from "react";
import { Map } from "leaflet";

import { GeoServerClient } from "utils/ogcClients";
import MapPage from "components/reusables/MapPage";
import StandardLeafletMap from "components/reusables/MapComponents/StandardLeafletMap";

import { SelectedCountryLayerTitle } from "components/reusables/CountryLayerTitle";
import { layersConfig } from "./layersConfig";
import Sidebar from "./Sidebar";
import Info from "./Sidebar/Info";

const INITIAL_YEAR = 2024;
const geometryClient = new GeoServerClient("countries_car");

const tabs: any[] = [
  { title: <SelectedCountryLayerTitle />, content: <Sidebar /> },
  { title: "Info", content: <Info /> }
];

const GlobalCarbonPage = () => {
  const [map, setMap] = useState<Map | null>(null);

  return (
    <MapPage
      map={map}
      initialYear={INITIAL_YEAR}
      layersConfig={layersConfig}
      // countryDataGetter={getHarmonizedStatistics}
      geometryClient={geometryClient}
      tabs={tabs}
      countriesApiPath="car/Countries"
    >
      <StandardLeafletMap setMap={setMap} />
    </MapPage>
  );
};

export default GlobalCarbonPage;
