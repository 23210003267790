import WebtoolsComponent, { defaultHighchartsData } from "components/charts/WebtoolsComponent";
import { Options, SeriesColumnOptions, SeriesSplineOptions } from "highcharts";
import { set } from "lodash";
import { dataLabel, pointFormatter, pointFormatter2 } from "utils/chartsUtils";
import cloneDeep from "lodash/cloneDeep";

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;

const defaultData: Options = {
  ...defaultHighchartsData,
  chart: {
    type: "spline",
    height: 300
  },
  tooltip: {
    pointFormatter: pointFormatter2
  },
  xAxis: {
    tickInterval: 5
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: MAX_WIDTH,
          maxHeight: MAX_HEIGHT
        }
      }
    ]
  }
};

type Props = {
  data: SeriesSplineOptions["data"];
  //  data: SeriesColumnOptions["data"];
  //  series?: SeriesColumnOptions["data"]; //if this is set, data is ignored
  title?: string;
  categories?: string[];
  yAxisTitle?: string;

  // dataLabelDisable?: boolean | false;
  // hideLegend?: boolean | false;
  colors?: string[];
  height?: string;
};

const SplineChart = (props: Props) => {
  const { data, title, yAxisTitle, categories, colors, height } = props;

  let merged = cloneDeep(defaultData);
  merged = set(merged, "series", data);

  merged = set(merged, "title.text", title);

  if (categories) merged = set(merged, "xAxis.categories", categories);

  if (yAxisTitle) merged = set(merged, "yAxis.title.text", yAxisTitle);

  if (colors) {
    merged = set(merged, "colors", colors);
    merged = set(merged, "plotOptions.column.colorByPoint", true);
  }
  if (height) merged = set(merged, "chart.height", height);

  return <WebtoolsComponent id={`column_${title}`} data={merged} />;
};

export default SplineChart;
