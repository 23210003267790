import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import { Box } from "@mui/material";
import Control, { ControlPositionType } from "components/reusables/MapComponents/LeafletCustomControl";
import MapButton from "components/reusables/MapComponents/MapButton";

import {
  colorByCategory,
  strokeWidthByKgsBreakpoints,
  TradeCategory
} from "components/pages/CommoditiesTradePage/TradePath";
import { TradePageContext } from "components/pages/CommoditiesTradePage";
import { useContext } from "react";

const TradePathsLegend = () => {
  const { selectedTrade } = useContext(TradePageContext);
  return (
    <Box className="flex flex-column" sx={{ width: 200 }}>
      {Object.entries(strokeWidthByKgsBreakpoints).map(([quantity, strokeWidth], index, array) => {
        const nextEntry = array[index + 1];

        const formattedQuantity = Number(quantity) / 1000;
        const formattedNextQuantity = nextEntry ? Number(nextEntry[0]) / 1000 : "";

        const category: TradeCategory = selectedTrade?.category || "Combined";

        return (
          <Box className="flex gap-10">
            <Box className="flex flex-column">
              <hr
                style={{
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderTop: `${strokeWidth}px solid ${colorByCategory[category]}99`,
                  width: 50
                }}
              />
            </Box>
            <Box className="flex items-center">
              {!nextEntry && "More than "}
              {formattedQuantity}k{nextEntry && " - "}
              {formattedNextQuantity}
              {nextEntry && "k"} tons
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

interface PropsI {
  position: ControlPositionType;
}

const MapLegendButton = ({ position }: PropsI) => {
  return (
    <Control position={position}>
      <MapButton tooltip="Legend" popupContent={<TradePathsLegend />}>
        <FormatListBulletedIcon />
      </MapButton>
    </Control>
  );
};

export default MapLegendButton;
