import { Box, IconButton, Typography } from "@mui/material";
import { BREADCRUMB_HEIGHT } from "layout/Header";
import { blue } from "constants/colors";
import React, { Dispatch, SetStateAction } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link } from "react-router-dom";
import StyledLink from "../components/reusables/StyledLink";
import { inherits } from "util";

interface MainWhoWeArePropsI {
  showWhoWeAre: boolean;
  setShowHideWhoWeAre: Dispatch<SetStateAction<boolean>>;
}

export const WhoWeAre = (props: MainWhoWeArePropsI) => {
  const { showWhoWeAre, setShowHideWhoWeAre } = props;

  return (
    <>
      <Box
        sx={{
          height: BREADCRUMB_HEIGHT,
          color: blue,
          backgroundColor: "transparent",
          whiteSpace: "nowrap",
          width: "100%",
          align: "center"
        }}
        pl={{ xs: 1, sm: 3 }}
        pr={{ xs: 1, sm: 3 }}
      >
        <Box sx={{ ml: "auto", float: "right" }}>
          <IconButton
            onClick={() => {
              setShowHideWhoWeAre(!showWhoWeAre);
            }}
            size="small"
            sx={{ borderRadius: 0, color: blue, paddingTop: "1rem" }}
            aria-label="Hide header details shown"
          >
            {!false && <img alt="Click to close" src="cross.svg" width={"10rem"} />}
          </IconButton>
        </Box>
        <Box sx={{ p: 1 }}>
          <Typography variant="h2" color={blue} sx={{ fontSize: "1.2rem", fontWeight: 1000 }}>
            About the observatory
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          color={blue}
          sx={{ fontSize: "1rem", fontWeight: 500, align: "justify", paddingRight: "5rem", paddingLeft: "5rem" }}
        >
          <br />
          The EU Observatory on deforestation and forest degradation aims to monitor changes in the world’s forest cover
          and related drivers. Besides providing access to global forest maps and spatial forest and forestry-related
          information, this Observatory will facilitate access to scientific information on supply chains, linking
          deforestation, forest degradation and changes in the world’s forest cover to Union demand for commodities and
          products. Data and information provided on this Observatory play a supporting role but do not assure
          compliance or imply non-compliance with EU Regulations, other legal frameworks or commitments, or
          international agreements.
        </Typography>
      </Box>

      <Box>
        <Typography variant="h2" color={blue} sx={{ fontSize: "0.9rem", fontWeight: 500, align: "justify" }}>
          <br />
          <StyledLink
            href="/Frequently_Asked_Questions_EUFO.docx"
            target="_blank"
            color={blue}
            aria-label="Link to the FAQ section(the link will be open a download window for save the related .doc file)"
          >
            Frequently asked questions
          </StyledLink>
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" color={blue} sx={{ fontSize: "0.9rem", fontWeight: 500, align: "justify" }}>
          <br /> Want to know more?{" "}
          <Link
            to="/info"
            style={{ width: "fit-content", textDecoration: "underline" }}
            aria-label="Link to the Jrc forest observatory info page"
          >
            Click here
          </Link>{" "}
          or contact us:{" "}
          <a
            href="mailto:jrc-forest-observatory@ec.europa.eu"
            style={{ textDecoration: "underline" }}
            aria-label="Link to the Jrc forest observatory email(external link will be opened in your email client)"
          >
            jrc-forest-observatory@ec.europa.eu
          </a>
        </Typography>

        <br />
      </Box>
    </>
  );
};

export function MainWhoWeAre(props: MainWhoWeArePropsI) {
  const { showWhoWeAre, setShowHideWhoWeAre } = props;

  const hoverUnderline = {
    "&:hover": {
      textDecoration: "underline",
      textDecorationThickness: 2
    }
  };

  return (
    <Box
      sx={{
        height: BREADCRUMB_HEIGHT,
        color: blue,
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap"
      }}
      pl={{ xs: 1, sm: 3 }}
      pr={{ xs: 1, sm: 3 }}
    >
      <Box className="flex" sx={{ pl: 2, pr: 1, verticalAlign: "center" }}>
        <Typography
          color={blue}
          sx={{
            ...hoverUnderline,
            opacity: 1,
            fontWeight: "500",
            fontSize: "1.2rem",
            cursor: "pointer",
            zIndex: 10
          }}
          onClick={() => {
            setShowHideWhoWeAre(!showWhoWeAre);
          }}
          aria-label="Click to expand and see more details"
        >
          About the observatory
        </Typography>
      </Box>
      <Box sx={{ ml: "auto" }}>
        <IconButton
          onClick={() => {
            setShowHideWhoWeAre(!showWhoWeAre);
          }}
          size="small"
          sx={{ borderRadius: 0, color: blue }}
          aria-label="Click to expand and see more details"
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default function BarWhoWeAre(props: MainWhoWeArePropsI) {
  const { showWhoWeAre, setShowHideWhoWeAre } = props;

  return (
    <header
      style={{
        position: "absolute",
        top: 0,
        // backgroundColor: "white",
        // opacity: 0.8,
        backgroundColor: "rgb(255, 255, 255, 0.8)",
        textAlign: "center",
        zIndex: 200,
        width: "100%"
      }}
    >
      {!showWhoWeAre && <MainWhoWeAre showWhoWeAre={showWhoWeAre} setShowHideWhoWeAre={setShowHideWhoWeAre} />}
      {showWhoWeAre && <WhoWeAre showWhoWeAre={showWhoWeAre} setShowHideWhoWeAre={setShowHideWhoWeAre} />}
    </header>
  );
}
