import { apiFieldsMappingHarmonizedStatistics } from "./apiFieldsMapping";

type HarmonizedStatisticsApiKey = keyof typeof apiFieldsMappingHarmonizedStatistics;

export const harmonizedDataKeys: HarmonizedStatisticsApiKey[] = [
  "defor",
  "harvest",
  "fire_out_harvest",
  "fire_in_harvest",
  "extreme_events",
  "degrad"
];

export const harvestDataKeys: HarmonizedStatisticsApiKey[] = ["harvest"];
export const forestDataKeys: HarmonizedStatisticsApiKey[] = ["defor"];
export const otherDataKeys: HarmonizedStatisticsApiKey[] = [
  "fire_out_harvest",
  "fire_in_harvest",
  "extreme_events",
  "degrad"
];
