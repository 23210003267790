import { groupBy } from "lodash";
import { ReactNode, useContext } from "react";
import { useQuery } from "react-query";
import { getAnnualVariations } from "utils/apiRequests";
import { TradePageContext } from "components/pages/CommoditiesTradePage";
import StackedAreaChart from "components/charts/StackedAreaChart";

interface PropsI {
  type?: string;
  dataSource?: string;
}

const colors = [
  "#DE3B80",
  "#FFA200",
  "#28965A",
  "#0E3B43",
  "#A63C06",
  "#2CEAA3",
  "#847996",
  "#710000",
  "#C36F09",
  "#F4E409",
  "#357266",
  "#A3BBAD",
  "#310A31",
  "#F4ECD6",
  "#463730"
];

const makeHighchartsSeries = (data: any[]): any[] => {
  const groupedByProduct = groupBy(data, "product_name");
  const allYears: number[] = [...new Set(data.map((item) => item.period))];
  const series = Object.entries(groupedByProduct).map(([productName, productDataList], index) => {
    return {
      name: productName,
      color: colors[index],
      data: allYears.map((i) => {
        const foundByYear = productDataList.find((dataPoint) => dataPoint.period === i);
        const { value, unit } = foundByYear || {};

        return {
          x: i,
          y: value || null,
          unit
        };
      })
    };
  });

  return series;
};

const AnnualVariation = (props: PropsI) => {
  const { dataSource, type } = props;
  const { selectedTrade, period, product } = useContext(TradePageContext);

  const { fromCountryCode, fromCountryName, toCountryCode, toCountryName } = selectedTrade || {};

  const queryOptions = {
    queryKey: ["annualVariation", fromCountryCode, toCountryCode, product?.commodity_name, dataSource, { type }],
    queryFn: getAnnualVariations,
    staleTime: Infinity,
    enabled: !!selectedTrade && !!period && !!fromCountryCode && !!product
  };

  const { data, isLoading, error } = useQuery(queryOptions);

  if (isLoading) return <>loading...</>;
  if (!data || error) return null;
  if (data.length === 0)
    return (
      <>
        No annual imported quantity from {fromCountryName} in {period}
      </>
    );

  const highchartsSeries = makeHighchartsSeries(data);
  const unit = data[0].unit;
  const title =
    type === "quantity"
      ? `Annual imported quantity by ${toCountryName} from ${fromCountryName} `
      : `Annual monetary value of the import of ${toCountryName} from ${fromCountryName} `;
  const yAxisTitle = type === "quantity" ? "Trade quantity (" + unit + ")" : "Monetary value (" + unit + ")";

  return (
    <>
      <StackedAreaChart title={title} series={highchartsSeries} yAxisTitle={yAxisTitle} />
    </>
  );
};

export default AnnualVariation;
