import React from "react";
import { useMap } from "react-leaflet";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import Control, { ControlPositionType } from "./LeafletCustomControl";

import MapButton from "./MapButton";
import { initialBounds } from "../MapPage";

interface PropsI {
  position: ControlPositionType;
}

const MapResetBoundsButton = ({ position }: PropsI) => {
  const map = useMap();

  const handleClick = () => map.fitBounds(initialBounds);

  return (
    <Control position={position}>
      <MapButton tooltip="Reset map bounds" onClick={handleClick}>
        <ZoomOutMapIcon />
      </MapButton>
    </Control>
  );
};

export default MapResetBoundsButton;
