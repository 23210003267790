import { Box } from "@mui/material";
import WithCountryData from "components/reusables/WithCountryData";

import LayerCharts from "./LayerCharts";
import WithInfoTooltipIcon from "components/reusables/WithInfoTooltipIcon";
import { greyTextColor } from "constants/colors";
import { makeTitle } from "utils/chartsUtils";
import GlobalCharts from "./GlobalCharts";
import { useContext } from "react";
import { MapPageContext } from "components/reusables/MapPage";

const PADDING = 3;

const TMFCharts = () => {
  const { setActiveTabIndex } = useContext(MapPageContext);

  return (
    <WithCountryData>
      <>
        <Box sx={{ padding: PADDING }}>
          <LayerCharts />
        </Box>
        <Box sx={{ padding: PADDING }}>
          <WithInfoTooltipIcon
            color={greyTextColor}
            tooltipProps={{ title: "More info" }}
            onIconClick={() => setActiveTabIndex(1)}
          >
            {makeTitle("Trends in Tropical Moist Forest")}
          </WithInfoTooltipIcon>
          <br />
          <GlobalCharts />
        </Box>
      </>
    </WithCountryData>
  );
};

export default TMFCharts;
