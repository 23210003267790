import { createElementObject, createTileLayerComponent, updateGridLayer, withPane } from "@react-leaflet/core";
import { TileLayer } from "leaflet";
import { TileLayerProps } from "react-leaflet";

interface CustomTileLayerProps extends TileLayerProps {
  language: string;
  projection: string;
}

export const CustomTileLayer = createTileLayerComponent<TileLayer, CustomTileLayerProps>(function createTileLayer(
  { url, ...options },
  context
) {
  const layer = new TileLayer(url, withPane(options, context));
  return createElementObject(layer, context);
},
updateGridLayer);

export default CustomTileLayer;
