import { createContext, Dispatch, lazy, ReactNode, SetStateAction, Suspense, useEffect, useState } from "react";
import { Map } from "leaflet";
import { useQuery } from "react-query";
import { CircularProgress } from "@mui/material";

import { CommoditiesProductI, TradePathInfoI } from "types";
import StandardLeafletMap from "components/reusables/MapComponents/StandardLeafletMap";
import MapPage from "components/reusables/MapPage";
import ControlsPanel from "components/pages/CommoditiesTradePage/ControlsPanel";
import SelectedCountryCenter from "components/pages/CommoditiesTradePage/SelectedCountryCenter";

import { CountryOptionI, getComProducts } from "utils/apiRequests";
import { layersConfig } from "./layersConfig";
import SidebarContent from "./SidebarContent";
import { SelectedCountryLayerTitle } from "components/reusables/CountryLayerTitle";
import MapLegendButton from "components/pages/CommoditiesTradePage/MapLegendButton";
import { GeoServerClient } from "utils/ogcClients";
import TruncateText from "components/reusables/TruncateText";

import useContextSync from "hooks/useContextSync";
import DocumentsTab from "../../reusables/DocumentsTab";
import documents from "./Sidebar/documentsConfig";

const TradePaths = lazy(() => import("./TradePaths"));

const INITIAL_YEAR = 2022;
export const PERIOD_OPTIONS_API_PATH = "/com/trades-average-periods";
export const REPORTERS_COUNTRIES_API_PATH = "/com/reporters";
export const DEFAULT_PRODUCT_NAME = "Cocoa beans";

export interface TradePageContextI {
  layer: "comtrade" | "faostat";
  setLayer: Dispatch<SetStateAction<TradePageContextI["layer"]>>;
  product: CommoditiesProductI | undefined;
  setProduct: Dispatch<SetStateAction<CommoditiesProductI | undefined>>;
  productName: string | undefined;
  setProductName: Dispatch<SetStateAction<string | undefined>>;
  selectedTrade: TradePathInfoI | undefined;
  setSelectedTrade: Dispatch<SetStateAction<TradePathInfoI | undefined>>;
  threshold: number;
  setThreshold: Dispatch<SetStateAction<number>>;
  period: string | undefined;
  setPeriod: Dispatch<SetStateAction<string | undefined>>;
  selectedPartnerCountry: CountryOptionI | undefined;
  setSelectedPartnerCountry: Dispatch<SetStateAction<CountryOptionI | undefined>>;
  periodOptionsApiPath?: string;
}

const initialContext: TradePageContextI = {
  layer: "comtrade",
  setLayer: () => null,
  product: undefined,
  setProduct: () => null,
  productName: undefined,
  setProductName: () => null,
  selectedTrade: undefined,
  setSelectedTrade: () => null,
  threshold: 90,
  setThreshold: () => null,
  period: undefined,
  setPeriod: () => null,
  periodOptionsApiPath: "",
  selectedPartnerCountry: undefined,
  setSelectedPartnerCountry: () => null
};

const geometryClient = new GeoServerClient("countries_prd");

export const TradePageContext = createContext(initialContext);

const TradePage = () => {
  const [productName, setProductName] = useState<string | undefined>(DEFAULT_PRODUCT_NAME);
  const [map, setMap] = useState<Map | null>(null);
  const [layer, setLayer] = useState<TradePageContextI["layer"]>(initialContext.layer);
  const [product, setProduct] = useState<TradePageContextI["product"]>();
  const [selectedTrade, setSelectedTrade] = useState<TradePageContextI["selectedTrade"]>();
  const [threshold, setThreshold] = useState(95);
  const [period, setPeriod] = useState<TradePageContextI["period"]>();
  const [selectedPartnerCountry, setSelectedPartnerCountry] = useState<CountryOptionI>();

  const { data: tree } = useQuery(["getCommoditiesTree", layer], getComProducts, { staleTime: Infinity });

  const setDefaultProduct = () => {
    if (tree) {
      const found = tree.find((i) => i.product_name === productName);
      setProductName(found?.product_name);
      setProduct(found);
    }
  };

  useEffect(() => {
    setDefaultProduct();
  }, [tree]);

  let sidebarTitle: ReactNode = <TruncateText text={product?.product_name || ""} charactersCount={40} />;
  if (selectedTrade)
    sidebarTitle = (
      <TruncateText
        text={`${product?.product_name || ""} imports from ${selectedTrade.fromCountryName}`}
        charactersCount={40}
      />
    );

  const tabs = [
    {
      title: <SelectedCountryLayerTitle layerTitle={sidebarTitle} replaceTitle={true} />,
      content: <SidebarContent />
    },
    { title: "Info", content: <DocumentsTab documents={documents()} /> }
  ];

  const handleCountryCodeChange = () => {
    setSelectedTrade(undefined);
  };

  const context = {
    layer,
    setLayer,
    product,
    setProduct,
    productName,
    setProductName,
    selectedTrade,
    setSelectedTrade,
    threshold,
    setThreshold,
    period,
    setPeriod,
    periodOptionsApiPath: PERIOD_OPTIONS_API_PATH,
    selectedPartnerCountry,
    setSelectedPartnerCountry
  };

  useContextSync(context, "trade");

  return (
    <TradePageContext.Provider value={context}>
      <MapPage
        map={map}
        initialYear={INITIAL_YEAR}
        layersConfig={layersConfig}
        onCountryCodeChange={handleCountryCodeChange}
        tabs={tabs}
        countriesApiPath={REPORTERS_COUNTRIES_API_PATH}
        canClick={false}
        initialCountryCode="EU27"
        geometryClient={geometryClient}
        disableFitBoundsOnCountryChange={true}
        openSidebarOnCountryChange={false}
      >
        <StandardLeafletMap
          setMap={setMap}
          CustomControlPanel={ControlsPanel}
          options={{ hideLegendButton: true, disableCountryClearable: true, countrySelectLabel: "Importer" }}
        >
          <Suspense fallback={<CircularProgress />}>
            <TradePaths />
          </Suspense>
          <SelectedCountryCenter />
          <MapLegendButton position="topLeft" />
        </StandardLeafletMap>
      </MapPage>
    </TradePageContext.Provider>
  );
};

export default TradePage;
