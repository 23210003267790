import { Box, IconButton } from "@mui/material";
import Footer from "layout/Footer";
import { Outlet, useLocation } from "react-router";
import GlobalNotificationsList from "./GlobalNotificationsList";
import Header from "./Header";
import React, { useRef } from "react";
import TrackPageView from "../components/reusables/EuropaAnalytics/TrackPageView";

const Layout = () => {
  const topRef = useRef<HTMLDivElement>(null);

  const executeScroll = () => topRef?.current?.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll

  const location = useLocation();

  React.useEffect(() => {
    executeScroll();
  }, [location]);

  return (
    <Box
      className="h-full w-full"
      sx={{
        overflowY: "auto",
        maxWidth: "100vw",
        overflowX: "hidden",
        scrollbarGutter: "auto"
      }}
    >
      <div style={{ width: 0, height: 0 }} ref={topRef}></div>
      <Header />
      <Box
        id="MAIN_BOX"
        component="main"
        className="flex flex-column w-full"
        sx={{
          flexGrow: 1,
          position: "relative",
          overflow: "hidden"
        }}
      >
        <TrackPageView>
          <Outlet />
        </TrackPageView>
        <GlobalNotificationsList />
      </Box>
      <Box
        sx={{
          position: "relative"
        }}
      >
        <IconButton
          size="small"
          onClick={() => executeScroll()}
          sx={{
            color: "primary.main",
            height: 30,
            width: 30,
            my: "auto",
            backgroundColor: "white",
            position: "absolute",
            bottom: 0,
            right: 10,
            display: "flex",
            justifyContent: "right"
          }}
        >
          <img alt="Return to top" src="up_icon.svg" />
        </IconButton>
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
