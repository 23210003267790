import { Accordion, Box, styled, Theme, Typography, useMediaQuery } from "@mui/material";
import { blue, greyLink, white } from "constants/colors";
import appTitle from "constants/title";
import { ReactNode, useState } from "react";
import StyledLink from "../components/reusables/StyledLink";
import { Link } from "react-router-dom";

const secondaryTextStyle = {
  fontWeight: "normal",
  fontSize: "1rem"
};

interface FooterTitleI {
  children: ReactNode;
}

const FooterTitle = ({ children }: FooterTitleI) => {
  return (
    <Typography borderBottom={"1px solid white"} maxWidth={250} mb={2} fontSize={"1.2rem"} fontWeight={500}>
      {children}
    </Typography>
  );
};

const Item1 = () => {
  return (
    <>
      <Typography fontSize={"1.2rem"} fontWeight={500}>
        {appTitle}
      </Typography>
      <hr />
      <Typography {...secondaryTextStyle}>This site is managed by the Joint Research Centre</Typography>
    </>
  );
};

const Item2 = () => {
  return (
    <>
      <FooterTitle>Contact us</FooterTitle>
      <Typography {...secondaryTextStyle}>
        <StyledLink
          href="https://commission.europa.eu/about-european-commission/departments-and-executive-agencies/joint-research-centre_en#contact"
          target="_blank"
          color={white}
          aria-label="Link to JRC contact information page(external link will be opened in a new window)"
        >
          Contact information of the DG
        </StyledLink>
        <br />
        <StyledLink
          href="mailto:jrc-forest-observatory@ec.europa.eu"
          color={white}
          aria-label="Link to jrc forest observatory email(external link will be opened in your email client)"
        >
          Contact the observatory
        </StyledLink>
      </Typography>
    </>
  );
};

const Item3 = () => {
  return (
    <>
      <FooterTitle>About us</FooterTitle>
      <Typography {...secondaryTextStyle}>
        <StyledLink
          href="https://joint-research-centre.ec.europa.eu/index_en"
          target="_blank"
          color={white}
          aria-label="Link to Joint research centre official website(external link will be opened in a new window)"
        >
          Information about the DG
        </StyledLink>
      </Typography>
    </>
  );
};

const Item4 = () => {
  const LinkFooter = styled(Link)`
    color: #ccc;
    text-decoration: none;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  `;

  return (
    <>
      <Typography {...secondaryTextStyle}>
        <StyledLink
          href="https://ec.europa.eu/info/about-european-commission/contact_en"
          target="_blank"
          color={white}
          aria-label="Link to EU commission contact information page(external link will be opened in a new window)"
        >
          Contact the European Commission
        </StyledLink>
      </Typography>
      <Typography {...secondaryTextStyle}>
        <LinkFooter to="/accessibility" aria-label="Link to accessibility declaration page">
          Accessibility
        </LinkFooter>
      </Typography>
      <Typography {...secondaryTextStyle}>
        <StyledLink
          href="https://european-union.europa.eu/contact-eu/social-media-channels_en#/search?page=0&institutions=european_commission"
          target="_blank"
          color={white}
          aria-label="Link to EU commission social media page(external link will be opened in a new window)"
        >
          Follow the European Commission on social media
        </StyledLink>
      </Typography>
      <Typography {...secondaryTextStyle}>
        <StyledLink
          href="https://commission.europa.eu/resources-partners_en"
          target="_blank"
          color={white}
          aria-label="Link to EU commission partners page(external link will be opened in a new window)"
        >
          Resources for partners
        </StyledLink>
      </Typography>
    </>
  );
};

const Item5 = () => {
  return (
    <>
      <Typography {...secondaryTextStyle}>
        <StyledLink
          href="https://ec.europa.eu/info/cookies_en"
          target="_blank"
          color={white}
          aria-label="Link to EU commission cookies page(external link will be opened in a new window)"
        >
          Cookies
        </StyledLink>
      </Typography>
      <Typography {...secondaryTextStyle}>
        <StyledLink
          href="https://ec.europa.eu/info/privacy-policy_en"
          target="_blank"
          color={white}
          aria-label="Link to EU commission privacy policy page(external link will be opened in a new window)"
        >
          Privacy policy
        </StyledLink>
      </Typography>
      <Typography {...secondaryTextStyle}>
        <StyledLink
          href="https://ec.europa.eu/info/legal-notice_en"
          target="_blank"
          color={white}
          aria-label="Link to EU commission legal notice page(external link will be opened in a new window)"
        >
          Legal notice
        </StyledLink>
      </Typography>
    </>
  );
};

const MobileFooter = () => {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Item1 />
      <hr />
      <Box sx={{ maxWidth: 250 }}>
        <Item2 />
        <br />
        <Item3 />
        <br />
        <img src="/images/EC_logo_horizontal_neg.svg" style={{ maxWidth: 200 }} alt="European Community logo" />
        <hr />
        <br />
        <Item4 />
        <br />
        <Item5 />
      </Box>
    </Box>
  );
};

const DesktopFooter = () => {
  return (
    <Box>
      <Box className="flex" py={2}>
        <Box className="flex-grow p-5" flexBasis="33%">
          <Item1 />
        </Box>
        <Box className="flex-grow p-5" flexBasis="33%">
          <Item2 />
        </Box>
        <Box className="flex-grow p-5" flexBasis="33%">
          <Item3 />
        </Box>
      </Box>
      <hr style={{ borderColor: "white" }} />
      <Box className="flex" py={2}>
        <Box className="flex-grow" flexBasis="33%">
          <img src="/images/EC_logo_horizontal_neg.svg" style={{ maxWidth: 200 }} alt="European Community logo" />
        </Box>
        <Box className="flex-grow" flexBasis="33%">
          <Item4 />
        </Box>
        <Box className="flex-grow" flexBasis="33%">
          <Item5 />
        </Box>
      </Box>
    </Box>
  );
};

const Footer = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box sx={{ bgcolor: blue, minHeight: 100, p: 3, color: "white" }}>
      {isSmallScreen && <MobileFooter />}
      {!isSmallScreen && <DesktopFooter />}
    </Box>
  );
};

export default Footer;
