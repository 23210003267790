export interface ApiMappingItem {
  title: string;
  description?: string;
  color?: string;
}

export type ApiMapping = Record<string, ApiMappingItem>;

export const apiFieldsMappingTMFStatistics: ApiMapping = {
  country_name: { title: "Country Name" },
  country_code: { title: "Country Code" },
  year: { title: "Year" },
  total: {
    color: "#fc8d59",
    title: "Total area"
  }
};

export const apiFieldsMappingDEGStatistics: ApiMapping = {
  ...apiFieldsMappingTMFStatistics,
  total: {
    title: "Total area",
    description: "Degradation "
  }
};

export const apiFieldsMappingDEFStatistics: ApiMapping = {
  ...apiFieldsMappingTMFStatistics,
  total: {
    title: "Total area",
    description: "Deforestation "
  }
};

export const apiFieldsMappingACS_MCStatistics: ApiMapping = {
  ...apiFieldsMappingTMFStatistics,
  undisturbed_TMF: {
    title: "Undisturbed ",
    color: "#005a00"
  },
  degraded_TMF: {
    title: "Degradation ",
    color: "#649b23"
  },

  deforested_Land: {
    title: "Deforestation ",
    color: "#ff870f"
  },

  forest_Regrowth: {
    title: "Regrouth ",
    color: "#d2fa3c"
  },
  water: {
    title: "Water "
  },
  other_LC: {
    title: "Other land cover "
  },
  deforested_Land_Plantations: {
    title: "Deforested Land - Forest converted to tree plantations",
    color: "#ffc894"
  },
  deforested_Land_Water: {
    title: "Deforested Land - Forest converted to water",
    color: "#00c896"
  },

  deforested_Land_OtherLC: {
    title: "Deforested Land - Forest converted to other land cover",
    color: "#ffe664"
  },

  ongoing_Disturbance: {
    title: "Ongoing deforestation or degradation",
    color: "#fa8c0a"
  }
};

export const apiFieldsMappingACStatistics: ApiMapping = {
  ...apiFieldsMappingTMFStatistics,

  total_Deforestation: {
    title: "Total deforestation (direct or after degradation)",
    color: "#ff0000"
  },
  total_Direct_Deforestation: {
    title: "Direct deforestation",
    color: "#c80a0a"
  },
  forest_Conversion_Plantation: {
    title: "Forest conversion to plantation",
    color: "#ffb6c0"
  },
  forest_Conversion_Water: {
    title: "Deforestation to water",
    color: "#228dfa"
  },

  deforestation_After_Degradation: {
    title: "Deforestation after degradation",
    color: "#ff6347"
  },
  degradation_Followed_By_Deforestation: {
    title: "Degradation followed by a deforestation",
    color: "#ffb400"
  },

  degradation_Not_Followed_By_Deforestation: {
    title: "Degradation not followed by a deforestation ",
    color: "#fff500"
  }
  /*
  undisturbed_Forest: {
    title: "Undisturbed ",
    color: "#005a00"
  },
  total_Forest_Disturbances: {
    title: "Degradation ",
    color: "#649b23"
  },

  total_Degradation: {
    title: "Deforestation ",
    color: "#ff870f"
  },

  direct_Deforestation_Exc_Plantation_Water: {
    title: "Ongoing deforestation or degradation",
    color: "#fa8c0a"
  }
  
 */
};
