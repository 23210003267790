import WebtoolsComponent, { defaultHighchartsData } from "components/charts/WebtoolsComponent";
import { Options, SeriesColumnOptions, SeriesOptions } from "highcharts";
import { set } from "lodash";
import { blue, green, red } from "@mui/material/colors";
import cloneDeep from "lodash/cloneDeep";

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;

const defaultData: Options = {
  ...defaultHighchartsData,
  chart: {
    type: "column"
  },
  tooltip: {
    pointFormat: "<b>{series.name}</b>: {point.y:.3f} {point.unit}",
    shadow: false,
    borderWidth: 2,
    borderRadius: 0,
    style: {
      color: "#404040"
    }
  },
  xAxis: {
    title: {
      text: ""
    },
    labels: {
      style: {
        color: "#404040"
      }
    }
  },

  yAxis: {
    title: {
      style: {
        color: "#404040"
      }
    }
  },
  plotOptions: {
    column: {
      stacking: "normal",
      borderWidth: 0
    }
  },

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: MAX_WIDTH,
          maxHeight: MAX_HEIGHT
        }
      }
    ]
  }
};

type Props = {
  data: SeriesColumnOptions["data"];
  title?: string;
  categories?: string[];
  yAxisTitle?: string;
};

const StackedColumnChart = (props: Props) => {
  const { title, data, categories, yAxisTitle } = props;

  let merged = cloneDeep(defaultData);
  if (categories) merged = set(merged, "xAxis.categories", categories);
  if (yAxisTitle) merged = set(merged, "yAxis.title.text", yAxisTitle);

  merged = set(merged, "title.text", title);
  merged = set(merged, "series", data);

  return <WebtoolsComponent id={`stacked_column_${title}`} data={merged} />;
};

export default StackedColumnChart;
