import { Box, Button, Typography } from "@mui/material";
import { darkGreen } from "../../constants/colors";
import { ReactNode } from "react";

type DocumentLink = {
  title: string;
  actionUrl: string;
  actionText: string;
};

export type DocumentConfig = {
  title: string;
  description: string | ReactNode;
  links: DocumentLink[];
};

interface DocumentsTabPropsI {
  documents: DocumentConfig[];
}

interface DocumentsGroupPropsI {
  document: DocumentConfig;
}

const DocumentsGroup = (props: DocumentsGroupPropsI) => {
  const { title, description, links } = props.document;

  return (
    <Box sx={{ pb: 3 }}>
      <Typography fontWeight={500} fontSize={20} color="#444" gutterBottom>
        {title}
      </Typography>
      <Typography fontSize={16} fontWeight={400} color={darkGreen} sx={{ whiteSpace: "pre-line" }}>
        {description}
      </Typography>
      <br />
      <Box className="flex flex-column gap-10">
        {links.map((i, index) => (
          <Box key={index} className="flex justify-between items-center" sx={{ border: "1px solid black", p: 2 }}>
            <Typography>{i.title}</Typography>
            <a href={i.actionUrl} target="_blank">
              <Button variant="contained" sx={{ backgroundColor: "yellow" }}>
                {i.actionText}
              </Button>
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const DocumentsTab = (props: DocumentsTabPropsI) => {
  return (
    <Box sx={{ p: 2 }}>
      {props.documents.map((i, index) => (
        <DocumentsGroup document={i} key={index} />
      ))}
    </Box>
  );
};

export default DocumentsTab;
