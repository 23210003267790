import "styles/charts.css";

import { memo, useEffect, useRef } from "react";
import { Options } from "highcharts";
import { mergeDeep } from "utils/utils";

interface WebtoolsComponentPropsI {
  id: string;
  data: Options;

  plugins?: string[];
}

export interface WebtoolsDataI {
  service: string;
  version: string;
  provider: string;
  lang: string;
  data: Options;
  plugins?: string[];
}

export const defaultHighchartsData: Options = {
  chart: {
    style: {
      fontFamily: "EC Square Sans Pro Condensed",
      fontSize: "1rem"
    }
  },
  exporting: {
    fallbackToExportServer: true
  },
  legend: { itemStyle: { fontWeight: "bold" } },
  title: {
    align: "center",
    useHTML: true
  },
  tooltip: {
    shadow: false,
    borderWidth: 2,
    borderRadius: 0
  },
  plotOptions: {
    series: { dataLabels: { style: { fontWeight: "bold" } } },
    column: { colorByPoint: true }
  },
  credits: {
    enabled: false
  }
};

const webtoolsDefaults: WebtoolsDataI = {
  service: "charts",
  version: "2.0",
  provider: "highcharts",
  lang: "en",
  plugins: [],
  data: defaultHighchartsData
};

const WebtoolsComponent = (props: WebtoolsComponentPropsI) => {
  const { id, data, plugins } = props;

  const ref = useRef(null);

  const renderChart = () => {
    if (!ref.current) return;
    if (!window.$wt) return;
    const params = { ...webtoolsDefaults };
    if (plugins) params.plugins = [...plugins];

    params.data = { ...data };

    const newParams = { ...params };

    if (window.$wt) {
      window.$wt.render(ref.current, newParams);
    }
  };

  useEffect(() => {
    renderChart();
  }, [data, window.$wt]);

  return <div id={"chartId"} ref={ref} style={{ minWidth: 200, minHeight: 200 }}></div>;
};

const MemoizedComponent = memo(WebtoolsComponent);

export default MemoizedComponent;
