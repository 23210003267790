import { useContext } from "react";
import { Place } from "@mui/icons-material";

import CountrySelect from "../CountrySelect";
import Control from "./LeafletCustomControl";
import { MapPageContext } from "../MapPage";
import MapButton from "components/reusables/MapComponents/MapButton";

interface CountrySelectButtonPropsI {
  disableClearable?: boolean;
  label?: string;
}

const CountrySelectButton = (props: CountrySelectButtonPropsI) => {
  const { setSelectedCountryCode } = useContext(MapPageContext);

  const handleCountrySelect = (countryCode: string) => setSelectedCountryCode(countryCode);

  const label = props.label || "Country";

  return (
    <Control position="topRight" style={{ borderRadius: 5 }}>
      <MapButton
        tooltip="Country"
        anchorposition={{ horizontal: "left" }}
        popupWrapperStyle={{ paddingLeft: 15, paddingRight: 15, paddingTop: 0, paddingBottom: 4 }}
        containerStyle={{ width: 300 }}
        popupContent={
          <CountrySelect onSelect={handleCountrySelect} disableClearable={props.disableClearable} label={label} />
        }
      >
        <Place />
      </MapButton>
    </Control>
  );
};

export default CountrySelectButton;
