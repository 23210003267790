import React, { useContext } from "react";
import { FormControlLabel, Typography } from "@mui/material";
import { LayerConfigI } from "../../../../types";
import { MapPageContext } from "../../MapPage";
import CustomCheckbox from "components/reusables/CustomCheckbox";
import { Tv } from "@mui/icons-material";

interface SimpleLayerPropsI {
  layerConfig: LayerConfigI;
}

const SimpleLayer = ({ layerConfig }: SimpleLayerPropsI) => {
  const { selectedLayers, setSelectedLayers, onlyOneLayerVisible, setSelectedYear } = useContext(MapPageContext);

  const { id, title } = layerConfig;

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedIndex = selectedLayers.findIndex((i) => i.id === e.target.name);
    if (selectedIndex === -1) {
      if (onlyOneLayerVisible) setSelectedLayers([layerConfig]);
      else {
        const newLayers = [...selectedLayers];
        newLayers.push(layerConfig);
      }

      if (layerConfig.year) setSelectedYear(layerConfig.year);
    } else {
      const newLayers = [...selectedLayers];
      newLayers.splice(selectedIndex, 1);
      setSelectedLayers(newLayers);
    }
  };

  const isChecked = !!selectedLayers.find((i) => i.id === id);

  return (
    <FormControlLabel
      key={id}
      control={<CustomCheckbox name={id} checked={isChecked} onChange={handleToggle} size={"small"} />}
      label={isChecked ? <Typography style={{ fontWeight: "500" }}>{title}</Typography> : title}
    />
  );
};

export default SimpleLayer;
