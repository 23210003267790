import { GeoServerClient } from "utils/ogcClients";
import { GetMapParametersI, LayerConfigI, LayerDefinitionI, LayerParamsI } from "types";
import { getLayersConfig, handleLayersName, makeCommonConfig, MakeConfigParamsI } from "utils/layerConfigUtils";
import { ComPrdCategoryType } from "constants/layersCategories";
import { useContext } from "react";
import { ProductTreeSelectContext } from "./ProductTreeSelect";
import { MapPageContext } from "../../reusables/MapPage";

const geoServerClient = new GeoServerClient();
export const defaultLayer = "prd";

export const layersDefinitions: LayerDefinitionI[] = [
  {
    type: "layer",
    name: "prd",
    layerName: "production_average",
    title: "Production quantity",
    defaultSelected: true
  },
  {
    type: "layer",
    name: "prd_area",
    layerName: "production_area_average",
    title: "Area harvested"
  },
  {
    type: "layer",
    name: "yld",
    layerName: "production_yield_average",
    title: "Yield"
  }
];

const getViewParams = (params: LayerParamsI) => {
  const { selectedLayers } = useContext(MapPageContext);
  const { product, period } = useContext(ProductTreeSelectContext);
  if (product && product.product_code) params.viewParams = "product:" + product.product_code + ";";
  if (period && period) params.viewParams += "period:" + period + ";";

  const layerName = layersDefinitions
    .filter((layer) => layer.name === selectedLayers[0].id)
    .map((layer: any) => layer["layerName"]);
  if (layerName && product) {
    params.styles = layerName + "_" + product.product_code;
    selectedLayers[0].style = layerName + "_" + product.product_code;
  }
};

const makeConfig = (props: MakeConfigParamsI): LayerConfigI => {
  const { definition, year } = props;
  const { name } = definition;

  const params: GetMapParametersI = {
    layers: handleLayersName(definition, year),
    ...geoServerClient.WMSParams
  };

  return {
    ...makeCommonConfig(props, geoServerClient),
    params,
    comPrdCategory: name as ComPrdCategoryType,
    functionToUse: getViewParams
  };
};

export const layersConfig = getLayersConfig(layersDefinitions, makeConfig);
