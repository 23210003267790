import { Box, Button, ButtonProps, styled, Tooltip } from "@mui/material";
import { CSSProperties, MouseEventHandler, ReactNode, useState } from "react";

interface MapButtonPropsI extends ButtonProps {
  tooltip: string;
  popupContent?: ReactNode;
  anchorposition?: {
    horizontal?: "left" | "right";
    vertical?: "top" | "bottom";
  };
  popupWrapperStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  isactive?: boolean;
  innerpadding?: number;
}

type StyledButtonProps = Omit<MapButtonPropsI, "tooltip" | "popupContent">;

const activeColor = "#0033FF";
const hoverColor = "#6383ff";
const defaultColor = "#4D4D4D";
const disabledColor = "#cfcfcf";

const getBackgroundColor = ({ isactive, disabled }: StyledButtonProps) => {
  let backgroundColor = defaultColor;
  if (isactive) backgroundColor = activeColor;
  if (disabled) backgroundColor = disabledColor;

  return backgroundColor;
};

const StyledMapButton = styled(Button)<StyledButtonProps>`
  height: 30px;
  width: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  color: white;
  padding: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getBackgroundColor};

  &:hover {
    background-color: ${hoverColor};
  }

  .map-button-inner-circle {
    border: 2px solid white;
    border-radius: 50%;
    height: 26px;
    max-width: 26px;
    min-width: 26px;
    padding: ${({ innerpadding }) => innerpadding || 4}px;
    display: flex;
    align-items: center;
    margin: auto;
  }

  svg {
    height: 100%;
    width: 100%;
  }
`;

const BORDER_RADIUS_PX = 6;

const WhiteTransparentBackground = () => (
  <Box
    sx={{
      bgcolor: "white",
      opacity: 0.8,
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: `${BORDER_RADIUS_PX}px`,
      zIndex: -1
    }}
  />
);

const defaultAnchorPosition: Required<MapButtonPropsI["anchorposition"]> = {
  horizontal: "right",
  vertical: "bottom"
};

const styleByVerticalPosition = {
  bottom: { top: 0 },
  top: { bottom: 0 }
};

const styleByHorizontalPosition = {
  left: { right: 40 },
  right: { left: 40 }
};

const MapButton = (props: MapButtonPropsI) => {
  const { tooltip, children, isactive, popupContent, popupWrapperStyle = {}, containerStyle, onClick, ...rest } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle: MouseEventHandler = () => setIsOpen(!isOpen);

  let handleClick = popupContent ? handleToggle : undefined;

  if (onClick) handleClick = onClick;

  const anchorposition = { ...defaultAnchorPosition, ...(props.anchorposition || {}) };

  const verticalStyle = styleByVerticalPosition[anchorposition.vertical];
  const horizontalStyle = styleByHorizontalPosition[anchorposition.horizontal];
  return (
    <Tooltip placement="top" title={tooltip} sx={{ position: "relative" }}>
      <Box>
        <StyledMapButton {...rest} onClick={handleClick}>
          <div className="map-button-inner-circle">{children}</div>
        </StyledMapButton>
        {popupContent && isOpen && (
          <div
            style={{
              position: "absolute",
              borderRadius: BORDER_RADIUS_PX,
              overflow: "hidden",
              minWidth: 100,
              maxWidth: 300,
              border: "1px solid grey",
              ...containerStyle,
              ...verticalStyle,
              ...horizontalStyle
            }}
          >
            <WhiteTransparentBackground />
            <Box style={{ padding: 8, overflow: "auto", ...popupWrapperStyle }}>{popupContent}</Box>
          </div>
        )}
      </Box>
    </Tooltip>
  );
};

export default MapButton;
