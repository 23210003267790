import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { LatLngTuple } from "leaflet";
import { sumBy } from "utils/utils";
import { CountryOptionI, getCountries, getTrades } from "utils/apiRequests";
import { TradePathInfoI, TradesAverageResponseItemI } from "types";
import { MapPageContext } from "components/reusables/MapPage";
import {
  REPORTERS_COUNTRIES_API_PATH,
  TradePageContext,
  TradePageContextI
} from "components/pages/CommoditiesTradePage";
import { GlobalContext } from "App";
import TradePath from "components/pages/CommoditiesTradePage/TradePath";

const EU27CenterLatLng = [50, 8];

interface TradeIdParamsI {
  period: string;
  toCountryCode: string;
  fromCountryCode: string;
  productCode: string;
}

export const buildTradeId = (params: TradeIdParamsI) => {
  const { period, toCountryCode, fromCountryCode, productCode } = params;
  return `${period}_${toCountryCode}_${fromCountryCode}_${productCode}`;
};

const processTradesData = (trades: TradesAverageResponseItemI[], threshold: number) => {
  // trades come already sorted by avg_value desc
  const total = sumBy("avg_value", trades);
  const thresholdAmount = (total * threshold) / 100;
  const filtered: TradesAverageResponseItemI[] = [];
  let partial = 0;

  for (const trade of trades) {
    partial = partial + trade.avg_value;
    filtered.push(trade);
    if (partial > thresholdAmount) {
      break;
    }
  }

  return filtered;
};

const buildTradePathInfo = (trade: TradesAverageResponseItemI, reporterCountry: CountryOptionI) => {
  const isEu = reporterCountry.country_code === "EU27";
  const {
    partner_center_lat,
    partner_center_lng,
    partner_name,
    reporter_code,
    product_code,
    partner_code,
    unit,
    avg_value,
    period
  } = trade;

  const id = buildTradeId({
    period,
    fromCountryCode: partner_code,
    toCountryCode: reporter_code,
    productCode: product_code.toString()
  });

  const reporterCountryCenter = [reporterCountry.center_lat, reporterCountry.center_lng];
  const toCenter = isEu ? EU27CenterLatLng : reporterCountryCenter;

  const obj: TradePathInfoI = {
    id,
    fromLatLng: [partner_center_lat, partner_center_lng] as LatLngTuple,
    fromCountryName: partner_name,
    fromCountryCode: partner_code,
    toLatLng: toCenter as LatLngTuple,
    toCountryName: isEu ? "European Union" : reporterCountry.country_name,
    toCountryCode: reporterCountry.country_code,
    productCode: product_code.toString(),
    quantity: avg_value,
    unit,
    category: "Combined",
    period
  };

  return obj;
};

// If on new trade data, a trade is found, it is automatically selected.
const handlePartnerCountryPersist = (
  trades: TradesAverageResponseItemI[],
  tradePageContext: TradePageContextI,
  reporterCountry: CountryOptionI
) => {
  const { selectedTrade, setSelectedTrade, selectedPartnerCountry, setSelectedPartnerCountry } = tradePageContext;
  if (!trades || trades.length === 0) return;
  const foundTrade = trades?.find((i) => i.partner_code === selectedPartnerCountry?.country_code);
  if (foundTrade) {
    const tradePathInfo = buildTradePathInfo(foundTrade, reporterCountry);
    if (selectedTrade?.id !== tradePathInfo.id) setSelectedTrade(tradePathInfo);
  } else {
    if (selectedPartnerCountry !== undefined) setSelectedPartnerCountry(undefined);
    if (selectedTrade !== undefined) setSelectedTrade(undefined);
  }
};

const TradePaths = () => {
  const context = useContext(MapPageContext);
  const { selectedCountryCode } = context;
  const tradePageContext = useContext(TradePageContext);
  const {
    product,
    selectedTrade,
    setSelectedTrade,
    threshold,
    setSelectedPartnerCountry,
    selectedPartnerCountry,
    period,
    layer
  } = tradePageContext;
  const { pushNotification } = useContext(GlobalContext);

  const { data: countries } = useQuery(["countries", REPORTERS_COUNTRIES_API_PATH], getCountries);

  const { data: trades, isLoading } = useQuery(["trades", selectedCountryCode, period, product, layer], getTrades, {
    staleTime: Infinity
  });

  const handleTradeSelect = (trade?: TradePathInfoI) => {
    const found = countries?.find((i) => i.country_code === trade?.fromCountryCode);
    setSelectedPartnerCountry(found);
    setSelectedTrade(trade);
  };

  const processedTrades = processTradesData(trades || [], threshold);

  const hasTrades = trades && Object.keys(trades).length > 0;

  useEffect(() => {
    const foundReporter = countries?.find((i) => i.country_code === selectedCountryCode);
    if (!foundReporter) return;
    if (!processedTrades) return;
    handlePartnerCountryPersist(processedTrades, tradePageContext, foundReporter);
  }, [processedTrades, selectedPartnerCountry]);

  useEffect(() => {
    if (!hasTrades && !isLoading) {
      pushNotification({
        type: "trade-data-not-available",
        severity: "warning",
        timeout: 4000,
        message: "No trade data available for the selected filters!",
        width: 450
      });
    }
  }, [trades]);

  if (!hasTrades) return null;
  if (!countries) return null;
  const foundReporter = countries.find((i) => i.country_code === selectedCountryCode);
  if (!foundReporter) return null;

  return (
    <>
      {processedTrades.map((trade) => {
        const tradePathObj = buildTradePathInfo(trade, foundReporter);
        return (
          <TradePath
            key={tradePathObj.id}
            trade={tradePathObj}
            selectedTrade={selectedTrade}
            onSelect={handleTradeSelect}
          />
        );
      })}
    </>
  );
};

export default TradePaths;
