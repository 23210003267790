import { Box, IconButton, Typography } from "@mui/material";
import Footer from "../layout/Footer";
import Header from "../layout/Header";

import React from "react";

const NotFoundPage = () => {
  return (
    <Box
      className="h-full w-full"
      sx={{
        maxHeight: "100vh",
        overflowY: "auto",
        maxWidth: "100vw",
        overflowX: "hidden",
        scrollbarGutter: "auto"
      }}
    >
      <Header />
      <Box
        id="MAIN_BOX"
        component="main"
        className="flex flex-column w-full"
        sx={{
          flexGrow: 1,
          position: "relative",
          overflow: "hidden",
          height: "50%"
        }}
      >
        <Typography variant="h3">An error has occurred</Typography>
      </Box>
      <Box
        sx={{
          position: "relative"
        }}
      ></Box>
      <Footer />
    </Box>
  );
};

export default NotFoundPage;
