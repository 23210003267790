import React, { Fragment, useEffect } from "react";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { getCarbonCountryDescription } from "utils/apiRequests";
import { MapPageContext } from "components/reusables/MapPage";
import { Box, IconButton, Typography } from "@mui/material";
import Parser from "html-react-parser";

import { HelpOutline } from "@mui/icons-material";
import { darkGreen } from "../../../../constants/colors";

const CountryDescriptionInfo = () => {
  const [showDescription1, setShowDescription1] = useState(false);
  const { selectedCountryCode } = useContext(MapPageContext);

  useEffect(() => {
    setShowDescription1(false);
  }, [selectedCountryCode]);

  const queryOptions = {
    queryKey: ["carbonCountryDescription", selectedCountryCode],
    queryFn: getCarbonCountryDescription,
    staleTime: Infinity,
    enabled: !!selectedCountryCode && selectedCountryCode !== ""
  };

  const { data, isLoading, error } = useQuery(queryOptions);

  const handleInfoClick = () => setShowDescription1(!showDescription1);

  if (!selectedCountryCode || selectedCountryCode === "") return null;

  if (isLoading) return <>loading...</>;
  if (!data || error) return <></>;

  return (
    <Box style={{ padding: "5px", textAlign: "justify" }} sx={{ fontSize: "1rem" }}>
      {data && data.shortDescription && (
        <Fragment>
          <b>Data source</b>
          <Box color={darkGreen}>
            {Parser(data.shortDescription)}{" "}
            {data.moreInfo && (
              <IconButton sx={{ padding: 0, margin: 0 }} onClick={handleInfoClick}>
                <HelpOutline htmlColor="#aaa" />
              </IconButton>
            )}
            {data.moreInfo && showDescription1 && <Fragment> {Parser(data.moreInfo)} </Fragment>}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default CountryDescriptionInfo;
