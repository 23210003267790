import { GfaCategoryType } from "../../../constants/layersCategories";
import { MapServerClient } from "utils/ogcClients";
import { GetMapParametersI, LayerConfigI, LayerDefinitionI } from "../../../types";

import {
  getLayersConfig,
  handleLayersName,
  makeCommonConfig,
  MakeConfigParamsI
} from "../../../utils/layerConfigUtils";

const mapFilePrefix = "GFA";
const mapServerClient = new MapServerClient(mapFilePrefix);

export const layersDefinitions: LayerDefinitionI[] = [
  {
    type: "layer",
    name: "forest",
    layerName: "GFM",
    title: "Forest Mask 2019",
    defaultSelected: true,
    description: ""
  },
  {
    type: "layer",
    name: "fragmentation",
    layerName: "GFF",
    title: "Forest Fragmentation 2019"
  },
  {
    type: "layer",
    name: "accounting",
    layerName: "GFACC",
    title: "Forest Accounting 2019"
  },
  {
    type: "layer",
    name: "pattern",
    layerName: "GFP",
    title: "Forest Pattern 2019"
  }
];

const makeConfig = (props: MakeConfigParamsI): LayerConfigI => {
  const { definition, year } = props;
  const { name, mapFile } = definition;

  const mapFileName = mapFile ? mapFile : year ? `${mapFilePrefix}${year}` : mapFilePrefix;

  const params: GetMapParametersI = {
    layers: handleLayersName(definition, year),
    ...mapServerClient.WMSParams
  };

  return {
    ...makeCommonConfig(props, mapServerClient),
    params,
    gfaCategory: name as GfaCategoryType
  };
};

export const layersConfig = getLayersConfig(layersDefinitions, makeConfig);
