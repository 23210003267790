import WebtoolsComponent, { defaultHighchartsData } from "components/charts/WebtoolsComponent";
import { Options, SeriesAreaOptions } from "highcharts";
import { set } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { pointFormatter2 } from "../../utils/chartsUtils";

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;

const defaultData: Options = {
  ...defaultHighchartsData,
  chart: {
    type: "area"
  },
  tooltip: {
    pointFormatter: pointFormatter2,
    shadow: false,
    borderWidth: 2,
    borderRadius: 0,
    style: {
      color: "#404040"
    }
  },
  xAxis: {
    title: {
      text: "year"
    },
    labels: {
      style: {
        color: "#404040"
      }
    }
  },
  yAxis: {
    title: {
      style: {
        color: "#404040"
      }
    }
  },
  plotOptions: {
    area: {
      stacking: "normal",
      lineWidth: 1
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: MAX_WIDTH,
          maxHeight: MAX_HEIGHT
        }
      }
    ]
  }
};

type Props = {
  series: SeriesAreaOptions[];
  title: string;
  yAxisTitle?: string;
};

const StackedAreaChart = (props: Props) => {
  const { title, series, yAxisTitle } = props;

  let merged = cloneDeep(defaultData);

  if (yAxisTitle) merged = set(merged, "yAxis.title.text", yAxisTitle);

  merged = set(merged, "title.text", title);
  merged = set(merged, "series", series);

  return <WebtoolsComponent id={`stacked_area_${title}`} data={merged} />;
};

export default StackedAreaChart;
