import { Box } from "@mui/material";
import { useContext } from "react";

import { MapPageContext } from "components/reusables/MapPage";
import { apiFieldsMappingHarmonizedStatistics } from "./utils/apiFieldsMapping";
//import ColumnChart from "components/charts/ColumnChart";
import { makeData } from "utils/chartsUtils";
import { harmonizedDataKeys } from "./utils/keysGroups";
import PieChart from "components/charts/PieChart";
import WithLegend from "components/reusables/WithLegend";
import chartsLegends from "./chartsLegends";
import { ghmCategories } from "../../../../constants/layersCategories";

const dataKeysByCategory = {
  harmonized: harmonizedDataKeys
};
const NoLayerSelectedMessage = () => {
  return <Box sx={{ padding: 1 }}>SELECT A LAYER TO SEE THE DATA</Box>;
};

const LayerCharts = () => {
  const { selectedLayers, selectedCountryData, selectedYear } = useContext(MapPageContext);

  const dataNew =
    selectedCountryData instanceof Array
      ? selectedCountryData.filter((item: any) => item.year === selectedYear)
      : undefined;

  if (!dataNew || dataNew.length !== 1) return null;

  if (selectedLayers?.length === 0) return <NoLayerSelectedMessage />;
  const selectedCategory = ghmCategories[0]; //selectedLayers[0]?.ghmCategory; // should accept layer parameter

  const dataH = makeData(dataNew[0], dataKeysByCategory["harmonized"], apiFieldsMappingHarmonizedStatistics, "ha");
  const legend = chartsLegends[selectedCategory];
  const titleH = "Forest cover change drivers" + (selectedLayers[0].year ? " in " + selectedLayers[0].year : ""); //

  return (
    <>
      <WithLegend legend={legend}>
        <PieChart data={dataH} title={titleH} />
      </WithLegend>
    </>
  );
};

export default LayerCharts;
