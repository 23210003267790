import { useContext } from "react";
import { Circle, CircleMarker, Pane } from "react-leaflet";
import { useQuery } from "react-query";
import { MapPageContext } from "components/reusables/MapPage";
import { getCountries } from "utils/apiRequests";
import { REPORTERS_COUNTRIES_API_PATH } from "components/pages/CommoditiesTradePage";

const SelectedCountryCenter = () => {
  const { selectedCountryCode, map } = useContext(MapPageContext);
  const { data } = useQuery(["countries", REPORTERS_COUNTRIES_API_PATH], getCountries);
  const foundCountry = data?.find((i) => i.country_code === selectedCountryCode);
  const { center_lat, center_lng } = foundCountry || {};

  if (!center_lat || !center_lng) return null;

  return (
    <>
      <Pane name="selectedCountryCenter" style={{ zIndex: 1000 }}>
        <CircleMarker center={[center_lat, center_lng]} radius={15} />
        <CircleMarker center={[center_lat, center_lng]} radius={3} fillOpacity={1} />
      </Pane>
    </>
  );
};

export default SelectedCountryCenter;
