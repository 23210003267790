import { Box, Typography } from "@mui/material";
import { TradePageContext } from "components/pages/CommoditiesTradePage";
import AnnualVariation from "components/pages/CommoditiesTradePage/AnnualVariation";
import TradedCommodities from "components/pages/CommoditiesTradePage/TradedCommodities";

import { MapPageContext } from "components/reusables/MapPage";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { getTrades } from "utils/apiRequests";
import UnitSelectDropdown from "./UnitSelectDropdown";
import { getCountryName } from "components/reusables/CountryLayerTitle";

const SidebarContent = () => {
  const { selectedCountryCode } = useContext(MapPageContext);
  const { product, selectedTrade, period, layer } = useContext(TradePageContext);
  const { quantity, unit } = selectedTrade || {};
  const [annualVariationType, setAnnualVariationType] = useState<string | undefined>("quantity");

  const query = useQuery(["trades", selectedCountryCode, period, product, layer], getTrades, {
    staleTime: Infinity
  });

  const { data: trades } = query;

  const selectedCountryName = getCountryName(useContext(MapPageContext));

  if (!trades) return null;
  const hasTrades = trades?.length > 0;

  if (!hasTrades) return null;

  const selectType = (
    <UnitSelectDropdown
      value={annualVariationType}
      onChange={(newValue) => setAnnualVariationType(newValue)}
      dataSource={layer}
    />
  );
  return (
    <>
      <Box className="w-full" sx={{ p: 2 }}>
        {!selectedTrade && (
          <>
            <TradedCommodities toCountryName={selectedCountryName} toCountryCode={selectedCountryCode} />
            <br />
            {selectedCountryCode === "EU27" && (
              <>
                <br />
                <TradedCommodities toCountryName={"Rest of the world"} toCountryCode={"ROW"} />
              </>
            )}
          </>
        )}

        {selectedTrade && (
          <>
            <Typography sx={{ alignItems: "center", display: "flex", gap: 1 }}>
              The trade average imported quantity in {period} was {quantity?.toLocaleString()} {quantity && unit}.
            </Typography>
            <br />
            {selectType}
            <br />
            <AnnualVariation dataSource={layer} type={annualVariationType} />
            <br />
            <TradedCommodities
              toCountryName={selectedTrade.toCountryName}
              toCountryCode={selectedTrade.toCountryCode}
            />
            <br />
            <TradedCommodities
              toCountryName={selectedTrade.fromCountryName}
              toCountryCode={selectedTrade.fromCountryCode}
              importer={true}
            />
            <br />
          </>
        )}
      </Box>
    </>
  );
};

export default SidebarContent;
