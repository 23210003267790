import React, { useContext, useState } from "react";

import Control, { ControlPositionType } from "./LeafletCustomControl";
import { MapPageContext } from "../MapPage";
import { LeafletMouseEvent } from "leaflet";
import { Grid, Box } from "@mui/material";

type Props = {
  position: ControlPositionType;
};

const CursorCoordinatesControl = (props: Props) => {
  const { map } = useContext(MapPageContext);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const { lat, lng } = coordinates;

  React.useEffect(() => {
    const listener = (e: LeafletMouseEvent) => {
      setCoordinates({ lat: e.latlng.lat, lng: e.latlng.lng });
    };

    map?.addEventListener("mousemove", listener);
    return () => {
      map?.removeEventListener("mousemove", listener);
    };
  }, [map]);

  return (
    <Control position={props.position} style={{ border: "unset" }}>
      <Box sx={{ bgcolor: "#ffffff9c", minWidth: 160, maxWidth: 160, textAlign: "center", borderRadius: "3px" }}>
        <Grid container>
          <Grid item xs={2}>
            lat:
          </Grid>
          <Grid item xs={4}>
            {lat.toFixed(4)}
          </Grid>
          <Grid item xs={2}>
            lng:
          </Grid>
          <Grid item xs={4}>
            {lng.toFixed(4)}
          </Grid>
        </Grid>
      </Box>
    </Control>
  );
};

export default CursorCoordinatesControl;
