import { Autocomplete, Box, FormControlLabel, FormGroup, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";

import CustomCheckbox from "components/reusables/CustomCheckbox";

type Props = {
  value?: string;
  onChange?: (e: any) => void;
  onlyProductFamilies?: boolean;
  dataSource?: string;
};

const SelectDropdown = (props: Props) => {
  const { value, onChange } = props;

  const data = [
    { label: "EU27", valueD: "EU27" },
    { label: "World", valueD: "WRD" }
  ];
  return (
    <FormGroup sx={{ pl: 2 }} row>
      {data.map(({ label, valueD }, index) => {
        const isChecked = valueD === value;
        return (
          <FormControlLabel
            key={index}
            control={
              <CustomCheckbox
                name={valueD}
                checked={isChecked}
                onChange={(event) => onChange && onChange(event.target.name)}
              />
            }
            label={isChecked ? <Typography style={{ fontWeight: "500" }}>{label}</Typography> : label}
            sx={{ padding: 0, margin: 0 }}
          />
        );
      })}
    </FormGroup>
  );
};
export default SelectDropdown;
