import { geoJSON } from "leaflet";
import { GeoJSON as GeoJsonType } from "geojson";
import { useContext, useEffect, useRef } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import { MapPageContext } from "components/reusables/MapPage";

export interface SelectedCountryPolygonPropsI {
  polygon: GeoJsonType | null;
  pathColor?: string;
}

const SelectedCountryPolygon = (props: SelectedCountryPolygonPropsI) => {
  const { polygon, pathColor } = props;
  const { disableFitBoundsOnCountryChange } = useContext(MapPageContext);
  const map = useMap();

  const ref = useRef(0);

  useEffect(() => {
    if (!polygon) return;
    const bounds = geoJSON(polygon).getBounds();
    if (!bounds.isValid()) return;

    if (!disableFitBoundsOnCountryChange) {
      map.fire("fit-bounds");
      map.fitBounds(bounds);
    }
    if (ref) ref.current++;
  }, [polygon]);

  const key = polygon ? JSON.stringify(polygon) : "EMPTY";

  if (!polygon) return null;
  return <GeoJSON key={key} data={polygon} pathOptions={{ fill: false, color: pathColor || "green", opacity: 1 }} />;
};

export default SelectedCountryPolygon;
