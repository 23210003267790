import { Box, Typography } from "@mui/material";
import { useContext } from "react";

import { MapPageContext } from "../../../reusables/MapPage";
import AnnualVariationCharts from "./AnnualVariationCharts";
import CommodityChart from "./CommodityChart";
import TrendChart from "./TrendChart";

import AverageChart from "./AverageChart";

const PADDING = 3;

export const intervalColorCode = [
  "#AAF7FFBF",
  "#72D9FFBF",
  "#3FA0FFBF",
  "#264DFFBF",
  "#282AD8BF",
  "#FFFDBFBF",
  "#FFE099BF",
  "#FFAD72BF",
  "#F76D5EBF",
  "#D82632BF",
  "#A51520BF"
];

const TrendsTab = () => {
  const { selectedCountryCode } = useContext(MapPageContext);

  const Allways = () => {
    return (
      <>
        {
          <Box sx={{ padding: PADDING }}>
            <TrendChart />
          </Box>
        }
      </>
    );
  };

  return <Allways />;
};

export default TrendsTab;
