export interface ApiMappingItem {
  title: string;
  description?: string;
  color?: string;
}

export type ApiMapping = Record<string, ApiMappingItem>;

export const apiFieldsMappingHarmonizedStatistics: ApiMapping = {
  country_name: { title: "Country Name" },
  country_code: { title: "Country Code" },
  year: { title: "Year" },
  fire_in_harvest: {
    title: "Fires inside harvested areas",
    color: "#fc8d59",
    description: "Forest cover loss from fires occurring in forest harvest areas"
  },
  fire_out_harvest: {
    title: "Fires outside harvested areas",
    color: "#d7301f",
    description:
      "Forest cover loss from fires occurring outside of forest harvest areas, includes burning of vegetation with no agricultural conversion"
  },
  harvest: {
    title: "Forest harvest",
    color: "#0570B0",
    description: "Forest cover loss due to clear cut harvesting in relation to forest management"
  },
  extreme_events: {
    title: "Extreme events",
    color: "#fdcc8a",
    description: "Forest cover loss due pests, biotic disturbances or windstorms occurring in forest harvest areas"
  },
  defor: {
    title: "Deforestation/tree cover losses",
    color: "#D01C8B",
    description:
      "Deforestation mainly due to agricultural expansion and tree cover losses outside the humid tropical forest domain"
  },
  degrad: {
    title: "Other types of disturbances",
    color: "#F1B6dA",
    description:
      "Short term disturbances in tropical moist forests due to selective logging, low intensity fires or events such as hurricanes, droughts, blowdown"
  }
};
