import { createTheme, PaletteMode, responsiveFontSizes, ThemeOptions } from "@mui/material";

const lightThemeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: "#87c064",
      dark: "#678868",
      light: "#d5ecc7"
    },
    secondary: {
      light: "#5f5fc4",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff"
    },
    background: {
      paper: "#fff",
      default: "#fcfcfe"
    },
    mode: "light" as PaletteMode
  },
  shape: {
    borderRadius: 2
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        sx: { p: 0.5 },
        size: "small"
      }
    }
  },
  typography: {
    fontFamily: [
      "EC Square Sans Pro Condensed" /*,
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'*/
    ].join(","),
    h1: {
      fontWeight: 600,
      fontSize: "2.2rem !important",
      lineHeight: 1.2
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.6rem !important",
      lineHeight: 1.1
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.375rem !important",
      lineHeight: 1
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.3rem !important",
      lineHeight: 1
    },
    h5: {
      fontWeight: 400,
      fontSize: "1.2rem !important",
      lineHeight: 1
    },
    h6: {
      fontWeight: 400,
      fontSize: "1.125rem !important",
      lineHeight: 1
    }
  }
};

export const lightTheme = responsiveFontSizes(createTheme(lightThemeConfig));
