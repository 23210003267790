import { createContext, Dispatch, SetStateAction, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { lightTheme } from "./utils/themes";
import { MapPageContextI } from "components/reusables/MapPage";
import { TradePageContextI } from "components/pages/CommoditiesTradePage";
import router from "router";
import { GestureHandling } from "leaflet-gesture-handling";
import { Map } from "leaflet";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "leaflet/dist/leaflet.css";

import "./styles/leaflet.css";
import "./types";
import { ProductTreeSelectContextI } from "./components/pages/CommoditiesProductionPage/ProductTreeSelect";

Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000
    }
  }
});

interface NotificationI {
  timeout?: number;
  severity: "error" | "success" | "warning" | "info";
  type: "tileerror" | "map-service-unavailable" | "country-not-available" | "trade-data-not-available";
  message: string;
  width?: number;
}
export interface TimestampedNotificationI extends NotificationI {
  id: string;
  timestamp: string;
}

export interface GlobalContextI {
  notifications: TimestampedNotificationI[];
  pushNotification: (notification: NotificationI) => void;
  removeNotification: (notification: TimestampedNotificationI) => void;
  // pastNotifications: [];
  contexts: {
    mapPage?: MapPageContextI;
    trade?: TradePageContextI;
    production?: ProductTreeSelectContextI;
  };
  setContexts: Dispatch<SetStateAction<GlobalContextI["contexts"]>>;
}

const initialContext: GlobalContextI = {
  notifications: [],
  pushNotification: () => null,
  removeNotification: () => null,
  // pastNotifications: []
  contexts: {
    mapPage: undefined,
    trade: undefined,
    production: undefined
  },
  setContexts: () => null
};

export const GlobalContext = createContext<GlobalContextI>(initialContext);

function App() {
  const [notifications, setNotifications] = useState<TimestampedNotificationI[]>([]);
  // keep past notifications in case we want to check history
  const [pastNotifications, setPastNotifications] = useState<TimestampedNotificationI[]>([]);
  const [contexts, setContexts] = useState<GlobalContextI["contexts"]>(initialContext.contexts);
  const count = useRef(0);

  const pushNotification = (notification: NotificationI) => {
    const now = new Date().toISOString();
    const newId = count.current++;
    const newNotification = { ...notification, timestamp: now, id: newId.toString() };

    // use previous state to prevent conflict with concurrent calls
    setNotifications((previousState) => [...previousState, newNotification]);
  };

  const removeNotification = (notification: TimestampedNotificationI) => {
    // use previous state to prevent conflict with concurrent calls
    setNotifications((previousState) => {
      const newState = [...previousState];
      const foundIndex = previousState.indexOf(notification);
      const deleted = newState.splice(foundIndex, 1);
      setPastNotifications((previousPastState) => [...previousPastState, { ...deleted[0] }]);
      return newState;
    });
  };

  const context = { notifications, pushNotification, removeNotification, contexts, setContexts };

  return (
    <GlobalContext.Provider value={context}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={lightTheme}>
          <RouterProvider router={router} />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GlobalContext.Provider>
  );
}

export default App;
