interface PropsI {
  fill?: string;
}

const CFDH = (props: PropsI) => {
  return (
    <svg viewBox="0 0 577 577" xmlns="http://www.w3.org/2000/svg">
      <g>
        <title>Layer 1</title>
        <g id="svg_14">
          <path
            strokeOpacity="0"
            stroke="#280c0c"
            fillOpacity="0"
            id="svg_3"
            fill="#14010e"
            strokeWidth="0px"
            d="m524.8,288.31c0,35.33 -7.76,68.88 -21.69,98.98c-1.2,2.65 -2.43,5.22 -3.74,7.8c-2.83,5.59 -5.88,11.03 -9.14,16.32c-1.52,2.54 -3.12,5.04 -4.75,7.51c-5.37,8.09 -11.21,15.81 -17.48,23.14c-2.14,2.5 -4.32,4.93 -6.53,7.33c-11.35,12.19 -23.94,23.14 -37.58,32.72c-4.9,3.41 -9.9,6.67 -15.05,9.68c-14,8.34 -28.91,15.23 -44.58,20.53c-6.67,2.29 -13.46,4.24 -20.38,5.91c-17.81,4.28 -36.42,6.56 -55.53,6.56c-87.08,0 -163.14,-47.04 -204.17,-117.15c-3.55,-5.98 -6.82,-12.19 -9.79,-18.53c-5.55,-11.72 -10.16,-23.97 -13.75,-36.67c-1.56,-5.66 -2.94,-11.35 -4.13,-17.16c-3.05,-15.2 -4.64,-30.9 -4.64,-46.97c0,-130.57 105.87,-236.45 236.48,-236.45s236.45,105.87 236.45,236.45z"
          />
          <path
            opacity="0"
            strokeOpacity="0"
            stroke="#252047"
            id="svg_4"
            d="m524.8,288.3c0,35.33 -7.76,68.88 -21.69,98.98c-1.2,2.65 -2.43,5.22 -3.74,7.8c-2.83,5.59 -5.88,11.03 -9.14,16.32c-1.52,2.54 -3.12,5.04 -4.75,7.51c-5.37,8.09 -11.21,15.81 -17.48,23.14c-2.14,2.5 -4.32,4.93 -6.53,7.33c-11.35,12.19 -23.94,23.14 -37.58,32.72c-4.9,3.41 -9.9,6.67 -15.05,9.68c-14,8.34 -28.91,15.23 -44.58,20.53c-6.67,2.29 -13.46,4.24 -20.38,5.91c-17.81,4.28 -36.42,6.56 -55.53,6.56c-87.08,0 -163.14,-47.04 -204.17,-117.15c-3.55,-5.98 -6.82,-12.19 -9.79,-18.53c-5.55,-11.72 -10.16,-23.97 -13.75,-36.67c-1.56,-5.66 -2.94,-11.35 -4.13,-17.16c-3.05,-15.2 -4.64,-30.9 -4.64,-46.97c0,-130.57 105.87,-236.45 236.48,-236.45s236.45,105.87 236.45,236.45z"
            fill="currentColor"
          />
          <path
            style={{ fill: props.fill }}
            d="m288.35,0c-159.26,0 -288.35,129.09 -288.35,288.35s129.09,288.35 288.35,288.35s288.35,-129.12 288.35,-288.35s-129.09,-288.35 -288.35,-288.35zm211.02,395.09c-2.83,5.59 -5.88,11.03 -9.14,16.32c-1.52,2.54 -3.12,5.04 -4.75,7.51c-5.37,8.09 -11.21,15.81 -17.48,23.14c-2.14,2.5 -4.32,4.93 -6.53,7.33c-11.35,12.19 -23.94,23.14 -37.58,32.72c-4.9,3.41 -9.9,6.67 -15.05,9.68c-14,8.34 -28.91,15.23 -44.58,20.53c-6.67,2.29 -13.46,4.24 -20.38,5.91c-17.81,4.28 -36.42,6.56 -55.53,6.56c-87.08,0 -163.14,-47.04 -204.17,-117.15c-3.55,-5.98 -6.82,-12.19 -9.79,-18.53c-5.55,-11.72 -10.16,-23.97 -13.75,-36.67c-1.56,-5.66 -2.94,-11.35 -4.13,-17.16c-3.05,-15.2 -4.64,-30.9 -4.64,-46.97c0,-130.57 105.87,-236.45 236.48,-236.45s236.45,105.87 236.45,236.45c0,35.33 -7.76,68.88 -21.69,98.98c-1.2,2.65 -2.43,5.22 -3.74,7.8z"
          />
          <g id="svg_5">
            <path
              style={{ fill: props.fill }}
              d="m364.28,512.32c-6.67,2.29 -13.46,4.24 -20.38,5.91c-100.8,-77.84 -200.43,-102.79 -259.7,-110.59c-3.55,-5.98 -6.82,-12.19 -9.79,-18.53c60.83,6.46 175.48,31.12 289.87,123.21z"
            />
            <path
              style={{ fill: props.fill }}
              d="m499.38,395.1c1.31,-2.58 2.54,-5.15 3.74,-7.8c-29.31,-10.45 -97.13,-29.96 -182.37,-24.41c-14.65,-6.78 -66.01,-26.62 -137.75,-11.9c-4.64,-1.2 -9.14,-2.28 -13.49,-3.3c-55.71,-12.84 -97.13,-12.98 -112.98,-12.4c1.2,5.8 2.58,11.5 4.13,17.16c17.08,-0.36 55.68,0.58 105.73,12.19c61.3,14.25 152.59,47.88 242.47,127.16c5.15,-3.01 10.16,-6.27 15.05,-9.68c-16.21,-14.54 -32.53,-27.6 -48.67,-39.35c35.58,-3.12 66.63,1.92 86.25,6.64c2.21,-2.39 4.39,-4.82 6.53,-7.33c-21.4,-5.62 -59.27,-12.48 -103.33,-6.86c-18.39,-12.77 -36.56,-23.87 -54.3,-33.55c94.27,-7.51 155.89,10.48 175.11,17.26c1.63,-2.47 3.23,-4.97 4.75,-7.51c-18.68,-6.96 -86.29,-28.22 -193.83,-17.12c-11.06,-5.66 -21.87,-10.74 -32.46,-15.31c109.39,-21.69 200.14,3.63 235.43,16.1l-0.01,0.01zm-249.35,-21.95c-20.53,-8.27 -39.86,-14.65 -57.45,-19.55c56.8,-9.72 99.38,2.39 118.75,9.94c-19.62,1.7 -40.19,4.79 -61.3,9.61z"
            />
          </g>
          <path
            style={{ fill: props.fill }}
            d="m146,320.83l-0.48,-1.81l-1.22,-4.5l-13.24,-49.31l-1.62,-6.1l-8.96,-33.32c-0.52,-1.88 -2.16,-3.15 -4.11,-3.15s-3.58,1.27 -4.09,3.15l-25.54,95.03c-0.35,1.27 -0.07,2.62 0.74,3.67c0.81,1.05 2.03,1.66 3.34,1.66l21.96,0l0,21.39l6.55,0l0,-21.39l22.57,0c1.33,0 2.56,-0.61 3.36,-1.66c0.81,-1.05 1.07,-2.4 0.72,-3.67l0.02,0.01zm-4.08,1.45l-0.72,0.44l0.52,-0.44l-22.74,0l0,-11.46l6.26,-8.99c0.73,-1.04 0.52,-2.51 -0.46,-3.29c-0.98,-0.77 -2.36,-0.55 -3.09,0.49l-2.71,3.89l0,-5.21l6.26,-8.99c0.73,-1.04 0.52,-2.51 -0.46,-3.29c-0.98,-0.77 -2.36,-0.55 -3.09,0.49l-2.71,3.89l0,-13.08c0,-1.43 -1.38,-2.59 -3.09,-2.59s-3.09,1.16 -3.09,2.59l0,22.14l-3.19,-5.42c-0.73,-1.24 -2.31,-1.65 -3.56,-0.92c-1.24,0.73 -1.65,2.33 -0.91,3.57l7.2,12.22l0.47,0.58l0,13.37l-21.96,0l-0.33,-0.44l25.54,-95.03l0.68,0l10.88,40.57l2.03,7.49l9.24,34.43l1.62,6.03l1.75,6.51l-0.52,0.44l0.2,0l-0.02,0.01z"
          />
          <path
            style={{ fill: props.fill }}
            d="m194.13,281.16c0,-22.24 -13.98,-63.31 -33.95,-63.31c-14.31,0 -25.54,21.13 -30.74,41.27c-0.74,2.8 -1.35,5.57 -1.84,8.26l2.03,7.49c0.26,-3.1 0.76,-6.36 1.42,-9.66c4.28,-21.17 15.88,-44.28 29.12,-44.28c16.76,0 30.89,36.99 30.89,60.23c0,20.33 -12.44,37.06 -28.24,38.76l4.97,-8.42c0.51,-0.87 0.23,-1.98 -0.64,-2.49c-0.87,-0.51 -1.98,-0.23 -2.49,0.64l-2.67,4.52l0,-13.13l2.98,-4.02c0.42,-0.57 0.3,-1.37 -0.26,-1.78c-0.57,-0.42 -1.36,-0.3 -1.78,0.27l-0.93,1.25l0,-2.27l2.98,-4.02c0.42,-0.57 0.3,-1.37 -0.26,-1.78c-0.57,-0.42 -1.36,-0.3 -1.78,0.27l-0.93,1.25l0,-5.24c0,-1.05 -0.81,-1.9 -1.81,-1.9s-1.81,0.85 -1.81,1.9l0,17.45l-2.17,-3.68c-0.51,-0.87 -1.62,-1.15 -2.49,-0.64c-0.87,0.51 -1.15,1.63 -0.64,2.49c0,0 5.22,8.71 5.3,8.8l0,10.63c-5.11,-0.38 -9.91,-2.32 -14.08,-5.49c-1.97,-1.49 -3.78,-3.23 -5.42,-5.22l1.62,6.03c1.57,1.42 3.26,2.64 5.02,3.69c3.7,2.22 7.78,3.61 12.08,4.01l0,24.52l5.19,0l0,-24.54c17.51,-1.66 31.35,-19.78 31.35,-41.85l-0.02,-0.01z"
          />
          <g id="svg_10">
            <path
              style={{ fill: props.fill }}
              d="m252.54,118.18c9.33,0 24.75,1.33 34.84,5.52c3.05,1.33 4.19,2.85 3.62,5.9l-1.71,7.81c-0.58,2.28 -1.53,3.23 -4.96,2.85c-9.33,-1.14 -20.75,-2.47 -30.65,-2.47c-25.9,0 -29.7,20.94 -29.7,45.69s4.76,44.55 29.7,44.55c11.05,0 20.37,-1.14 30.65,-2.47c3.62,-0.38 4.38,0.57 5.15,3.04l1.52,7.24c0.76,3.05 -0.19,4.76 -3.24,6.09c-9.33,4 -25.89,5.71 -35.22,5.71c-42.46,0 -51.41,-31.42 -51.41,-63.79s8,-65.69 51.41,-65.69l0,0.02z"
            />
            <path
              style={{ fill: props.fill }}
              d="m358.6,247.66c-46.64,0 -50.83,-37.13 -50.83,-66.64c0,-23.04 5.33,-63.02 51.02,-63.02s51.79,32.56 51.79,63.02s-4.76,66.64 -51.98,66.64zm0.19,-111.01c-21.7,0 -28.56,16.95 -28.56,46.27s5.72,45.89 28.56,45.89s29.9,-16.38 29.9,-45.89s-6.67,-46.27 -29.9,-46.27z"
            />
            <path
              style={{ fill: props.fill }}
              d="m483.89,270.26l-53.88,0c-2.29,0 -3.05,-0.76 -3.05,-3.04l0,-6.48c0,-2.66 0.95,-4.19 2.29,-5.52l31.42,-29.13c3.61,-3.42 7.23,-9.14 7.23,-14.09c0,-6.66 -4.38,-8.38 -13.14,-8.38c-7.05,0 -15.23,0.57 -20.94,1.14c-2.1,0.19 -4.19,0.57 -4.76,-2.47l-0.96,-5.52c-0.38,-2.28 0,-4 3.62,-5.14c6.48,-2.28 18.28,-3.23 23.23,-3.23c22.09,0 30.66,8.95 30.66,22.85c0,10.47 -3.62,16.56 -12.19,24.56l-21.33,19.99l31.8,0c2.1,0 3.24,0.76 3.24,3.23l0,8.19c0,1.9 -0.95,3.04 -3.24,3.04z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CFDH;
