import { Typography } from "@mui/material";
import { GfaCategoryType } from "constants/layersCategories";

export const gfaCategoryToTitle: Record<GfaCategoryType, string> = {
  forest: "Forest",
  fragmentation: "Fragmentation",
  pattern: "Pattern",
  accounting: "Accounting"
};

const layersDescriptions = {
  forest: (
    <Typography>
      The forest cover layer shows the pixels covered by forest, extracted from Copernicus satellite imagery, according
      to FAO's definition of forest.
    </Typography>
  ),
  fragmentation: (
    <Typography>
      The fragmentation layer and statistics show the degree of fragmentation in a local neighborhood area of ~10 km2
      over each forest pixel.
    </Typography>
  ),
  pattern: (
    <Typography>
      Forest pattern layer and statistics show the morphological feature classes of forest pixels.
    </Typography>
  ),
  accounting: (
    <Typography>Forest accounting layer and statistics show the forest patch size class distribution.</Typography>
  ),
  change: (
    <>
      <Typography>Forest Attributes change layer and statistics provide information on:</Typography>
      <ul>
        <li>change of Forest Cover</li>
        <li>change of Fragmentation</li>
      </ul>
    </>
  )
};

export default layersDescriptions;
