import { GlobalContext, GlobalContextI } from "App";
import { isEqual } from "lodash";
import { useContext, useEffect } from "react";

type SynchedContexts = keyof GlobalContextI["contexts"];

// put child contexts in global context
export default function useContextSync<T>(context: T, key: SynchedContexts): void {
  const { contexts, setContexts } = useContext(GlobalContext);
  useEffect(() => {
    const equal = isEqual(context, contexts[key]);
    if (equal) return;
    const newContext = { ...contexts, [key]: context };
    setContexts(newContext);

    return () => {
      if (equal) setContexts({ ...contexts, [key]: undefined });
    };
  });
}
