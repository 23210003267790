import { Box } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  legend: ReactNode;
};

const WithLegend = ({ children, legend }: Props) => {
  return (
    <Box className="flex flex-wrap items-center gap-30" sx={{ py: 1 }}>
      <Box sx={{ paddingX: 2, flexGrow: 1, flexBasis: "50%", border: "1px solid grey", height: "fit-content" }}>
        {legend}
      </Box>
      <Box sx={{ maxWidth: 300, flexGrow: 1, flexBasis: "40%", minWidth: 200, py: 2 }}>{children}</Box>
    </Box>
  );
};

export default WithLegend;
