import Control, { ControlPositionType } from "./LeafletCustomControl";
import MapButton from "./MapButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";

import styled from "@emotion/styled";

interface PropsI {
  position: ControlPositionType;
  onToggle: (e: any) => void;
  isOpen: boolean;
}

const ToggleSidebarButton = ({ position, onToggle, isOpen }: PropsI) => {
  return (
    <Control position={position}>
      <MapButton tooltip="Toggle Sidebar" onClick={onToggle} isactive={isOpen}>
        {!isOpen && <ArrowBackIosNewIcon />}
        {isOpen && <CloseIcon />}
      </MapButton>
    </Control>
  );
};

export default ToggleSidebarButton;
