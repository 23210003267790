const documents = [
  {
    title: "Layers documents",
    description: "Check the following documents for more info about the methodology and classes used.",
    links: [
      {
        title: "Fragmentation",
        actionUrl: "https://ies-ows.jrc.ec.europa.eu/gtb/GTB/psheets/GTB-Fragmentation-FADFOS.pdf",
        actionText: "DOWNLOAD DOC"
      },
      {
        title: "Pattern",
        actionUrl: "https://ies-ows.jrc.ec.europa.eu/gtb/GTB/psheets/GTB-Pattern-Morphology.pdf",
        actionText: "DOWNLOAD DOC"
      },
      {
        title: "Accounting",
        actionUrl: "https://ies-ows.jrc.ec.europa.eu/gtb/GTB/psheets/GTB-Objects-Accounting.pdf",
        actionText: "DOWNLOAD DOC"
      }
    ]
  },
  {
    title: "Global indicators",
    description: `Forest Area Density is the degree of connectivity of the forest.
    Porosity is the proportion of internal forest openings (percentage).
    Forest Coverage is the forest area divided by the total land area (percentage).
    Coherence is the degree of spatial forest network compactness (percentage).
    `,
    links: [
      {
        title: "Forest Area Density",
        actionUrl: "https://ies-ows.jrc.ec.europa.eu/gtb/GTB/psheets/GTB-Fragmentation-FADFOS.pdf",
        actionText: "DOWNLOAD DOC"
      },
      {
        title: "Porosity",
        actionUrl: "https://ies-ows.jrc.ec.europa.eu/gtb/GTB/psheets/GTB-Pattern-Morphology.pdf",
        actionText: "DOWNLOAD DOC"
      },
      {
        title: "Reference Area Coverage",
        actionUrl: "https://ies-ows.jrc.ec.europa.eu/gtb/GTB/psheets/GTB-RestorationPlanner.pdf",
        actionText: "DOWNLOAD DOC"
      },
      {
        title: "Coherence",
        actionUrl: "https://ies-ows.jrc.ec.europa.eu/gtb/GTB/psheets/GTB-RestorationPlanner.pdf",
        actionText: "DOWNLOAD DOC"
      }
    ]
  }
];
export default documents;
