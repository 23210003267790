import React from "react";
import ReactDOM from "react-dom";
import { Map, DomUtil, DomEvent } from "leaflet";
import { useMap } from "react-leaflet";

// ADD positions between corners
Map.include({
  _initControlPos: function () {
    const corners: any = (this._controlCorners = {}),
      l = "leaflet-",
      container = (this._controlContainer = DomUtil.create("div", l + "control-container", this._container));

    function createCorner(vSide: string, hSide: string) {
      const className = l + vSide + " " + l + hSide;

      corners[vSide + hSide] = DomUtil.create("div", className, container);
    }

    createCorner("top", "left");
    createCorner("top", "right");
    createCorner("bottom", "left");
    createCorner("bottom", "right");

    createCorner("top", "center");
    createCorner("center", "center");
    createCorner("center", "left");
    createCorner("center", "right");
    createCorner("bottom", "center");
  }
});

const POSITION_CLASSES = {
  centerRight: "leaflet-middle leaflet-right",
  centerLeft: "leaflet-middle leaflet-left",
  bottomLeft: "leaflet-bottom leaflet-left",
  bottomRight: "leaflet-bottom leaflet-right",
  bottomCenter: "leaflet-bottom leaflet-center",
  topLeft: "leaflet-top leaflet-left",
  topRight: "leaflet-top leaflet-right",
  topCenter: "leaflet-top leaflet-center"
};

export type ControlPositionType = keyof typeof POSITION_CLASSES;

interface PropsI {
  children: React.ReactElement;
  position: ControlPositionType;
  style?: React.CSSProperties;
}

const Control = (props: PropsI): JSX.Element => {
  const { children, style } = props;
  const [container, setContainer] = React.useState<any>(document.createElement("div"));
  const positionClass = (props.position && POSITION_CLASSES[props.position]) || POSITION_CLASSES.topRight;

  React.useEffect(() => {
    const targetDiv = document.getElementsByClassName(positionClass);
    setContainer(targetDiv[0]);
  }, []);

  const map = useMap();
  const handleHoverIn = () => map.scrollWheelZoom.disable();
  const handleHoverOut = () => map.scrollWheelZoom.enable();

  const controlContainer = (
    <div
      className="leaflet-control leaflet-bar"
      style={style}
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
    >
      {children}
    </div>
  );

  DomEvent.disableClickPropagation(container);

  return ReactDOM.createPortal(controlContainer, container);
};

export default Control;
