import axios from "axios";
import { CRS } from "leaflet";

const { REACT_APP_GEOSERVER_PATH, REACT_APP_MAPSERVER_PATH } = process.env;

export type CountryParams = {
  map: string;
  request: string;
  service: string;
  srs: string;
  crs: string;
  version: string;
  info_format: string;
  format: string;
  outputformat: string;
  layers: string;
  query_layers: string;
  styles: string;
};

export class OgcServerClient {
  serverUrlPath: string;
  wmsPath: string;
  wfsPath: string;
  ogcAxios: any;
  WMSParams: any;
  countryParams: any;

  constructor(url: string, wmsUrl: string, wfsUrl: string) {
    this.serverUrlPath = url;
    this.wmsPath = wmsUrl;
    this.wfsPath = wfsUrl;
    this.ogcAxios = axios.create({ baseURL: this.serverUrlPath });

    this.ogcAxios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        const { response } = error;
        if (!response) {
          console.log("NO RESPONSE");
        } else {
          console.error("error", response);
        }
        return error;
      }
    );

    this.WMSParams = {
      format: "image/png",
      opacity: "0.7",
      transparent: true,
      version: "1.3.0",
      request: "GetMap",
      service: "WMS",
      crs: CRS.EPSG4326.code,
      srs: CRS.EPSG4326.code,
      styles: ""
    };
  }

  getWFS(params: any) {
    return this.ogcAxios.get(this.wfsPath, params);
  }
  getWMS(params: any) {
    return this.ogcAxios.get(this.wmsPath, params);
  }

  getWMSPath() {
    return this.serverUrlPath + this.wmsPath;
  }
}

export class MapServerClient extends OgcServerClient {
  mapFileBasePath: string;

  constructor(map: string | "") {
    super(REACT_APP_MAPSERVER_PATH || "", map !== "" ? "/" + map : map, "");
    this.setCountryParams();
    this.mapFileBasePath = "/usr/lib/cgi-bin/mapfiles";
  }

  setCountryParams() {
    this.countryParams = {
      ...this.WMSParams,
      map: "/usr/lib/cgi-bin/mapfiles/eurostat.map",
      srs: CRS.EPSG4326.code,
      crs: CRS.EPSG4326.code,
      request: "GetFeatureInfo",
      outputformat: "json",
      info_format: "application/json",
      layers: "COUNTRIES_01M_2020",
      query_layers: "COUNTRIES_01M_2020",
      styles: ""
    };
  }

  getFeatureId(data: any): string {
    const { id } = data;
    return id;
  }
}

type GeometryLayerType =
  | "countries_gen"
  | "countries_tmf"
  | "countries_prd"
  | "countries_gfa"
  | "countries_ghm"
  | "countries_car";

export class GeoServerClient extends OgcServerClient {
  constructor(geometryLayer?: GeometryLayerType) {
    super(REACT_APP_GEOSERVER_PATH || "", "/wms", "/wfs");
    this.setCountryParams(geometryLayer);
  }

  setCountryParams(typeName?: GeometryLayerType) {
    this.countryParams = {
      ...this.WMSParams,
      srs: CRS.EPSG4326.code,
      crs: CRS.EPSG4326.code,
      request: "GetFeatureInfo",
      outputformat: "json",
      info_format: "application/json",
      layers: typeName ? typeName : "countries_gen",
      query_layers: typeName ? typeName : "countries_gen",
      styles: "",
      typeName
    };
  }

  getFeatureId(data: any): string | undefined {
    if (!data.features) return undefined;
    const { country_code } = data.features[0].properties;
    return country_code;
  }
}
