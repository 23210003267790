import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { makeTitle } from "utils/chartsUtils";
import { darkGreen, greyTextColor } from "constants/colors";
import StyledLink from "../../../reusables/StyledLink";
import colorByPageName from "../../../../constants/colorByPageName";

const PADDING = 3;
const color = colorByPageName["Tropical Moist Forest"];

const SidebarContent = () => {
  return (
    <>
      <Box padding={PADDING} color={color} sx={{ textAlign: "justify" }}>
        The Tropical Moist Forest dataset from the Joint Research Centre provides information on changes in forest cover
        from 2000 up to the year 2023 in the humid tropics. The wall-to-wall maps at 0.09 ha resolution (30m) depict the
        tropical moist forest extent and the related disturbances (deforestation and degradation), and
        post-deforestation recovery (or forest regrowth). Deforestation refers to a change in land cover (from forest to
        non-forested land) when degradation refers to a short-duration disturbance in a forest remaining forested such
        as selective logging, fires and unusual weather events (hurricanes, droughts, blowdown).
        <br />
        <br />
        Check the{" "}
        <StyledLink
          href="https://forobs.jrc.ec.europa.eu/TMF"
          target="_blank"
          color={color}
          aria-label="Link to TMF Tropical Forest Monitoring (external link will be opened in a new window)"
        >
          TMF website
        </StyledLink>{" "}
        and{" "}
        <StyledLink
          href="https://forobs.jrc.ec.europa.eu/static/tmf/TMF_DataUsersGuide.pdf"
          target="_blank"
          color={color}
          aria-label="Link to documentation of Tropical Moist Forest - Data Users Guide (v1) (external link to pdf file will be opened in a new window)"
        >
          documentation
        </StyledLink>{" "}
        for more information about the methodology and classes used.
        <br />
      </Box>
    </>
  );
};

export default SidebarContent;
