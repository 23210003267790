import { Box, Typography } from "@mui/material";
import SummaryPage, { SummaryItem } from "components/reusables/SummaryPage";
import colorByPageName from "constants/colorByPageName";
import StyledLink from "../reusables/StyledLink";
import { removeOpacity } from "components/reusables/removeColorOpacity";
const title = "Production and trade of commodities";
// const color = removeOpacity.color(colorByPageName[title]);
const color = colorByPageName[title];

const ProductionDescription = () => {
  return (
    <Typography>
      FAOSTAT agricultural production data contains statistics on food and agriculture production data for over 245
      countries and territories and covers all FAO regional groupings from 1961. The data selected are commodity and
      product quantities, area harvested (reported in hectares) for the production of commodities;
    </Typography>
  );
};

const TradeDescription = () => {
  return (
    <Typography>
      For the trade we report two different datasets which include bilateral trades, and specifically product import
      quantities and monetary values (in US$). The data presented as European Union are calculated by the European
      Commission, Directorate General Joint Research Centre:
      <br />
      1) FAOSTAT trade time series annual data on the international trade of food and agricultural products for the
      period from 1986;
      <br />
      2) UN COMTRADE data obtained from the United Nations Statistics Division (UNSD) since 2000.
    </Typography>
  );
};

const BioTradeDescription = () => {
  return (
    <Typography>
      The <b>biotrade</b> package (
      <StyledLink
        href="https://joss.theoj.org/papers/10.21105/joss.05550"
        target="_blank"
        color={color}
        aria-label="Link to Biotrade: A Python package to access and analyse the international trade of bio-based products (external link will be opened in a new window)"
      >
        Rougieux et al., 2023
      </StyledLink>
      ) allows for regular downloads of updated trade data in agriculture, fisheries and forest products from the
      international data sources FAOSTAT and United Nations (UN) Comtrade. The software, written in the Python language,
      provides methods for merging data from different sources as well as for aggregating and ranking trade flows for
      several products and all countries as well as geographical areas (e.g. continents). In addition, the package
      allows to store and update the collection of macroeconomic time series from the World Bank (2023).{" "}
    </Typography>
  );
};

const MainContent = () => {
  return (
    <Box>
      <SummaryItem
        title="Production"
        color={colorByPageName["Production"]}
        mainColor={color}
        linkTo="production"
        content={<ProductionDescription />}
        ariaLabel="Production map"
      />
      <SummaryItem
        title="Trade"
        color={colorByPageName["Trade"]}
        mainColor={color}
        linkTo="trade"
        content={<TradeDescription />}
        ariaLabel="Trade map"
      />
      <SummaryItem
        title="'Biotrade' tool"
        content={<BioTradeDescription />}
        color={color}
        mainColor={color}
        linkTo="https://pypi.org/project/biotrade/"
        external={true}
        ariaLabel="Biotrade tool"
      />
    </Box>
  );
};

const CommoditiesSummaryPage = () => {
  return (
    <SummaryPage backgroundImgPath="background_commodities" title={title} color={color} mainContent={<MainContent />} />
  );
};

export default CommoditiesSummaryPage;
