import { Box, Typography } from "@mui/material";

import SummaryPage from "components/reusables/SummaryPage";

import colorByPageName from "constants/colorByPageName";

import React from "react";

const title = "Reporting at country level";

const color = colorByPageName[title];

const Info1Description = () => {
  return <Typography>Under construction...</Typography>;
};

//const handleCountrySelect = (countryCode: string) => window.open("/factsheets/" + countryCode + ".pdf");

const MainContent = () => {
  return (
    <Box>
      <Info1Description />
    </Box>
  );
};

const NationalReportingPage = () => {
  return (
    <SummaryPage
      backgroundImgPath="images/national_reporting_bg.png"
      title={title}
      description="Datasets and information for each country"
      color={color}
      mainContent={<MainContent />}
    />
  );
};

export default NationalReportingPage;
