import { useContext } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Control, { ControlPositionType } from "./LeafletCustomControl";
import MapButton from "./MapButton";
import { MapPageContext } from "../MapPage";

interface PropsI {
  position: ControlPositionType;
  showScaleToRestrictLegend?: boolean;
}

const MapLegendButton = ({ position, showScaleToRestrictLegend }: PropsI) => {
  const { selectedLayers } = useContext(MapPageContext);

  const currentLayerParams: any = selectedLayers[0]?.params;
  const scale: any = showScaleToRestrictLegend ? { scale: "1:500" } : {};

  const disabled = selectedLayers.length == 0;

  const params = {
    ...(currentLayerParams || {}),
    ...scale,
    request: "GetLegendGraphic",
    style: currentLayerParams?.styles,
    layer: currentLayerParams?.layers,
    sld_version: "1.1.0"
  };

  const searchParams = new URLSearchParams(params);
  const imgUrl = selectedLayers[0]?.url + "?" + searchParams.toString();

  return (
    <Control position={position}>
      <MapButton
        tooltip="Legend"
        popupWrapperStyle={{ maxHeight: "50vh" }}
        popupContent={!disabled && <>{currentLayerParams && <img src={imgUrl} alt="legend" />}</>}
        disabled={disabled}
      >
        <FormatListBulletedIcon />
      </MapButton>
    </Control>
  );
};

export default MapLegendButton;
