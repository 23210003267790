import Box from "@mui/material/Box";
import CustomSlider from "components/reusables/CustomSlider";
import { useContext } from "react";
import { MapPageContext } from "./MapPage";

interface TimeSliderPropsI {
  years: number[];
  onChange?: (year: number) => void;
  hideFirstLastLabel?: boolean;
}

function TimeSlider(props: TimeSliderPropsI) {
  const { onChange, years, hideFirstLastLabel } = props;
  const { selectedYear, setSelectedYear } = useContext(MapPageContext);

  if (years.length === 0) return <Box pl={2}>No years available</Box>;

  const marks = years.map((i, index) => {
    const isMinOrMaxMark = index === 0 || index === years.length - 1;
    let label = selectedYear === i ? i.toString() : undefined;

    if (isMinOrMaxMark) label = i.toString();

    if (hideFirstLastLabel && selectedYear !== i) label = "";

    // prevent middle label overlapping min and max ones
    let translation;
    if (years.length > 4) {
      if (index === 1) translation = "translate(8px, 0px)";
      if (index === years.length - 2) translation = "translate(-8px, 0px)";
    }
    const labelElement = <div style={{ transform: `${translation || ""}` }}>{label}</div>;

    return { value: i, label: labelElement };
  });

  const min = years[0];
  const max = years[years.length - 1];

  const handleChange = (e: any, newValue: any) => {
    setSelectedYear(newValue);
    if (onChange) onChange(newValue);
  };

  function valueLabelFormat(value: number) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  return (
    <Box pl={3} pr={3}>
      <Box
        sx={{
          borderRadius: 0.5,
          height: 50
        }}
      >
        <CustomSlider
          min={min}
          max={max}
          value={selectedYear}
          valueLabelFormat={valueLabelFormat}
          step={null}
          valueLabelDisplay="off"
          marks={marks}
          onChange={handleChange}
          style={{ marginBottom: 0 }}
        />
      </Box>
    </Box>
  );
}

export default TimeSlider;
