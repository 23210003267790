import React from "react";
import CustomTileLayer from "./CustomTileLayer";

const BaseLayer = () => {
  return (
    <CustomTileLayer
      url="https://gisco-services.ec.europa.eu/maps/tiles/OSMCartoComposite{language}/EPSG{projection}/{z}/{x}/{y}.png"
      language="EN"
      projection="4326"
      noWrap={false}
    />
  );
};

export default BaseLayer;
