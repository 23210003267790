import WebtoolsComponent, { defaultHighchartsData } from "components/charts/WebtoolsComponent";
import { Options, SeriesColumnOptions } from "highcharts";
import { set } from "lodash";
import { dataLabel, pointFormatter, pointFormatter2 } from "utils/chartsUtils";
import cloneDeep from "lodash/cloneDeep";

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;

const defaultData: Options = {
  ...defaultHighchartsData,
  chart: {
    type: "column",
    height: 300
  },
  tooltip: {
    pointFormatter: pointFormatter2
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        formatter: dataLabel
      }
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: MAX_WIDTH,
          maxHeight: MAX_HEIGHT
        }
      }
    ]
  }
};

type Props = {
  data: SeriesColumnOptions["data"];
  series?: SeriesColumnOptions["data"]; //if this is set, data is ignored
  title?: string;
  categories?: string[];
  yAxisTitle?: string;
  dataLabelDisable?: boolean | false;
  hideLegend?: boolean | false;
  colors?: string[];
};

const ColumnChart = (props: Props) => {
  const { title, data, series, categories, yAxisTitle, dataLabelDisable, hideLegend, colors } = props;

  let merged = cloneDeep(defaultData);
  merged = set(merged, "title.text", title);
  if (series) {
    merged = set(merged, "series", series);
  } else {
    merged = set(merged, "series[0].data", data);
  }
  if (categories) merged = set(merged, "xAxis.categories", categories);
  if (yAxisTitle) merged = set(merged, "yAxis.title.text", yAxisTitle);
  if (dataLabelDisable) merged = set(merged, "plotOptions.series.dataLabels.enabled", false);

  if (colors) {
    merged = set(merged, "colors", colors);
    merged = set(merged, "plotOptions.column.colorByPoint", true);
  }

  if (hideLegend === false) merged = set(merged, "plotOptions.column.showInLegend", true);
  else merged = set(merged, "plotOptions.column.showInLegend", false);

  return <WebtoolsComponent id={`column_${title}`} data={merged} />;
};

export default ColumnChart;
