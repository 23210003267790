import { Box, FormControlLabel, FormGroup, IconButton, Typography } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";

import Control from "components/reusables/MapComponents/LeafletCustomControl";
import { TradePageContext } from "components/pages/CommoditiesTradePage";
import MapButton from "components/reusables/MapComponents/MapButton";
import LayersIcon from "@mui/icons-material/Layers";
import ThresholdSlider from "components/pages/CommoditiesTradePage/ThresholdSlider";
import PeriodSlider from "components/reusables/PeriodSlider";
import { useQuery } from "react-query";
import {
  getPeriods,
  getProductsMapping,
  getStatements,
  getTradesAverageAvailableProducts,
  StatementI
} from "utils/apiRequests";
import ProductTreeSelect from "components/pages/CommoditiesTradePage/ProductTreeSelect";
import CustomCheckbox from "components/reusables/CustomCheckbox";
import apiClient from "utils/apiClient";
import { MapPageContext } from "components/reusables/MapPage";
import { Info } from "@mui/icons-material";
import { StyledLinkLeaflet } from "../../reusables/StyledLink";
import { CommoditiesProductI, ProductMappinI } from "../../../types";

const TradesPageControlsPanel = () => {
  const { selectedCountryCode } = useContext(MapPageContext);

  const { period, setPeriod, periodOptionsApiPath, layer, setLayer, product, setProductName } =
    useContext(TradePageContext);
  const { data: periodOptions } = useQuery([periodOptionsApiPath, layer], getPeriods, { staleTime: Infinity });
  const [showDescription1, setShowDescription1] = useState(false);
  const [showDescription2, setShowDescription2] = useState(false);

  const { data: statements } = useQuery([], getStatements, { staleTime: Infinity });

  const { data: mappingTable } = useQuery(
    ["getProductsMapping", selectedCountryCode, period, layer],
    getProductsMapping,
    { staleTime: Infinity }
  );

  const objStatementFao: StatementI =
    statements?.find(function (el) {
      return el.source === "TRDFAO";
    }) || {};
  const objStatementCom: StatementI =
    statements?.find(function (el) {
      return el.source === "TRDCOM";
    }) || {};

  const layersControls = [
    {
      name: "comtrade",
      label: "UN Comtrade",
      description: (
        <Typography sx={{ fontSize: "0.75rem" }}>
          {objStatementCom.text_before}
          <StyledLinkLeaflet target="_blank" href={objStatementCom.link1_target}>
            {objStatementCom.link1_text}
          </StyledLinkLeaflet>
          {objStatementCom.text_between}
          <StyledLinkLeaflet target="_blank" href={objStatementCom.link2_target}>
            {objStatementCom.link2_text}
          </StyledLinkLeaflet>
          {objStatementCom.text_after}
        </Typography>
      )
    },
    {
      name: "faostat",
      label: "FAOSTAT",
      description: (
        <Typography sx={{ fontSize: "0.75rem" }}>
          {objStatementFao.text_before}
          <StyledLinkLeaflet target="_blank" href={objStatementFao.link1_target}>
            {objStatementFao.link1_text}
          </StyledLinkLeaflet>
          {objStatementFao.text_between}
          <StyledLinkLeaflet target="_blank" href={objStatementFao.link2_target}>
            {objStatementFao.link2_text}
          </StyledLinkLeaflet>
          {objStatementFao.text_after}
        </Typography>
      )
    }
  ];

  const setMappingProduct = (selected: string) => {
    const previous_layer = selected === "faostat" ? "comtrade" : "faostat";
    const layer = selected === "comtrade" ? "comtrade" : "faostat";
    const match =
      product &&
      (mappingTable?.find((p) => p[`product_code_${previous_layer}`] === product.product_code) as ProductMappinI);
    match && setProductName(match[`product_name_${layer}`]);
  };

  const handleInfoClick = (index: number) =>
    index === 0 ? setShowDescription1(!showDescription1) : setShowDescription2(!showDescription2);

  const setInitialPeriod = async () => {
    if (!periodOptionsApiPath) return;
    if (!periodOptions) return;
    // when options change, if the current one is not available
    // select the last available one
    if (period) {
      if (periodOptions?.includes(period)) return;
      else setPeriod(periodOptions[periodOptions.length - 1]);
    } else setPeriod(periodOptions[periodOptions.length - 1]);
  };

  useEffect(() => {
    setInitialPeriod();
  }, [periodOptions]);

  const setLatestPeriodWithData = async () => {
    const res = await apiClient.get(`${periodOptionsApiPath}/d/${layer}`, {
      params: { product_code: product?.product_code, reporter_code: selectedCountryCode }
    });
    if (res.data && res.data.length > 0) {
      setPeriod(res.data[res.data.length - 1]);
    }
  };

  useEffect(() => {
    setLatestPeriodWithData();
  }, [layer, product, selectedCountryCode]);

  const handleLayerChange = (e: any) => {
    setLayer(e.target.name);
    //Chanfe selected product and product name if in mapping table.
    setMappingProduct(e.target.name);
  };

  return (
    <Control position="topRight">
      <MapButton
        tooltip="Layers"
        anchorposition={{ horizontal: "left" }}
        containerStyle={{ width: 300 }}
        popupWrapperStyle={{ maxHeight: "60vh" }}
        popupContent={
          <Box className="flex flex-column gap-10" sx={{ px: 0.75 }}>
            <Typography variant="caption">Layer</Typography>
            <FormGroup sx={{ pl: 2 }}>
              {layersControls.map(({ name, label, description }, index) => {
                const isChecked = layer === name;
                return (
                  <Fragment key={index}>
                    <Typography>
                      <FormControlLabel
                        control={
                          <CustomCheckbox name={name} checked={isChecked} onChange={handleLayerChange} size="small" />
                        }
                        label={isChecked ? <Typography style={{ fontWeight: "500" }}>{label}</Typography> : label}
                        sx={{ padding: 0, margin: 0 }}
                      />
                      {description && (
                        <IconButton
                          size="small"
                          sx={{
                            color: "primary.main",
                            height: 30,
                            width: 30,
                            padding: 0,
                            margin: 0
                          }}
                          onClick={handleInfoClick.bind(this, index)}
                        >
                          <Info fontSize="small" />
                        </IconButton>
                      )}
                    </Typography>
                    {index === 0 && showDescription1 && <Box>{description}</Box>}
                    {index === 1 && showDescription2 && <Box>{description}</Box>}
                  </Fragment>
                );
              })}
            </FormGroup>

            <Typography variant="caption">{layer === "faostat" ? "FAOSTAT" : "UN COMTRADE"} Product </Typography>
            <ProductTreeSelect />
            <Box>
              <Typography variant="caption">Period:</Typography>
              <PeriodSlider />
              <Typography variant="caption">Trade amount threshold:</Typography>
              <ThresholdSlider />
            </Box>
          </Box>
        }
      >
        <LayersIcon />
      </MapButton>
    </Control>
  );
};

export default TradesPageControlsPanel;
