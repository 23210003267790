import { get } from "lodash";

export const sumBy = (path: string, array: any[]): number => {
  return array.reduce((accum, item) => {
    const found = get(item, path);
    if (found) return accum + parseFloat(found);
    else return accum;
  }, 0);
};

export const roundNumber = (result: number, decimalPlaces: number): number => {
  return Number(Math.round(parseFloat(result + "e" + decimalPlaces)) + "e-" + decimalPlaces);
};

export function makeId(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function range(start: number, end: number) {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
}

export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item);
}

export function mergeDeep(target: any, source: any) {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export function flattenChildren(array: any[]) {
  let result: any[] = [];
  array.forEach(function (a: any) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flattenChildren(a.children));
    }
  });
  return result;
}
