import React, { useState } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import Control, { ControlPositionType } from "./LeafletCustomControl";
import MapButton from "./MapButton";

const activeStyle = {
  backgroundColor: "#a3a3a3"
};

interface PropsI {
  position: ControlPositionType;
  onZoomStart?: () => void;
  onZoomEnd?: () => void;
}

const BoxZoomButton = (props: PropsI) => {
  const map = useMap();
  const { position, onZoomStart, onZoomEnd } = props;
  const [isZooming, setIsZooming] = useState(false);
  let startLatLng: L.LatLng | null = null;
  let polygon: L.Polygon | null = null;

  const container = map.getContainer();

  const handleMouseDown = (e: any) => {
    startLatLng = e.latlng;
  };
  const handleMouseMove = (e: any) => {
    if (!startLatLng) return;

    const ne = startLatLng;
    const nw = new L.LatLng(startLatLng.lat, e.latlng.lng);
    const sw = e.latlng;
    const se = new L.LatLng(e.latlng.lat, startLatLng.lng);

    if (!polygon) {
      const newPolygon = new L.Polygon([ne, nw, sw, se]);
      polygon = newPolygon;
      map.addLayer(newPolygon);
    } else {
      polygon.setLatLngs([ne, nw, sw, se]);
    }
  };

  const handleMouseUp = (e: any) => {
    if (
      startLatLng === null ||
      startLatLng === undefined ||
      startLatLng.lat === e.latlng.lat ||
      startLatLng.lng === e.latlng.lng
    ) {
      stop();
      return;
    }

    const ne = startLatLng;
    const sw = e.latlng;

    const bounds = L.latLngBounds([ne, sw]);
    map.fitBounds(bounds, { animate: false });

    startLatLng = null;
    if (polygon) map.removeLayer(polygon);
    polygon = null;

    stop();
  };

  const removeListeners = () => {
    map.removeEventListener("mousedown", handleMouseDown);
    map.removeEventListener("mousemove", handleMouseMove);
    map.removeEventListener("mouseup", handleMouseUp);
  };

  const start = () => {
    setIsZooming(true);
    if (onZoomStart) onZoomStart();
    startLatLng = null;
    map.dragging.disable();
    L.DomUtil.addClass(container, "crosshair-cursor-enabled");

    map.addEventListener("mousedown", handleMouseDown);
    map.addEventListener("mousemove", handleMouseMove);
    map.addEventListener("mouseup", handleMouseUp);
  };

  const stop = () => {
    setIsZooming(false);
    if (onZoomEnd) onZoomEnd();
    startLatLng = null;
    map.dragging.enable();
    L.DomUtil.removeClass(container, "crosshair-cursor-enabled");
    removeListeners();
  };

  const handleClick = (e: any) => {
    if (isZooming) stop();
    else start();
    e.stopPropagation();
  };

  const onButtonMouseUp = (e: any) => {
    // THIS IS IMPORTANT to prevent the other onmouseup listener from interfering
    e.stopPropagation();
  };

  return (
    <Control position={position}>
      <MapButton
        id="box-zoom-button"
        tooltip="Zoom Rectangle"
        color="inherit"
        onClick={handleClick}
        style={isZooming ? activeStyle : undefined}
        onMouseUp={onButtonMouseUp}
      >
        <svg id="box-zoom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
          <path d="M288,21.33A21.33,21.33,0,0,1,309.33,0h64a21.33,21.33,0,0,1,21.33,21.33v64a21.33,21.33,0,1,1-42.67,0V42.67H309.33A21.33,21.33,0,0,1,288,21.33ZM495.08,505.75a21.33,21.33,0,0,1-30.17,0l-79.07-79.07A148.2,148.2,0,0,1,309.33,448C227,448,160,381,160,298.67s67-149.33,149.33-149.33,149.33,67,149.33,149.33a148.59,148.59,0,0,1-39.58,100.92l76,76A21.33,21.33,0,0,1,495.08,505.75ZM373.33,298.67A21.33,21.33,0,0,0,352,277.33H330.67V256A21.33,21.33,0,0,0,288,256v21.33H266.67a21.33,21.33,0,1,0,0,42.67H288v21.33a21.33,21.33,0,1,0,42.67,0V320H352A21.33,21.33,0,0,0,373.33,298.67ZM96,0H32A21.33,21.33,0,0,0,10.67,21.33v64a21.33,21.33,0,1,0,42.67,0V42.67H96A21.33,21.33,0,0,0,96,0Zm0,341.33H53.33V298.67a21.33,21.33,0,1,0-42.67,0v64A21.33,21.33,0,0,0,32,384H96a21.33,21.33,0,0,0,0-42.67ZM32,234.67a21.33,21.33,0,0,0,21.33-21.33V170.67a21.33,21.33,0,1,0-42.67,0v42.67A21.33,21.33,0,0,0,32,234.67Zm149.33-192H224A21.33,21.33,0,0,0,224,0H181.33a21.33,21.33,0,0,0,0,42.67Z" />
        </svg>
      </MapButton>
    </Control>
  );
};

export default BoxZoomButton;
