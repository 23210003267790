import WebtoolsComponent, { defaultHighchartsData } from "components/charts/WebtoolsComponent";
import { set } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { Options } from "highcharts";
import { TrendsProps } from "types";
import {
  dataLabelTrends,
  dataLabelYaxis,
  pointFormatterTrend
} from "../pages/CommoditiesProductionPage/Sidebar/TrendChart";

const defaultData: Options = {
  ...defaultHighchartsData,
  chart: {
    type: "column",
    inverted: true
  },
  tooltip: {
    headerFormat: "",
    outside: false,
    pointFormatter: pointFormatterTrend
  },
  plotOptions: {
    series: {
      dataLabels: {
        allowOverlap: true,
        enabled: true,

        formatter: dataLabelTrends
      }
    }
  },
  responsive: {
    rules: [
      {
        condition: {}
      }
    ]
  },

  xAxis: {
    type: "category",
    title: {
      text: " "
    },
    labels: {
      formatter: dataLabelYaxis,
      useHTML: true
    }
  }
};

const BarChart = (props: TrendsProps) => {
  const { data, categories, yAxisTitle, title, plotBand, functionToCall, callParameter } = props;

  let merged = cloneDeep(defaultData);

  if (functionToCall && callParameter) {
    merged = set(merged, "chart.events", {
      render() {
        const x = this.title.getBBox().width + this.title.getBBox().x + 5;
        const y = this.title.getBBox().y;

        if (this.helpOutline) {
          this.helpOutline.destroy();
        }

        // Create groups

        this.helpOutline = this.renderer
          .image("help_outline.svg", x, y)
          .css({
            cursor: "pointer"
          })
          .add()
          .toFront()
          .on("click", function () {
            functionToCall(callParameter);
          });
      }
    });
  }
  merged = set(merged, "title.text", title);

  merged = set(merged, "series", data);

  set(merged, "yAxis.title.text", "");
  if (yAxisTitle) merged = set(merged, "yAxis.title.text", yAxisTitle);

  if (categories) merged = set(merged, "xAxis.categories", categories);

  if (plotBand) merged = set(merged, "xAxis.plotBands", [plotBand]);

  return <WebtoolsComponent id={`column_${title}`} data={merged} />;
};

export default BarChart;
