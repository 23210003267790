import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { blue } from "constants/colors";
import appTitle from "constants/title";
import { useEffect, useState } from "react";
import { useMatches } from "react-router";
import { MatchWithHandle } from "router";
import Breadcrumbs from "./Breadcrumbs";
import "./header.css";

export const MAIN_HEADER_HEIGHT = 70;
export const MAIN_HEADER_HEIGHT_MOBILE = 50;
export const BREADCRUMB_HEIGHT = 35;

const MainHeader = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const height = isSmallScreen ? MAIN_HEADER_HEIGHT_MOBILE : MAIN_HEADER_HEIGHT;

  return (
    <Box className="flex" sx={{ height, width: "100%", justifyContent: "center" }}>
      <Box className="flex">
        <a
          href="https://ec.europa.eu"
          target={"_blank"}
          title={"European Commission"}
          aria-label="Link to the EU Commission Official Website(external link will be opened in a new window)"
        >
          <img
            alt="Logo of European Commission"
            src="images/European_Commission_logo.svg"
            style={{ height, padding: 10 }}
          />
        </a>
      </Box>
      <Box className="flex" sx={{ pl: 2, pr: 1 }}>
        <Typography
          sx={{
            textOverflow: "ellipsis",
            maxHeight: "100%",
            overflow: "hidden",
            marginTop: "auto",
            marginBottom: "auto",
            fontSize: "1.2rem !important",
            lineHeight: 1
          }}
          color={blue}
          className="my-auto ec-font"
          variant="h1"
        >
          {appTitle}
        </Typography>
      </Box>
    </Box>
  );
};

const Header = () => {
  const matches = useMatches() as MatchWithHandle[];
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { handle } = matches[matches.length - 1];
  const isMapPage = Boolean(handle?.isMapPage);

  const [showMainHeader, setShowMainHeader] = useState(true);
  const [isToggledByUser, setIsToggledByUser] = useState(false);

  useEffect(() => {
    if (isToggledByUser) return;
    setShowMainHeader(!isMapPage);
  }, [isMapPage]);

  return (
    <header id="HEADER_WRAPPER" style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 2000 }}>
      {showMainHeader && <MainHeader />}
      <Breadcrumbs
        showMainHeader={showMainHeader}
        setShowMainHeader={setShowMainHeader}
        setIsToggledByUser={setIsToggledByUser}
        matches={useMatches() as MatchWithHandle[]}
        isSmallScreen={isSmallScreen}
      />
    </header>
  );
};

export default Header;
