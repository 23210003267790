import { Box, Typography } from "@mui/material";
import SummaryPage, { SummaryTitleText } from "components/reusables/SummaryPage";

import { blue } from "constants/colors";

import colorByPageName from "constants/colorByPageName";
import StyledLink from "components/reusables/StyledLink";

const title = "Background information";
const color = colorByPageName[title];

const Ele1 = () => {
  return (
    <Typography color={blue}>
      <b>
        Priority 5 Support the availability of, quality of, and access to information on forests and commodity supply
        chains. Support research and innovation{" "}
      </b>
      <br />
      <br />
      Effective forest policy needs reliable information on forest resources, their condition, and how they are managed
      and used. It also needs reliable information on land-use change. The EU has been supporting research and capacity
      building in this domain at the global, regional and national levels. It has also been supporting the monitoring of
      deforestation and forest degradation by using Earth Observation data from different sources.
      <br />
      <br /> The EU’s Copernicus Earth Observation and Monitoring programme provides full, free and open access to
      Sentinel satellites data and information from Copernicus services. This has helped to reduce the cost of
      monitoring Sustainable Development Goals indicators like the indicator on sustainable forest management.
      Copernicus is a key tool to strengthen global or national forest monitoring systems. The Commission is also
      implementing the EU’s Bio-economy Strategy, including the establishment of a Knowledge Centre for Bio-economy and
      an EU monitoring system for the bio-economy, covering ecosystems and their services. However, there is a need to
      further harness the EU’s earth-observation and supply-chain data, and to combine research and monitoring capacity
      to develop an early warning system.
      <br />
      <br />
      It is important to encourage the consumption of products from deforestation-free supply chains in the EU and to
      fine-tune assessments of the impact that consumption by the EU and other markets has on the world’s forests. This
      requires better monitoring of trade flows down to national, regional and local levels, and better access to timely
      information. A number of initiatives have emerged in recent years that seek to achieve this, but only a few of
      these supply-chain transparency initiatives have been developed into truly accessible platforms capable of
      informing the decisions of a wide range of actors.
      <br />
      <br />
      <b>The list of key actions to be implemented by the Commission includes : </b>
      <br />• Build on the already existing monitoring tools, and establish an EU Observatory on deforestation, forest
      degradation, changes in the world’s forest cover, and associated drivers. The objective of this is to facilitate
      access to information on supply chains for public entities, consumers and businesses.
    </Typography>
  );
};
const Ele1Title = () => {
  return (
    <>
      From the Commission Communication{" "}
      <StyledLink
        href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:52019DC0352"
        target="_blank"
        color={blue}
        aria-label="Link to EUR lex website union law page(external link will be opened in a new window)"
      >
        Stepping up EU Action to Protect and Restore the World’s Forests
      </StyledLink>{" "}
      (COM(2019) 352 final)
    </>
  );
};

const Ele2 = () => {
  return (
    <Typography color={blue}>
      <b> Recital (31)</b>
      <br />
      <br /> Another important action announced in the communication on Stepping up EU Action to Protect and Restore the
      World’s Forests is the establishment of the EU Observatory on deforestation, forest degradation, changes in the
      world’s forest cover and associated drivers (‘EU Observatory’) launched by the Commission in order to better
      monitor changes in the world’s forest cover and related drivers. Building on existing monitoring tools, including
      Copernicus products and other publicly or privately available sources, the EU Observatory should facilitate access
      to information on supply chains for public entities, consumers and business, providing easy-to-understand data and
      information linking deforestation, forest degradation and changes in the world’s forest cover to Union demand for,
      and trade in, commodities and products. The EU Observatory should thus support the implementation of this
      Regulation by providing scientific evidence with regard to global deforestation and forest degradation and related
      trade. The EU Observatory should provide for land cover maps, including with time series since the cut-off date
      defined in this Regulation, and a range of classes allowing landscape composition to be examined. The EU
      Observatory should participate in the development of an early warning system combining research and monitoring
      capacity. As regards this Regulation, when technically feasible, the objective of the early warning system should
      be to be part of a platform that can assist the competent authorities, operators, traders and other relevant
      stakeholders and that can provide continuous monitoring and early notification of possible deforestation or forest
      degradation activities. That platform should be operational as soon as possible. The EU Observatory should
      cooperate with the competent authorities, relevant international organisations and bodies, research institutes,
      non-governmental organisations, operators, traders, third countries and other relevant stake holders
    </Typography>
  );
};
const Ele2Title = () => {
  return (
    <>
      From the{" "}
      <StyledLink
        href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32023R1115"
        target="_blank"
        color={blue}
        aria-label="Link to EUR lex website Regulation (external link will be opened in a new window)"
      >
        Regulation (EU) 2023/1115 of the European Parliament and the Council on the making available on the Union market
        and the export from the Union of certain commodities and products associated with deforestation and forest
        degradation and repealing Regulation (EU) No 995/2010
      </StyledLink>
      {""}:
    </>
  );
};

const Ele3 = () => {
  return (
    <Typography color={blue}>
      <b>
        Q61. When will the EU Forest Observatory be operational? How is this going to help companies implement the
        Regulation?
      </b>
      <br />
      <br />
      The Observatory will build on already existing monitoring tools, including Copernicus products and other publicly
      or privately available sources, to support the implementation of this Regulation by providing scientific evidence,
      including land cover maps on the cut-off date, regarding global deforestation and forest degradation and related
      trade. The use of these maps will not automatically ensure that the conditions of the Regulation are complied
      with, but it will be a tool to help companies to ensure compliance with this Regulation, for example to assess the
      deforestation risk. Companies will still be obliged to carry out due diligence.
      <br />
      <br />
      The EU Forest Observatory will cover all forests worldwide, including European forests and will be developed in
      coherence with other ongoing EU policy developments such as the Forest Monitoring Law and upgrading and
      enhancement of the Forest Information System for Europe (FISE).
      <br />
      <br />
      There is not yet a precise date for the full operationalization of the Observatory (indicative date for the
      platform to be online is December 2023). The availability of the services provided in the future by the
      Observatory is however not a precondition to comply with the requirements set by this Regulation.
      <br />
      <br />
      The Observatory will build on already existing monitoring tools, including Copernicus products and other publicly
      or privately available sources, to support the implementation of this Regulation by providing scientific evidence,
      including land cover maps on the cut-off date, regarding global deforestation and forest degradation and related
      trade. The use of these maps will not automatically ensure that the conditions of the Regulation are complied
      with, but will be a tool to help companies to ensure compliance with this Regulation, for example to assess the
      deforestation risk. Companies will still be obliged to carry out due diligence.
    </Typography>
  );
};
const Ele3Title = () => {
  return (
    <>
      From the{" "}
      <StyledLink
        href="https://environment.ec.europa.eu/system/files/2023-06/FAQ%20-%20Deforestation%20Regulation_1.pdf"
        target="_blank"
        color={blue}
        aria-label="Link to Frequently Asked Questions document for EUDR (external link to pdf file will be opened in a new window)"
      >
        Frequently Asked Questions document for EUDR{" "}
      </StyledLink>
      :
    </>
  );
};

const Ele4 = () => {
  return (
    <Typography color={blue}>
      <b>Chapter 4. Strategic forest monitoring, reporting and data collection</b>
      <br />
      <br />
      The Forest Information System for Europe (FISE) will be enhanced to become the corner stone for harmonised forest
      data in Europe. The integrated forest monitoring system will therefore be framed under and its results made
      available through this information system. The Commission’s EU Observatory on deforestation, forest degradation,
      changes in the world’s forest cover, and associated drivers will develop Earth-Observation-based monitoring tools
      for forests that may be operationalized by Copernicus and taken up by FISE as part of the integrated forest
      monitoring system.
    </Typography>
  );
};
const Ele4Title = () => {
  return (
    <>
      From the Commission Communication{" "}
      <StyledLink
        href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A52021DC0572"
        target="_blank"
        color={blue}
        aria-label="Link to EUR lex website New EU forest stragegy for 2030 (external link will be opened in a new window)"
      >
        New EU Forest Strategy for 2030{" "}
      </StyledLink>
      (COM(2021) 572 final):
    </>
  );
};

const MainContent = () => {
  return (
    <Box>
      <SummaryTitleText color={blue} title={<Ele1Title />} content={<Ele1 />} />
      <SummaryTitleText color={blue} title={<Ele2Title />} content={<Ele2 />} />
      <SummaryTitleText color={blue} title={<Ele3Title />} content={<Ele3 />} />
      <SummaryTitleText color={blue} title={<Ele4Title />} content={<Ele4 />} />
    </Box>
  );
};
const GlobalForestMonitoringSummaryPage = () => {
  return <SummaryPage backgroundImgPath="background_gfm" title={title} color={color} mainContent={<MainContent />} />;
};

export default GlobalForestMonitoringSummaryPage;
