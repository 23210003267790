import { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Alert, CircularProgress, CircularProgressProps, Stack, Box } from "@mui/material";
import { GlobalContext, TimestampedNotificationI } from "../App";
import { groupBy } from "lodash";

interface NotificationPropsI {
  notification: TimestampedNotificationI;
  repeatCount?: number;
  removeGroup?: () => void;
}

// Disable transition which would result in circle out of sync with percentage
const CircularProgressStatic = styled(CircularProgress)<CircularProgressProps>`
  circle {
    transition: unset;
    -webkit-transition: unset;
  }
`;

const getPercentFromTimePassed = (timePassed: number, timeout: number) => {
  return (timePassed / timeout) * 100;
};

const Notification = (props: NotificationPropsI) => {
  const { removeNotification } = useContext(GlobalContext);
  const [isVisible, setIsVisible] = useState(true);
  const [timeoutPercent, setTimeoutPercent] = useState(0); // 0 - 100
  const { notification, removeGroup } = props;
  const { severity, message, timeout = 5000, width } = notification;

  const handleRemoveNotification = () => {
    setIsVisible(false);
    if (removeGroup) removeGroup();
    else removeNotification(notification);
  };

  useEffect(() => {
    if (timeout) {
      const intervalMilliseconds = timeout / 100;

      let iterations = 0;
      const interval = setInterval(() => {
        iterations++;
        const timePassed = iterations * intervalMilliseconds;
        const percent = getPercentFromTimePassed(timePassed, timeout);
        setTimeoutPercent(percent);
        if (timePassed >= timeout) clearInterval(interval);
      }, intervalMilliseconds);
    }
  }, []);

  useEffect(() => {
    if (timeoutPercent === 100) {
      setTimeout(() => {
        handleRemoveNotification();
      }, 200);
    }
  }, [timeoutPercent]);

  if (!isVisible) return null;

  return (
    <Alert
      onClose={handleRemoveNotification}
      severity={severity}
      sx={{ width: width || 330 }}
      variant="filled"
      elevation={6}
    >
      <Box className="flex">
        <Box sx={{ pr: 1 }}>
          {timeout && <CircularProgressStatic variant="determinate" value={timeoutPercent} color="inherit" size={25} />}
        </Box>
        <span>
          {props.repeatCount && `${props.repeatCount} -`} {message}
        </span>
      </Box>
    </Alert>
  );
};

interface NotificationsGroupPropsI {
  notifications: TimestampedNotificationI[];
}

const NotificationsGroup = ({ notifications }: NotificationsGroupPropsI) => {
  const { removeNotification } = useContext(GlobalContext);

  const handleRemoveGroup = () => {
    for (const n of notifications) {
      removeNotification(n);
    }
  };

  return (
    <Notification
      notification={notifications[0]}
      repeatCount={notifications.length > 1 ? notifications.length : undefined}
      removeGroup={handleRemoveGroup}
    />
  );
};

const GlobalNotificationsList = () => {
  const { notifications } = useContext(GlobalContext);

  const grouped = groupBy(notifications, "type");

  return (
    <Stack spacing={2} sx={{ position: "absolute", bottom: 20, right: 40, zIndex: 3000 }}>
      {Object.values(grouped).map((i) => (
        <NotificationsGroup key={JSON.stringify(i)} notifications={i} />
      ))}
    </Stack>
  );
};

export default GlobalNotificationsList;
