import axios from "axios";

const { REACT_APP_API_PATH } = process.env;

export const apiBaseURL = REACT_APP_API_PATH;

const apiClient = axios.create({ baseURL: apiBaseURL });

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (!response) {
      console.log("NO RESPONSE");
    } else {
      console.error("error", response);
    }
    return error;
  }
);

export default apiClient;
