import React, { ReactNode, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { PageTitleType } from "constants/colorByPageName";
import { ArrowForward } from "@mui/icons-material";
import { removeOpacity } from "./removeColorOpacity";
interface Props {
  title: PageTitleType;
  backgroundImgPath?: string;
  color: string;
  description?: ReactNode;
  mainContent: ReactNode;
}

interface SummaryItemPropsI {
  title: PageTitleType;
  entries?: string[];
  content?: ReactNode;
  color: string;
  mainColor: string;
  linkTo: string;
  minWidth?: number;
  external?: boolean;
  ariaLabel?: string;
}

interface SummaryTitleTextPropsI {
  title: ReactNode;
  content?: ReactNode;
  color: string;
}

export const SummaryItem = ({
  title,
  entries,
  color,
  mainColor,
  linkTo,
  content,
  minWidth,
  external,
  ariaLabel
}: SummaryItemPropsI) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleHoverIn = () => setIsHovering(true);
  const handleHoverOut = () => setIsHovering(false);
  // update alpha channel on rgba color to 1(no opacity)
  const newColor = removeOpacity.color(color);
  const newMainColor = removeOpacity.mainColor(mainColor);

  const configLink = (
    <Box
      sx={{
        backgroundColor: isHovering ? newColor : "unset",
        color: isHovering ? "white" : newMainColor,
        pl: 0.5,
        position: "relative",
        width: "fit-content",
        minWidth: minWidth ? minWidth : 300,
        borderBottom: `2px solid ${newColor}`
      }}
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
    >
      <Typography sx={{ fontSize: "1.2rem", fontWeight: 500 }}>{title}</Typography>
      {isHovering && (
        <Box sx={{ position: "absolute", top: 0, right: 10, display: "flex", alignItems: "center", height: "100%" }}>
          <ArrowForward />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ color: newMainColor, p: 1 }}>
      {!external && (
        <Link
          to={linkTo}
          style={{ width: "fit-content", display: "block" }}
          aria-label={`Link to ${ariaLabel ? `${ariaLabel}` : `internal link is present`}`}
        >
          {configLink}
        </Link>
      )}
      {external && (
        <a
          href={linkTo}
          style={{ width: "fit-content", display: "block" }}
          target={"_blank"}
          aria-label={`${
            ariaLabel
              ? `Link to ${ariaLabel} (external link will be opened in a new window)`
              : `External link (external link will be opened in a new window)`
          }`}
        >
          {configLink}
        </a>
      )}
      {(entries || content) && (
        <Box sx={{ fontSize: "0.9rem", padding: 1 }}>
          {content && content}
          {entries &&
            entries.map((i, index) => {
              return <div key={index}>◦ {i}</div>;
            })}
        </Box>
      )}
    </Box>
  );
};

const SummaryPage = (props: Props) => {
  const { color, backgroundImgPath, title, description, mainContent } = props;

  return (
    <Box className="flex flex-column" sx={{ minHeight: "100vh", textAlign: "justify" }}>
      {backgroundImgPath && <div className={`${backgroundImgPath}-overlay`}></div>}
      <Box sx={{ color: "white", position: "relative", backgroundColor: color }}>
        <Box sx={{ zIndex: -1, position: "absolute" }} className="w-full h-full" />
        <Typography
          variant="h1"
          sx={{
            verticalAlign: "middle",
            paddingLeft: 5,
            paddingTop: 3,
            paddingBottom: 3,
            textTransform: "uppercase"
          }}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            variant="h5"
            sx={{
              minHeight: "100",
              marginLeft: 5,
              marginBottom: 3,
              fontWeight: 400,
              maxWidth: "100%"
            }}
            style={{ fontSize: "0.9rem" }}
          >
            {description}
          </Typography>
        )}
      </Box>
      <Box sx={{ flexGrow: 1, width: "100%", position: "relative" }}>
        <Box
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.9)", zIndex: -1, position: "absolute" }}
          className="w-full h-full"
        />
        <Box sx={{ p: 5 }}>{mainContent}</Box>
      </Box>
    </Box>
  );
};

export default SummaryPage;

export const SummaryTitleText = ({ title, content, color }: SummaryTitleTextPropsI) => {
  const configTitle = (
    <Typography sx={{ fontSize: "1.3rem", fontWeight: 1000, color: color, lineHeight: "24px", marginBottom: "1em" }}>
      {title}
    </Typography>
  );

  return (
    <Box sx={{ p: 1 }}>
      {configTitle}
      <Box sx={{ fontSize: "0.9rem" }}>{content && content}</Box>
    </Box>
  );
};
