import { LatLng } from "leaflet";
import { GeoServerClient } from "utils/ogcClients";

export const getCountryFeatureByCoords = async (coords: LatLng, client: GeoServerClient) => {
  try {
    const typeName = client.countryParams.typeName || client.countryParams.layers;
    const params = {
      ...client.countryParams,
      typeName,
      cql_filter: `CONTAINS(geom, Point(${coords.lat} ${coords.lng}))`,
      outputformat: "application/json",
      service: "WFS",
      request: "GetFeature"
    };

    const res = await client.getWFS({ params });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCountryFeatureById = async (id: string, client: GeoServerClient) => {
  try {
    const typeName = client.countryParams.typeName || client.countryParams.layers;
    const params = {
      ...client.countryParams,
      typeName,
      outputformat: "application/json",
      service: "WFS",
      request: "GetFeature",
      featureID: id
    };

    const res = await client.getWFS({ params });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
