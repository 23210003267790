import styled from "@emotion/styled";
import { getListItemSecondaryActionClassesUtilityClass, Typography } from "@mui/material";
import { ApiMapping, ApiMappingItem } from "components/pages/GlobalForestAttributesPage/Sidebar/utils/apiFieldsMapping";
import { Point } from "highcharts";
import { ReactNode, useContext } from "react";

import { sumBy } from "utils/utils";
import { MapPageContext } from "components/reusables/MapPage";
import StackedColumnChart from "components/charts/StackedColumnChart";
import ColumnChart from "components/charts/ColumnChart";

interface DataItemI {
  name: string;
  y: number;
}

export const makeTitle = (text: string) => {
  return (
    <Typography variant="h3" fontWeight={500} py={1}>
      {text}
    </Typography>
  );
};

export const handleColor = (item: ApiMappingItem, value: number): string => {
  const { positiveNegativeColors } = item;
  let color = "";
  if (positiveNegativeColors) {
    if (value > 0) color = positiveNegativeColors[0];
    else color = positiveNegativeColors[1];
  } else if (item.color) color = item.color;

  return color;
};

export const makeData = (data: any, dataKeys: string[], mapping: ApiMapping, unit: string): DataItemI[] => {
  const items = [];
  for (const key of dataKeys) {
    if (!mapping[key]) {
      console.error("MAPPING NOR FOUND FOR KEY", key);
      continue;
    }
    const value = data[key];
    const color = handleColor(mapping[key], value);
    const name = mapping[key]?.title;
    const item = { name, color, y: value, property: key, unit } as DataItemI;

    items.push(item);
  }
  return items;
};

const getData = (): any[] => {
  const { selectedCountryData } = useContext(MapPageContext);
  const data: any[] = selectedCountryData instanceof Array ? selectedCountryData : [];
  return data;
};

export const makeHighchartsSeries = (
  data: any[],
  dataItems: any[],
  allTimeDimensions: string[],
  attr = "year"
): any[] => {
  const series = Object.entries(dataItems).map(([name, itemDataValue]) => {
    return {
      name: itemDataValue.name,
      color: itemDataValue.color,
      unit: itemDataValue.unit,
      data: allTimeDimensions.map((i) => {
        const foundByAttr = data.find((dataPoint) => dataPoint[attr] === i);
        return foundByAttr[itemDataValue.property];
      })
    };
  });

  return series;
};

export const Chart = ({
  title = "",
  categories,
  stacked = false,
  apiMapping,
  yAxisTitle = "ha",
  colors = undefined,
  dataP,
  hideLegend = false,
  attrDimension = "year"
}: {
  title?: string;
  categories: string[];
  stacked?: boolean;
  apiMapping: ApiMapping;
  yAxisTitle?: string;
  colors?: string[];
  dataP?: any[];
  hideLegend?: boolean;
  attrDimension?: string;
}) => {
  const data = dataP ? dataP : getData();
  const allYears: string[] = [...new Set(data.map((item) => item[attrDimension]))];

  const dataAll = makeData(data, categories, apiMapping, yAxisTitle);
  const highAll = makeHighchartsSeries(data, dataAll, allYears, attrDimension);

  const hasValues = highAll
    .map((item) => item.data.some((elem: number) => elem != 0))
    .find((elem: number) => elem != 0);
  if (hasValues === true) {
    if (stacked) {
      return <StackedColumnChart data={highAll} title={title} categories={allYears} yAxisTitle={yAxisTitle} />;
    } else {
      return (
        <>
          <ColumnChart
            data={[]}
            series={highAll}
            title={title}
            categories={allYears}
            yAxisTitle={yAxisTitle}
            dataLabelDisable={true}
            hideLegend={hideLegend}
            colors={colors}
          />
        </>
      );
    }
  } else
    return (
      <Typography variant={"h6"} style={{ lineHeight: "50px" }}>
        No values for {title}
      </Typography>
    );
};

export const StyledLegend = styled.ul`
  padding-left: 10px;

  li {
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  div {
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
    border: 1px solid #ccc;
    border-radius: 50%;
  }

  li span {
    padding-left: 5px;
  }
` as any;

interface MakeLegendOptionsI {
  withDescription?: boolean;
}

export const makeLegend = (keys: string[], mapping: ApiMapping, options?: MakeLegendOptionsI) => {
  const { withDescription } = options || {};

  return (
    <StyledLegend>
      {keys.map((i) => {
        const { title, color, description } = mapping[i];

        let text: ReactNode = title;
        if (withDescription) {
          text = (
            <>
              <b>{title}</b>: {description}
            </>
          );
        }

        return (
          <li key={i}>
            <div style={{ backgroundColor: color || "" }}></div> <span>{text}</span>
          </li>
        );
      })}
    </StyledLegend>
  );
};

export const barsConfig = [{ dataKey: "y" }];
export const piesConfig = barsConfig;

export function pointFormatter(this: any): any {
  const { unit } = this;
  const context = this as Point;
  if (!context) return " NO CONTEXT";
  const { series, y } = context;
  if (!y) return;
  const percent: number = (100 * y) / sumBy("y", series.data);
  const formatted = percent.toFixed(1);
  return `${formatedNumber(y)} ${unit} (${formatted} %)`;
}

export function pointFormatter2(this: any): any {
  let { unit, extra } = this;
  if (!unit || unit === undefined) unit = "";
  if (!extra || extra === undefined) extra = "";
  const context = this as Point;
  if (!context) return " NO CONTEXT";
  const { series, y } = context;
  if (!y) return;
  const name = series.name.startsWith("Series ") ? "" : "<b>" + series.name + "</b>: ";

  if (unit === "") {
    const options = series?.userOptions as any;
    if (options && options.unit) unit = options.unit;
  }

  return name + formatedNumber(y) + " " + unit + (extra ? "<br/>" + extra : "");
}

export function dataLabel(this: any): any {
  const { y } = this;

  return "<span style='font-size:10px; font-weight: normal'>" + formatedNumber(y) + "</span>";
}

export function formatedNumber(num: number, fractionDigits = 3): string {
  return Number(num.toFixed(fractionDigits)).toLocaleString();
}
