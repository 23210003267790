interface PropsI {
  fill?: string;
}

const GFM = (props: PropsI) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.17 163.17">
      <g>
        <path
          style={{ fill: props.fill }}
          d="M81.58,163.17C36.6,163.17,0,126.57,0,81.58S36.6,0,81.58,0s81.58,36.6,81.58,81.58-36.6,81.58-81.58,81.58Zm0-147.91C45.01,15.25,15.25,45.01,15.25,81.58s29.76,66.33,66.33,66.33,66.33-29.76,66.33-66.33S118.16,15.25,81.58,15.25Z"
        />
        <g>
          <path
            style={{ fill: props.fill }}
            d="M104.44,96.66v-2.02l4.86-4.86c.51-.51,.51-1.34,0-1.85-.51-.51-1.34-.51-1.85,0l-3.01,3v-8.63c0-.72-.59-1.31-1.31-1.31s-1.31,.59-1.31,1.31v6.01l-3.01-3.01c-.51-.51-1.34-.51-1.85,0-.51,.51-.51,1.34,0,1.85l4.86,4.86v4.64c-4.02-.37-7.71-2.4-10.17-5.64,2.27-2.73,3.64-6.23,3.64-10.05,0-3.27-.33-6.72-.94-10.1,2.51-1.93,5.61-2.99,8.78-2.99,7.95,0,14.42,6.47,14.42,14.42,0,7.51-5.77,13.69-13.11,14.36m-23.6-4.64l4.86-4.86c.51-.51,.51-1.34,0-1.85-.51-.51-1.34-.51-1.85,0l-3.01,3.01v-5.47l3.55-3.55c.51-.51,.51-1.34,0-1.85s-1.34-.51-1.86,0l-1.7,1.69v-3.39c0-.72-.59-1.31-1.31-1.31s-1.31,.59-1.31,1.31v9.94l-3.01-3.01c-.51-.51-1.34-.51-1.85,0-.51,.51-.51,1.34,0,1.86l4.86,4.86v4.63c-6.63-.66-11.82-6.27-11.82-13.06s1.54-14.75,4.12-20.81c2.61-6.13,5.9-9.65,9-9.65s6.38,3.53,9,9.69c2.58,6.07,4.13,13.83,4.13,20.76s-5.19,12.4-11.82,13.06v-2.01Zm-10.89,2.08h-8.77v-2.08l2.32-2.32c.51-.51,.51-1.34,0-1.85-.51-.51-1.34-.51-1.85,0l-.47,.47v-8.63c0-.72-.59-1.31-1.31-1.31s-1.31,.59-1.31,1.31v6.01l-1.7-1.7c-.51-.51-1.34-.51-1.85,0-.51,.51-.51,1.34,0,1.86l3.55,3.55v4.7h-8.77l10.08-36.96,4.44,16.28c-.35,2.55-.53,5.1-.53,7.55,0,4.98,2.33,9.43,5.95,12.32l.22,.81Zm50.21-11.8c0-9.4-7.64-17.04-17.04-17.04-3.33,0-6.59,.99-9.35,2.8-.72-3.14-1.67-6.17-2.82-8.88-3.1-7.28-7.15-11.29-11.42-11.29s-8.33,3.99-11.41,11.24c-1.09,2.55-2,5.4-2.7,8.36l-4.28-15.69c-.15-.57-.67-.97-1.26-.97s-1.11,.4-1.27,.97l-11.8,43.26c-.11,.4-.03,.82,.22,1.14,.25,.32,.63,.52,1.04,.52h10.49v10.49c0,.72,.59,1.31,1.31,1.31s1.31-.59,1.31-1.31v-10.49h10.49c.41,0,.8-.19,1.04-.52,.2-.27,.29-.59,.26-.92,1.62,.75,3.39,1.22,5.25,1.38v10.54c0,.72,.59,1.31,1.31,1.31s1.31-.59,1.31-1.31v-10.54c3.4-.28,6.5-1.65,8.95-3.76,2.95,3.71,7.3,6.02,12.02,6.38v7.92c0,.72,.59,1.31,1.31,1.31s1.31-.59,1.31-1.31v-7.92c8.79-.67,15.73-8.04,15.73-16.99"
          />
          <path
            style={{ fill: props.fill }}
            d="M59.81,76.48c.74,0,1.35-.6,1.35-1.35s-.6-1.35-1.35-1.35-1.35,.6-1.35,1.35,.6,1.35,1.35,1.35"
          />
          <path
            style={{ fill: props.fill }}
            d="M111.33,87.21c.74,0,1.35-.6,1.35-1.35s-.6-1.35-1.35-1.35-1.35,.6-1.35,1.35,.6,1.35,1.35,1.35"
          />
        </g>
        <g>
          <path
            style={{ fill: props.fill }}
            d="M124.93,147.23c.51,0,1.01-.22,1.35-.64,.61-.75,.5-1.85-.25-2.45C85.89,111.48,39.25,101,15.02,97.66c-.95-.14-1.84,.53-1.97,1.49-.13,.95,.54,1.84,1.49,1.97,23.87,3.29,69.81,13.61,109.29,45.72,.32,.26,.71,.39,1.1,.39Z"
          />
          <path
            style={{ fill: props.fill }}
            d="M15.11,112.04c-.95-.18-1.86,.44-2.05,1.38-.18,.95,.44,1.86,1.38,2.05,20.48,3.97,51.02,12.66,80.23,31.49,.29,.19,.62,.28,.94,.28,.57,0,1.13-.28,1.47-.8,.52-.81,.29-1.89-.52-2.41-29.68-19.13-60.68-27.95-81.45-31.98Z"
          />
          <g>
            <path
              style={{ fill: props.fill }}
              d="M66.91,111.6c-.27-.13-.49-.38-.57-.7-.14-.56,.21-1.13,.77-1.26,30.15-7.29,58.1-.91,72.07,3.55,.55,.17,.86,.76,.68,1.31-.18,.55-.76,.85-1.31,.68-13.76-4.39-41.28-10.68-70.94-3.51-.24,.06-.49,.03-.7-.07Z"
            />
            <path
              style={{ fill: props.fill }}
              d="M135.42,120.95c.56,.15,.89,.72,.75,1.28-.15,.56-.72,.89-1.28,.75-12.1-3.14-30.87-6.34-51.54-3.7-.21,.03-.41,0-.58-.09-.31-.15-.54-.45-.59-.81-.07-.57,.33-1.1,.91-1.17,21-2.68,40.05,.56,52.33,3.75Z"
            />
            <path
              style={{ fill: props.fill }}
              d="M101.13,128.04c-.35-.17-.59-.52-.6-.94,0-.58,.46-1.05,1.04-1.05,9.79-.07,19.85,.95,29.9,3.02,.57,.12,.93,.67,.81,1.24s-.67,.93-1.24,.81c-9.91-2.04-19.83-3.04-29.47-2.97-.17,0-.32-.04-.46-.1Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GFM;
