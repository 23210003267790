import React, { ReactNode, useContext, useState } from "react";
import TimeSlider from "../LayersTimeSlider";
import LayersSelector from "./LayersSelector/LayersSelector";
import Control from "./LeafletCustomControl";

import { Box, IconButton, Typography } from "@mui/material";
import { MapPageContext } from "../MapPage";

import LayersIcon from "@mui/icons-material/Layers";
import MapButton from "components/reusables/MapComponents/MapButton";
import { Info } from "@mui/icons-material";
import { SeriesPieOptions } from "highcharts";
type Props = {
  description?: string | ReactNode;
};
export const LayersControlsSection = (props: Props) => {
  const { selectedLayers } = useContext(MapPageContext);
  const [showDescription, setShowDescription] = useState(false);

  const isTimeLayers = selectedLayers.some((i) => i.year);
  const handleInfoClick = () => setShowDescription(!showDescription);

  return (
    <Box sx={{ height: "auto", position: "relative" }}>
      <Typography fontWeight={500} sx={{ pl: 1 }}>
        Layers
        {props.description && (
          <IconButton
            size="small"
            sx={{ color: "primary.main", height: 30, width: 30, my: "auto" }}
            onClick={handleInfoClick}
          >
            <Info fontSize="small" />
          </IconButton>
        )}
        :
      </Typography>
      {showDescription && <Box>{props.description}</Box>}
      <LayersSelector />
      {isTimeLayers && (
        <>
          <Box sx={{ borderBottom: "1px solid #ccc", my: 1 }} />
          <Typography fontWeight={500} sx={{ pl: 1 }}>
            Year:
          </Typography>
          <TimeSlider />
        </>
      )}
    </Box>
  );
};

const LayersControlsButton = () => {
  return (
    <Control position="topRight">
      <MapButton
        tooltip="Layers"
        anchorposition={{ horizontal: "left" }}
        containerStyle={{ width: 300 }}
        popupContent={<LayersControlsSection />}
      >
        <LayersIcon />
      </MapButton>
    </Control>
  );
};

export default LayersControlsButton;
