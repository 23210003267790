import { useState } from "react";
import { Map } from "leaflet";

import MapPage from "components/reusables/MapPage";

import { layersConfig } from "./layersConfig";
import StandardLeafletMap from "components/reusables/MapComponents/StandardLeafletMap";
import { makeTitle } from "utils/chartsUtils";
import SidebarContent from "./SidebarContent";

const INITIAL_YEAR = 2021;

const tabs = [{ title: makeTitle("Global Forest maps for year 2020 in support to EUDR"), content: <SidebarContent /> }];

const ReferenceMapPage = () => {
  const [map, setMap] = useState<Map | null>(null);

  return (
    <MapPage map={map} initialYear={INITIAL_YEAR} layersConfig={layersConfig} canSelectCountry={false} tabs={tabs}>
      <StandardLeafletMap setMap={setMap} showScaleToRestrictLegend={true} />
    </MapPage>
  );
};

export default ReferenceMapPage;
