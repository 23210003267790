import { useState } from "react";
import { Map } from "leaflet";

import { GeoServerClient } from "utils/ogcClients";
import MapPage from "components/reusables/MapPage";

import { layersConfig } from "./layersConfig";
import { getForestStatistics } from "utils/apiRequests";
import StandardLeafletMap from "components/reusables/MapComponents/StandardLeafletMap";

import Sidebar from "components/pages/GlobalForestAttributesPage/Sidebar";
import { SelectedCountryLayerTitle } from "components/reusables/CountryLayerTitle";
import DocumentsTab from "components/reusables/DocumentsTab";
import documents from "components/pages/GlobalForestAttributesPage/Sidebar/documentsConfig";

const INITIAL_YEAR = 2019;
const geometryClient = new GeoServerClient("countries_gfa");

const tabs = [
  { title: <SelectedCountryLayerTitle />, content: <Sidebar /> },
  { title: "Info", content: <DocumentsTab documents={documents} /> }
];

const GlobalForestAttributesPage = () => {
  const [map, setMap] = useState<Map | null>(null);

  return (
    <MapPage
      map={map}
      initialYear={INITIAL_YEAR}
      layersConfig={layersConfig}
      countryDataGetter={getForestStatistics}
      geometryClient={geometryClient}
      tabs={tabs}
      countriesApiPath="gfa/Countries"
    >
      <StandardLeafletMap setMap={setMap} />
    </MapPage>
  );
};

export default GlobalForestAttributesPage;
